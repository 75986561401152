import React, { useState } from "react";
import TextEditor from "../../Components/TextMenuBar/TextMenuBar";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useSearchParams } from "react-router-dom";
import Modal from "../../Components/Modal/Modal";
import AddWidget from "../../Components/Popups/AddNew/AddWidget";

const EditWiki = () => {
  const { projects } = useProjectContext();

  const [showModal, setShowModal] = useState(false);
  const [searchParams] = useSearchParams();
  const projectIdParam = searchParams.get("projectId");
  const projectId = projectIdParam ?? null;
  const project = projectId
    ? projects.find((p) => p.project_id === projectId)
    : null;

  const projectTitle = project ? project.project_name : "Project not found";
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <AddWidget onClose={closeModal} />
      </Modal>
      <div className="pl-4">
        <div className="mb-4">
          <p className='text-primary text-2xl font-semibold font-["Inter"] leading-loose'>
            Welcome to {projectTitle} -- ({projectId})
          </p>
          <div className="relative">
            <div className="mr-7 my-4 absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-10 z-0 rounded-xl" />
            <div className="relative z-10 pl-6 pr-10 py-9">
              <p className='absolute top-1 left-2 text-base font-semibold font-["Inter"] leading-normal text-mainBlack'>
                Description
              </p>
              <TextEditor placeholder="Input text" height="30vh" />
            </div>
          </div>
        </div>
        {/* add widget */}
        <div className="relative w-fit rounded-lg border border-r border-primary/15 backdrop-blur-sm shadow-md shadow-primary/20">


          <p className='px-2 bg-white/20 backdrop-blur-xl absolute -top-3 text-mainBlack opacity-30 text-base font-semibold font-["Inter"] leading-normal'>
            Widget
          </p>
          <button
            onClick={openModal}
            className="px-40 py-48 text-primary text-base font-semibold font-['Inter'] leading-normal"
          >
            Add Widget +
          </button>
        </div>
      </div>
    </>
  );
};

export default EditWiki;
