import React from "react";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../contexts/ProjectContext";
import DataFilter from "../DataFilter";
import "../../pages/Project/Project.css";
import { paths } from "../../constants/paths";

export default function ListViewComponent() {
  const navigate = useNavigate();
  const { projects, fetchProjectDetails, searchTerm } = useProjectContext();

  const handleNavigateWiki = (id: string) => {
    fetchProjectDetails(id); // fetch specific project details
    navigate(paths.project.projectWikiPage(id))
  };

  return (
    <div className="list-view-height bg-login-bg bg-no-repeat bg-cover overflow-y-scroll pb-32">
      <DataFilter
        data={projects}
        searchTerm={searchTerm}
        getKey={(project) => project.project_id}
        filterCondition={(project, searchTerm) =>
          project.project_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          project.description.toLowerCase().includes(searchTerm.toLowerCase())
        }
      >
        {(filteredProjects) => (
          <table className="projects-list-view bg-top ml-[40px] list-view-width ">
            <thead>
              <tr className="list-header h-14 bg-transparent">
                <th className="text-zinc-800 text-base font-semibold font-['Inter'] leading-normal px-4 py-2 text-left w-1/4">
                  Project Name
                </th>
                <th className="text-zinc-800 text-base font-semibold font-['Inter'] leading-normal px-4 py-2 text-left w-3/4">
                  Description
                </th>
              </tr>
            </thead>
            <tbody className="cursor-pointer list-rows [&>*:nth-child(odd)]:bg-stone-300 [&>*:nth-child(odd)]:bg-opacity-20 [&>*:nth-child(odd)]:backdrop-blur-1">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((item) => (
                  <tr
                    className="row h-14"
                    key={item.project_id}
                    onClick={() => handleNavigateWiki(item.project_id)}
                  >
                    <td className="text-zinc-600 text-base font-semibold font-['Inter'] leading-normal px-4 py-2 w-1/4">
                      {item.project_name}
                    </td>
                    <td className="text-zinc-600 text-base font-semibold font-['Inter'] leading-normal px-4 py-2 w-3/4">
                      {item.description}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2} className="text-center py-4">
                    No projects found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </DataFilter>
    </div>
  );
}
