import React, { useState } from "react";
import FuncDescrepTextField from "./DescriptiveTextField";
import { FaAngleDown } from "react-icons/fa";
import {
  FUNC_REQ_TEXTFILED_VALUES,
  HARA_CHILDS,
  SAFETY_CHILDS,
  FUNC_CHILD,
} from "../../constants/constants";
import { useFunctionTable } from "../../contexts/FunctionContext";
import { useHaraTable } from "../../contexts/HaraContext";
import { useSafety } from "../../contexts/SafetyContext";
import useUrlParams from "../../hooks/useUrlParams";
import Pagination from "../Pagination";
import DetailsSection from "./DetailsSection";

interface FuncReqTextFieldValue {
  label: string;
  textValue: string;
}

const SectionToggle: React.FC<{
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}> = ({ title, isOpen, onToggle, children }) => (
  <div className="mb-4">
    <button
      className="w-full flex text-zinc-600 text-base font-semibold justify-between items-center cursor-pointer"
      onClick={onToggle}
    >
      <span>{title}</span>
      <FaAngleDown
        className={`ml-2 transform transition-transform duration-300 ${
          isOpen ? "rotate-180" : ""
        }`}
      />
    </button>
    {isOpen && <div className="pl-4 mt-2">{children}</div>}
  </div>
);

const references = [
  {
    id: "01",
    info: "#Information",
  },
  {
    id: "02",
    info: "#Information",
  },
  {
    id: "03",
    info: "#Information",
  },
];
const ReferencesSection: React.FC = () => (
  <div className="pl-4 mt-2">
    <div className="mb-4">
      <span className="text-neutral-400 text-base font-normal">
        Downstream References
      </span>
      <hr className="my-1 w-[22.5vw] border-gray-300" />
      {references.map((references) => (
        <div
          key={references.id}
          className="cursor-pointer text-zinc-800 text-base font-medium hover:text-cyan-600"
        >
          {references.info}
        </div>
      ))}
    </div>

    <div>
      <span className="text-neutral-400 text-base font-normal">
        Upstream References
      </span>
      <hr className="my-1 w-[22.5vw] border-gray-300" />
      {references.map((references) => (
        <div
          key={references.id}
          className="cursor-pointer text-zinc-800 text-base font-medium hover:text-cyan-600"
        >
          {references.info}
        </div>
      ))}
    </div>
  </div>
);

const CommentsSection: React.FC<{
  comments: { id: string; comment: string }[];
}> = ({ comments }) => (
  <div className="pl-4 mt-2">
    {comments.map((comment) => (
      <div
        key={comment.id}
        className="text-neutral-800 text-base font-normal my-2"
      >
        {comment.comment}
      </div>
    ))}
  </div>
);

const Descriptive = () => {
  const { functionTableData, triggeringEventTableData, hazardTableData } =
    useFunctionTable();
  const { haraTableData, operConditionData } = useHaraTable();
  const { goalsTableData, requirementsTableData } = useSafety();
  const itemsPerPage = 4;
  const [textValues, setTextValues] = useState<FuncReqTextFieldValue[]>(
    FUNC_REQ_TEXTFILED_VALUES
  );
  const [isOpen, setIsOpen] = useState({
    details: false,
    references: false,
    comments: false,
  });
  const { id, child, setParams, pageNo } = useUrlParams();
  const [currentPage, setCurrentPage] = useState(Number(pageNo) || 1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const dataMapping: Record<string, any[]> = {
    [FUNC_CHILD.functionalReq]: functionTableData,
    [FUNC_CHILD.triggeringEvt]: triggeringEventTableData,
    [FUNC_CHILD.hazards]: hazardTableData,
    [HARA_CHILDS.haraTables]: haraTableData,
    [HARA_CHILDS.operConditions]: operConditionData,
    [SAFETY_CHILDS.safetyGoals]: goalsTableData,
    [SAFETY_CHILDS.safetyRequirements]: requirementsTableData,
  };

  const dataForCurrentChild = dataMapping[child ?? ""] || [];
  const currentItems = dataForCurrentChild.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(dataForCurrentChild.length / itemsPerPage);

  const handleTextChange = (index: number, newText: string) => {
    const newTextValues = [...textValues];
    newTextValues[index].textValue = newText;
    setTextValues(newTextValues);
  };

  const toggleSection = (section: keyof typeof isOpen) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const paginate = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      setParams({
        pageNo: pageNumber.toString(),
      });
      setCurrentPage(pageNumber);
    }
  };

  const comments = [
    { id: "01", comment: "comment1" },
    { id: "02", comment: "comment2" },
  ];

  return (
    <div className="profile-main w-full h-full flex flex-col">
      <div className="flex-1 overflow-hidden flex h-full">
        <div className="h-[70vh] w-[70%] bg-transparent flex flex-col pt-4">
          <div className=" overflow-y-auto w-full">
            {currentItems.map((item, index) => (
              <FuncDescrepTextField
                key={item.id ?? item.hazardId}
                label={item.id ?? item.hazardId}
                textValue={item.description}
                onTextChange={(newText) => handleTextChange(index, newText)}
              />
            ))}
          </div>
          {/* Pagination */}
          <div className="mx-9">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>

        <div className="w-[30%] h-full rounded-l-lg mt-3 bg-opacity-10 bg-cyan-600 backdrop-blur-1">
          <div className="p-4 bg-transparent rounded-md max-w-md mx-auto space-y-6 overflow-y-auto max-h-[60vh]">
            <SectionToggle
              title="Details"
              isOpen={isOpen.details}
              onToggle={() => toggleSection("details")}
            >
              <DetailsSection
                dataForCurrentChild={dataForCurrentChild}
                id={id}
              />
            </SectionToggle>

            <SectionToggle
              title="References"
              isOpen={isOpen.references}
              onToggle={() => toggleSection("references")}
            >
              <ReferencesSection />
            </SectionToggle>

            <SectionToggle
              title={`Comments (${comments.length})`}
              isOpen={isOpen.comments}
              onToggle={() => toggleSection("comments")}
            >
              <CommentsSection comments={comments} />
            </SectionToggle>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Descriptive;
