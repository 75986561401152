import React, { useEffect } from "react";
import ViewList from "../../Components/ViewList";
import SafetyTableTemplate from "./SafetyTableTemplete";
import Descriptive from "../../Components/Descriptive/Descriptive";
import Detailed from "../../Components/Detailed";
import FunctionGraph from "../Function/FunctionGraph";
import { VIEWS } from "../../constants/constants";
import { useFunctionTable } from "../../contexts/FunctionContext";
import { useHaraTable } from "../../contexts/HaraContext";
import { useSafety } from "../../contexts/SafetyContext";
import useUrlParams from "../../hooks/useUrlParams";
import { useProjectContext } from "../../contexts/ProjectContext";

interface SafetyProps {
  view: string;
}

const Safety: React.FC<SafetyProps> = ({ view }) => {
  const { getFunctionalRequirements } = useFunctionTable()
  const { getHarasData } = useHaraTable()
  const { fetchSafetyGoals, fetchSafetyRequirements } = useSafety()
  const { projectId } = useUrlParams()
  const { fetchProjectDetails } = useProjectContext()
  const renderComponent = () => {
    switch (view) {
      case VIEWS.table:
        return <SafetyTableTemplate />;
      case VIEWS.descriptive:
        return <Descriptive />;
      case VIEWS.detailed:
        return <Detailed />;
      case VIEWS.graph:
        return <FunctionGraph />;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchProjectDetails(projectId)
      getFunctionalRequirements(projectId);
      getHarasData(projectId)
      fetchSafetyGoals(projectId)
      fetchSafetyRequirements(projectId)
    }
  }, [])

  return (
    <div className="safety-wrapper w-full">
      <ViewList />
      {renderComponent()}
    </div>
  );
};

export default Safety;
