import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSideBar } from '../../../contexts/SideBarContext';
import { ADD_NEW_FOLDER_STRINGS } from './constants';

const AddNewFolder = ({ closeModal }: { closeModal: () => void }) => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const [folderName, setFolderName] = useState("");
  const { addNewFolder } = useSideBar();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };

  const handleSaveClick = () => {
    if (folderName.trim() === "") {
      alert(ADD_NEW_FOLDER_STRINGS.alertEmptyFolderName);
      return;
    }

    addNewFolder(page, folderName);
    closeModal();
  };

  return (
    <div className="popup-shadow p-6 w-[688px] h-[244px] px-6 pt-6 pb-4 shadow flex-col justify-start items-start gap-6 inline-flex">
      <div className="w-full h-6 text-zinc-800 text-2xl font-semibold font-['Inter']">
        {ADD_NEW_FOLDER_STRINGS.title}
      </div>

      <form onSubmit={(e) => e.preventDefault()} className="w-full">
        <label
          htmlFor="folderName"
          className="text-cyan-600 text-base font-medium leading-normal"
        >
          {ADD_NEW_FOLDER_STRINGS.folderNameLabel}
        </label>
        <input
          id="folderName"
          type="text"
          className="border-2 pl-3 pr-2 py-2 bg-white rounded-lg border-stone-300 w-full h-14 mt-2 flex-col justify-start items-start gap-1 inline-flex focus:outline-none focus:ring-0"
          placeholder={ADD_NEW_FOLDER_STRINGS.folderNamePlaceholder}
          value={folderName}
          onChange={handleInputChange}
        />
      </form>

      <div className="w-full h-7 flex items-center justify-end mt-4">
        <button
          className="mr-4 font-semibold text-gray-400 hover:text-primary cursor-pointer"
          onClick={closeModal}
          style={{ background: "none", border: "none", padding: 0 }}
        >
          {ADD_NEW_FOLDER_STRINGS.cancelButton}
        </button>

        <button
          className="font-semibold text-primary cursor-pointer"
          onClick={handleSaveClick}
          style={{ background: "none", border: "none", padding: 0 }}
        >
          {ADD_NEW_FOLDER_STRINGS.saveButton}
        </button>
      </div>
    </div>
  );
};

export default AddNewFolder;
