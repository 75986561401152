const SECRET_KEY: string = process.env.REACT_APP_SECRET_KEY ?? "";

const getKey = async () => {
  if (!SECRET_KEY) {
    throw new Error("SECRET_KEY is not defined");
  }

  const keyBytes = new Uint8Array(
    SECRET_KEY.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
  );
  return await window.crypto.subtle.importKey(
    "raw",
    keyBytes,
    "AES-GCM",
    false,
    ["encrypt", "decrypt"]
  );
};

const getIv = () => window.crypto.getRandomValues(new Uint8Array(12));

export const encryptData = async (data: string) => {
  const key = await getKey();
  const iv = getIv();
  const enc = new TextEncoder();
  const encrypted = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    key,
    enc.encode(data)
  );
  return {
    iv: Array.from(iv),
    data: Array.from(new Uint8Array(encrypted)),
  };
};

export const decryptData = async (encryptedData: any) => {
  const key = await getKey();
  const iv = new Uint8Array(encryptedData.iv);
  const data = new Uint8Array(encryptedData.data);
  const decrypted = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv },
    key,
    data
  );
  const dec = new TextDecoder();
  return dec.decode(decrypted);
};

export const getStoredAuth = async () => {
  const storedAuth = localStorage.getItem("auth");
  if (storedAuth) {
    try {
      const decryptedAuth = await decryptData(JSON.parse(storedAuth));
      return JSON.parse(decryptedAuth);
    } catch (error) {
      console.error("Error decrypting stored auth:", error);
    }
  }
  return null;
};

export const setStoredAuth = async (auth: { user: any; token: string }) => {
  const authString = JSON.stringify(auth);
  try {
    const encryptedAuth = await encryptData(authString);
    const encryptedAuthString = JSON.stringify(encryptedAuth);
    localStorage.setItem("auth", encryptedAuthString);
    localStorage.setItem("userId", auth.user.id);
  } catch (error) {
    console.error("Error encrypting auth data:", error);
  }
};

export const removeStoredAuth = () => {
  localStorage.removeItem("auth");
};

export function generateNonRepeatingId(): string {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base-36
  const randomPart = Math.random().toString(36).substring(2, 7); // Random part of the ID
  return `${timestamp}-${randomPart}`;
}
export const getUserId = () => {
  return localStorage.getItem("userId");
};
