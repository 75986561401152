import { AuthState, AuthContextType } from "../types/authTypes";
import { getStoredAuth, setStoredAuth } from "../utils/localStorage";
import setupAxiosInterceptors from "../utils/axiosInterceptors";
import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  ReactNode,
} from "react";
import Spinner from "../Components/Spinner";
import { authReducer } from "../reducers/authReducer";

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
  loading: true,
  error: null,
};

export const AuthContext = createContext<AuthContextType>({
  state: initialState,
  dispatch: () => null,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStoredAuth = async () => {
      try {
        const storedAuth = await getStoredAuth();
        if (storedAuth?.token) {
          dispatch({ type: "LOGIN_SUCCESS", payload: storedAuth });
          setupAxiosInterceptors(dispatch);
        } else {
          dispatch({ type: "LOGIN_FAIL", payload: null });
        }
      } catch (error) {
        console.error("Failed to retrieve stored auth:", error);
        dispatch({ type: "LOGIN_FAIL", payload: null });
      } finally {
        setLoading(false);
      }
    };
    fetchStoredAuth();
  }, []);

  useEffect(() => {
    const updateStoredAuth = async () => {
      if (state.isAuthenticated && state.user && state.token) {
        await setStoredAuth({ user: state.user, token: state.token });
      }
    };
    updateStoredAuth();
  }, [state.isAuthenticated, state.user, state.token]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};
