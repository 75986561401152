import React from "react";
import Button from "../../Button";
import VersionsTable from "../../../pages/Versions/VersionsTable";
import { useVersions } from "../../../contexts/VersionsContext";
import useUrlParams from "../../../hooks/useUrlParams";
import { VIEWS } from "../../../constants/constants";
import { COMPARE_POPUP_STRINGS } from './constants';

interface ComparePopupProps {
  readonly onClose: () => void;
}

function ComparePopup({ onClose }: ComparePopupProps) {
  const { versionTableTitles, versionTableData, selectedIndexes } = useVersions();
  const { setParams } = useUrlParams();

  const handleCompareClick = () => {
    onClose();
    setParams({
      version1: versionTableData[selectedIndexes[0]].version,
      version2: versionTableData[selectedIndexes[1]].version,
      view: VIEWS.table,
    });
  };

  const isCompareButtonDisabled = selectedIndexes.length !== 2;

  return (
    <div className="lg:w-[800px] xl:w-[1000px] 2xl:w-[1488px] bg-white rounded-2xl shadow flex-col justify-center gap-10 inline-flex">
      <div className="p-8">
        <p className="text-greyText text-2xl font-semibold font-['Inter'] leading-loose">
          {COMPARE_POPUP_STRINGS.title}
        </p>
        <div className="py-10">
          <VersionsTable
            tableTitle={versionTableTitles}
            tableData={versionTableData}
          />
        </div>

        <div className="btns flex items-end justify-end ml-auto mt-4">
          <Button
            onClick={onClose}
            className="px-4 py-1 rounded-lg justify-center items-center gap-2 inline-flex text-primary"
          >
            {COMPARE_POPUP_STRINGS.buttonLabels.cancel}
          </Button>
          <Button
            onClick={handleCompareClick}
            className={`px-3 py-1 text-center text-white text-base font-semibold font-['Inter'] leading-normal bg-primary rounded-2xl ${isCompareButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            disabled={isCompareButtonDisabled}
          >
            {COMPARE_POPUP_STRINGS.buttonLabels.compare}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ComparePopup;
