import { getUserId } from "../../utils/localStorage";
import { HARA_ENDPOINTS } from "../ApiEndPoints";
import ApiManager from "../ApiManager";

const apiManager = ApiManager.getInstance();

export const getHara = async (reqId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${HARA_ENDPOINTS.GET_HARA}/${reqId}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
  throw new Error("Error fetching HARA");
};

export const getHaras = async (projectId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${HARA_ENDPOINTS.GET_HARAS}/${projectId}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getHaraSchema = async () => {
  const response = await apiManager.get(HARA_ENDPOINTS.GET_HARA_SCHEMA, {});
  return response;
};

export const addHaraDef = async () => {
  const response = await apiManager.post(HARA_ENDPOINTS.ADD_HARA_DEF, {});
  return response;
};
