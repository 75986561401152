import React, { useState } from "react";
import Button from "../../Button";
import DetailedComparisonTable from "../../../pages/Versions/DetailedComparisonTable";
import ImportSuccessful from "../Import/ImportSuccessful";
import Modal from "../../Modal/Modal";
import { FUNC_CHILD, PAGES, VIEWS } from "../../../constants/constants";
import useUrlParams from "../../../hooks/useUrlParams";
import { useVersions } from "../../../contexts/VersionsContext";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { ADD_VERSION_STRINGS } from './constants';
interface AddVersionProps {
  readonly onClose: () => void;
}

function AddVersion({ onClose }: AddVersionProps) {
  const [showImportSuccessful, setShowImportSuccessful] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { setParams } = useUrlParams();
  const { importVersion } = useVersions();
  const { projectDetails } = useProjectContext();

  const handleCancel = () => {
    onClose();
  };

  const handleAccept = () => {
    importVersion(projectDetails);
    setShowImportSuccessful(true);
    openModal();
    setTimeout(() => {
      setParams({
        page: PAGES.function,
        view: VIEWS.table,
        child: FUNC_CHILD.functionalReq,
      });
      setShowImportSuccessful(false);
      onClose();
      closeModal();
    }, 3000);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showImportSuccessful && (
        <Modal show={showModal} onClose={closeModal}>
          <ImportSuccessful redirectText={ADD_VERSION_STRINGS.redirectText} />
        </Modal>
      )}
      <div className="w-[1000px] lg:w-[1100px] 2xl:w-[1488px] h-[80vh] bg-white rounded-2xl shadow flex flex-col gap-10 mx-auto">
        <div className="p-8 flex flex-col h-full">
          <div>
            <p className="text-greyText text-2xl font-semibold font-['Inter'] leading-loose">
              {ADD_VERSION_STRINGS.title}
            </p>
          </div>
          <div className="my-10 overflow-y-auto">
            <DetailedComparisonTable />
          </div>
          <div className="save-btn flex items-end justify-end ml-auto text-base font-semibold font-['Inter'] leading-normal">
            <Button
              onClick={handleCancel}
              className="px-4 py-2 text-center text-primary rounded-2xl"
            >
              {ADD_VERSION_STRINGS.buttonLabels.cancel}
            </Button>
            <Button
              onClick={handleAccept}
              className="text-white bg-primary px-4 py-2 rounded-3xl"
            >
              {ADD_VERSION_STRINGS.buttonLabels.accept}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddVersion;
