import React, { useState } from "react";
import Button from "../../Button";

interface FuncReqChooseProps {
  readonly onClose: () => void;
}

interface ValueItem {
  id: string;
  description: string;
}

function FuncReqChoose({ onClose }: FuncReqChooseProps) {
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  // Use unique identifiers for each value item
  const values: ValueItem[] = Array.from({ length: 13 }, (_, index) => ({
    id: `item-${index + 1}`,
    description: "Description",
  }));

  const handleCheckboxChange = (id: string) => {
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <div className="w-[50vh] h-[50vh] bg-white rounded-2xl shadow flex flex-col gap-10 max-w-md mx-auto">
      <div className="p-8 flex flex-col h-full">
        <div>
          <p className="text-greyText text-2xl font-semibold font-['Inter'] leading-loose">
            Choose
          </p>
        </div>
        <div className="my-6 overflow-x-auto whitespace-nowrap">
          {values.map((item) => (
            <div key={item.id} className="flex items-center mr-4 my-1">
              <input
                type="checkbox"
                id={item.id}
                checked={!!checkedItems[item.id]}
                onChange={() => handleCheckboxChange(item.id)}
                className="mr-2"
                style={{
                  width: "1rem",
                  height: "1rem",
                  accentColor: "#00A3B5",
                }}
              />
              <label
                htmlFor={item.id}
                className="text-base font-['Inter'] leading-normal text-mainBlack font-normal"
              >
                {item.description}
              </label>
            </div>
          ))}
        </div>
        <div className="save-btn flex items-end justify-end ml-auto mt-4">
          <Button
            onClick={handleSave}
            className="px-3 py-1 text-center text-base font-semibold font-['Inter'] leading-normal text-primary rounded-2xl"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FuncReqChoose;
