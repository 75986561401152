import { getStoredAuth, getUserId } from "../../utils/localStorage";
import { PROJECTS_API_ENDPOINTS } from "../ApiEndPoints";
import ApiManager from "../ApiManager";

const apiManager = ApiManager.getInstance();

interface User {
  id: string;
}
export const presignedUrl = async (
  file_name: string,
  s3_root_folder_name: string
): Promise<{
  upload_url: string;
  s3_root_folder_name: string;
  project_version: string;
  s3_file_path: string;
}> => {
  try {
    const credentials = {
      file_name,
      s3_root_folder_name,
    };
    const response = await apiManager.post(
      PROJECTS_API_ENDPOINTS.PRESIGNED_URL,
      credentials
    );
    if (response.detail) {
      throw new Error("Error fetching presigned URL");
    }
    return {
      upload_url: response.upload_url,
      s3_root_folder_name: response.s3_root_folder_name,
      project_version: response.project_version,
      s3_file_path: response.s3_file_path,
    };
  } catch (error) {
    console.error("Error fetching presigned URL:", error);
    throw error;
  }
};

export const uploadUsingTheLink = async (
  file: File | undefined,
  uploadUrl: string
) => {
  if (!file || !uploadUrl) {
    throw new Error("File or upload URL is missing");
  }

  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(uploadUrl, {
      method: "PUT",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }
    return true;
  } catch (error) {
    console.error("Error during file upload:", error);
    throw error;
  }
};

export const addProjectCall = async (
  project: any,
  preUrlResponse: any,
  user: User
) => {
  const credentials = {
    project_name: project.project_name,
    project_description: project.description,
    source_rms: project.source_rms,
    template_name: project.template_name,
    project_version: preUrlResponse.project_version,
    s3_root_folder_name: preUrlResponse.s3_root_folder_name,
    s3_file_path: preUrlResponse.s3_file_path,
  };
  apiManager.setUserId(user.id);
  const response = await apiManager.post(
    PROJECTS_API_ENDPOINTS.ADD_PROJECT,
    credentials
  );
  return response;
};

export const getProjects = async (id: string) => {
  const authToken = await getStoredAuth();
  apiManager.setToken(authToken?.token);
  apiManager.setUserId(id);
  const response = await apiManager.get(
    PROJECTS_API_ENDPOINTS.GET_PROJECTS,
    {}
  );
  if (!response.detail) {
    return response;
  }
  throw new Error("Error fetching projects");
};

export const importNewVersion = async (
  projectDetails: any,
  preUrlResponse: any
) => {
  try {
    const credentials = {
      project_name: projectDetails.heading,
      project_version: preUrlResponse.project_version,
      s3_root_folder_name: preUrlResponse.s3_root_folder_name,
      s3_file_path: preUrlResponse.s3_file_path,
      source_rms: "codebeamer",
      template_name: "iso_26262",
      project_id: projectDetails.project_id,
    };

    await apiManager.put(
      PROJECTS_API_ENDPOINTS.IMPORT_NEW_VERSION,
      credentials
    );
  } catch (error) {
    throw error;
  }
};
export const getProject = async (id: string, userId: any) => {
  try {
    const authToken = await getStoredAuth();
    apiManager.setToken(authToken.token);
    apiManager.setUserId(userId);
    const endpoint = `${PROJECTS_API_ENDPOINTS.GET_PROJECT}/${id}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
  throw new Error("Error fetching project");
};

export const getVersions = async (id: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${PROJECTS_API_ENDPOINTS.GET_VERSIONS}/${id}/versions`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};
