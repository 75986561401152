import React, { createContext, useState, useContext, ReactNode } from "react";
import {
  addProjectCall,
  getProject,
  getProjects,
  presignedUrl,
  uploadUsingTheLink,
} from "../services/Api/Projects";

import {
  Project,
  ProjectDetails,
  ProjectContextType,
} from "../types/ProjectContextTypes";
import { useAuth } from "../hooks/useAuth";

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectDetails, setProjectDetails] = useState<ProjectDetails[]>([]);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [loadingProjectDetails, setLoadingProjectDetails] =
    useState<boolean>(false);
  const { state } = useAuth();
  const { user } = state;

  const addProject = async (
    project: Omit<Project, "project_id">,
    user: any
  ): Promise<string> => {
    const preUrlResponse = await presignedUrl(project.file?.name ?? "", "");
    const uploadResult = await uploadUsingTheLink(
      project.file,
      preUrlResponse.upload_url
    );
    if (uploadResult) {
      const newProject = await addProjectCall(project, preUrlResponse, user);
      await fetchProjects(user.id);
      return newProject.project_id;
    }
    const newId =
      projects.length > 0
        ? (parseInt(projects[projects.length - 1].project_id) + 1).toString()
        : "1";
    const newProjectData: Project = { ...project, project_id: newId };

    setProjects((prevProjects) => [...prevProjects, newProjectData]);

    return newId;
  };

  const removeProject = (project_id: string) => {
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.project_id !== project_id)
    );
  };

  const fetchProjects = async (user_id: string) => {
    setLoadingProjects(true);
    try {
      const fetchedProjects = await getProjects(user_id);
      if (fetchedProjects) {
        const mappedProjects = fetchedProjects.map((project: any) => ({
          project_id: project.project_id,
          project_name: project.project_name,
          description: project.description,
          version: project.version,
        }));
        setProjects(mappedProjects);
      }
    } catch (error) {
      console.error("Failed to fetch projects", error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const fetchProjectDetails = async (id: string) => {
    setProjectDetails([]);
    setLoadingProjectDetails(true);
    try {
      const details = await getProject(id, user?.id);
      // Check if details is defined
      if (!details) {
        console.error("Project details not found");
        return;
      }

      const mappedDetails: ProjectDetails = {
        project_id: details.project_id,
        heading: details.heading,
        s3_root_folder_name: details.s3_root_folder_name,
        description: details.description,
        creation_date: details.creation_date,
        source_rms: details.source_rms,
        template_name: details.template_name,
        version_name: details.version_name,
        version_creation_date: details.version_creation_date,
      };

      // Update the state with the fetched details
      setProjectDetails([mappedDetails]);
    } catch (error) {
      console.error("Failed to fetch project details", error);
    } finally {
      setLoadingProjectDetails(false);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        projectDetails,
        loadingProjects,
        loadingProjectDetails,
        addProject,
        removeProject,
        fetchProjects,
        fetchProjectDetails,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProjectContext must be used within a ProjectProvider");
  }
  return context;
};
