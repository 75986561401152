import axios from "axios";
import { removeStoredAuth } from "../utils/localStorage";

const setupAxiosInterceptors = (dispatch: React.Dispatch<any>) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        // Clear the stored authentication data and log out the user
        dispatch({ type: "LOGOUT" });
        removeStoredAuth();

        // Optionally, redirect the user to the login page
        window.location.href = "/login";

        // Creating a new Error object to reject the promise
        return Promise.reject(
          new Error("Unauthorized request with retry failed")
        );
      }

      // Rejecting with a new Error object if not already handled
      return Promise.reject(new Error(error.message));
    }
  );
};

export default setupAxiosInterceptors;
