import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { paths } from "../constants/paths";

interface PrivateRouteProps {
  redirectPath?: string;
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectPath = paths.login,
  children,
}) => {
  const { state } = useAuth();
  const location = useLocation();
  if (!state.isAuthenticated) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
