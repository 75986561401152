import React, { useEffect, useState } from "react";
import TextEditor from "../../TextMenuBar/TextMenuBar";
import { useSideBar } from "../../../contexts/SideBarContext";
import useUrlParams from "../../../hooks/useUrlParams";
import { useEditorContext } from "../../../contexts/EditorContext";
import { ADD_NEW_PAGE_STRINGS } from './constants';

interface SidebarItem {
  name: string;
  subItems?: string[];
}

const AddNewPage = ({ closeModal }: { closeModal: () => void }) => {
  const [pageName, setPageName] = useState("");
  const [folders, setFolders] = useState<SidebarItem[]>([]); // State to hold folder data
  const [selectedFolder, setSelectedFolder] = useState(""); // State to hold the selected folder
  const { getSpecificPageFolder, addNewPage } = useSideBar();
  const { page, child } = useUrlParams();
  const { editorContent } = useEditorContext();

  useEffect(() => {
    if (page) {
      const folderItems = getSpecificPageFolder(page);
      setFolders(folderItems);

      // If child exists, set it as the selected folder by default
      if (child) {
        setSelectedFolder(child);
      }
    }
  }, [page, child, getSpecificPageFolder]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageName(event.target.value);
  };

  const handleFolderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFolder(event.target.value);
  };

  const handleSaveClick = () => {
    if (pageName.trim() === "") {
      alert(ADD_NEW_PAGE_STRINGS.alertEmptyPageName);
      return;
    }
    if (selectedFolder === "") {
      alert(ADD_NEW_PAGE_STRINGS.alertSelectFolder);
      return;
    }
    // Call the addNewPage function from the context
    addNewPage(selectedFolder, pageName, editorContent);
    closeModal();
  };

  return (
    <div className="bg-transparent flex items-center justify-center">
      <div className="popup-shadow p-6 w-[688px] px-6 pt-6 pb-4 rounded-lg shadow flex-col justify-start items-start gap-6 inline-flex">
        <div className="w-full text-zinc-800 text-2xl font-semibold font-['Inter']">
          {ADD_NEW_PAGE_STRINGS.title}
        </div>
        <form action="" className="">
          <div className="flex justify-between items-center mb-6">
            <label
              htmlFor="folder"
              className="text-cyan-600 text-base font-medium leading-normal"
            >
              {ADD_NEW_PAGE_STRINGS.chooseFolderLabel}
            </label>

            <select
              name="folder"
              id="folder"
              className="border-2 pl-2 py-[7px] bg-white rounded-lg border-stone-300 w-[510px] h-10 flex-col justify-start items-start gap-1 inline-flex focus:outline-none focus:ring-0"
              value={selectedFolder}
              onChange={handleFolderChange}
            >
              <option value="">{ADD_NEW_PAGE_STRINGS.selectFolderPlaceholder}</option>
              {folders.map((folder, index) => (
                <option key={`folder-` + index} value={folder.name}>
                  {folder.name}
                </option>
              ))}
            </select>
          </div>

          <label
            htmlFor="input-text"
            className="text-cyan-600 text-base font-medium leading-normal"
          >
            {ADD_NEW_PAGE_STRINGS.pageNameLabel}
          </label>
          <input
            id="pageName"
            type="text"
            className="border-2 pl-3 pr-2 py-2 bg-white rounded-lg border-stone-300 w-[640px] h-14 mt-2 flex-col justify-start items-start gap-1 inline-flex focus:outline-none focus:ring-0"
            placeholder={ADD_NEW_PAGE_STRINGS.pageNamePlaceholder}
            value={pageName}
            onChange={handleInputChange}
          />
          <div className="textarea mt-6 h-[auto]">
            <div className="flex flex-col h-full">
              <label
                htmlFor="desc-1"
                className="text-cyan-600 text-base font-medium leading-normal pb-1"
              >
                {ADD_NEW_PAGE_STRINGS.wikiContentLabel}
              </label>
              <TextEditor placeholder="Input text" height="40vh" />
            </div>
          </div>
        </form>

        <div className="w-full h-7 flex items-center justify-end">
          <button
            className="flex mr-6 font-semibold text-gray-400 hover:text-primary cursor-pointer"
            onClick={closeModal}
            style={{ background: "none", border: "none", padding: 0 }}
          >
            {ADD_NEW_PAGE_STRINGS.cancelButton}
          </button>

          <button
            onClick={handleSaveClick}
            className="mr-2 font-semibold text-gray-400 hover:text-primary cursor-pointer"
          >
            {ADD_NEW_PAGE_STRINGS.saveButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewPage;
