import React, { useState, useRef, useEffect } from "react";
import "../pages/Project/Project.css";
import TitleBarItems from "./TitleBarItems";
import { useSearchParams } from "react-router-dom";
import { useSideBar } from "../contexts/SideBarContext";
import { useFunctionTable } from "../contexts/FunctionContext";
import {
  CONST_URLS,
  FUNC_CHILD,
  HARA_CHILDS,
  PAGES,
  SAFETY_CHILDS,
  VIEWS,
} from "../constants/constants";
import { useHaraTable } from "../contexts/HaraContext";
import { useSafety } from "../contexts/SafetyContext";
import useUrlParams from "../hooks/useUrlParams";
import { useProjectContext } from "../contexts/ProjectContext";
import { useVersions } from "../contexts/VersionsContext";
import { useAuth } from "../hooks/useAuth";

interface TitleBarProps {
  selectedItem: string | null;
}

const TitleBar: React.FC<TitleBarProps> = ({ selectedItem }) => {
  const {
    functionTableData,
    triggeringEventTableData,
    hazardTableData,
    preliminaryArchitecture,
  } = useFunctionTable();

  const { haraTableData, operConditionData } = useHaraTable();
  const { goalsTableData, requirementsTableData } = useSafety();
  const { titleBarItems } = useSideBar();

  const [, setSearchParams] = useSearchParams();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const { setParams } = useUrlParams();
  const { projectDetails, fetchProjects } = useProjectContext();
  const { getVersion } = useVersions();
  const { state } = useAuth();
  const { user } = state;

  // Ref for the dropdown element
  const dropdownRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleItemTextClick = (item: string) => {
    switch (item) {
      case PAGES.wiki:
        setSearchParams((params) => {
          params.set(CONST_URLS.page, PAGES.wiki);
          params.delete(CONST_URLS.view);
          params.delete(CONST_URLS.id);
          params.delete(CONST_URLS.child);
          params.delete(CONST_URLS.pageNo);
          return params;
        });
        break;

      case PAGES.function:
        setParams({
          page: PAGES.function,
          child: FUNC_CHILD.functionalReq,
          view: VIEWS.table,
          id: functionTableData[0]?.id,
        });
        break;

      case PAGES.hazard:
        setParams({
          page: PAGES.hara,
          child: HARA_CHILDS.haraTables,
          view: VIEWS.table,
          id: hazardTableData[0]?.id,
        });
        break;
      case SAFETY_CHILDS.safetyGoals:
        setParams({
          page: PAGES.safety,
          child: SAFETY_CHILDS.safetyGoals,
          view: VIEWS.table,
          id: goalsTableData[0]?.id,
        });
        break;

      case PAGES.stoif:
        setSearchParams((params) => {
          params.set(CONST_URLS.page, PAGES.stoif);
          params.delete(CONST_URLS.view);
          params.delete(CONST_URLS.id);
          params.delete(CONST_URLS.child);
          params.delete(CONST_URLS.pageNo);
          return params;
        });
        break;
    }
  };

  const handleArrowClick = (item: string) => {
    // Handle arrow click logic to open/close dropdown
    setOpenDropdown(openDropdown === item ? null : item);
  };

  const handleOpenSubitemFolder = (child: string) => {
    const mapping: { [key: string]: () => void } = {
      // for function page
      [FUNC_CHILD.functionalReq]: () => {
        setParams({
          page: PAGES.function,
          child: FUNC_CHILD.functionalReq,
          view: VIEWS.table,
          id: functionTableData[0]?.id,
        });
      },
      [FUNC_CHILD.triggeringEvt]: () => {
        setParams({
          page: PAGES.function,
          child: FUNC_CHILD.triggeringEvt,
          view: VIEWS.table,
          id: triggeringEventTableData[0]?.id,
        });
      },
      [FUNC_CHILD.hazards]: () => {
        setParams({
          page: PAGES.function,
          child: FUNC_CHILD.hazards,
          view: VIEWS.table,
          id: hazardTableData[0]?.id,
        });
      },
      [FUNC_CHILD.preArch]: () => {
        setParams({
          page: PAGES.function,
          child: FUNC_CHILD.preArch,
          view: VIEWS.architecture,
          id: preliminaryArchitecture[0]?.id,
        });
      },
      // for hara page
      [HARA_CHILDS.haraTables]: () => {
        setParams({
          page: PAGES.hara,
          child: HARA_CHILDS.haraTables,
          view: VIEWS.table,
          id: haraTableData[0]?.id,
        });
      },
      [HARA_CHILDS.operConditions]: () => {
        setParams({
          page: PAGES.hara,
          child: HARA_CHILDS.operConditions,
          view: VIEWS.table,
          id: operConditionData[0]?.id,
        });
      },
      // for safety
      [SAFETY_CHILDS.safetyGoals]: () => {
        setParams({
          page: PAGES.safety,
          child: SAFETY_CHILDS.safetyGoals,
          view: VIEWS.table,
          id: goalsTableData[0]?.id,
        });
      },
      [SAFETY_CHILDS.safetyRequirements]: () => {
        setParams({
          page: PAGES.safety,
          child: SAFETY_CHILDS.safetyRequirements,
          view: VIEWS.table,
          id: requirementsTableData[0]?.id,
        });
      },
    };
    if (mapping[child]) {
      mapping[child]();
    } else {
      setSearchParams((params) => {
        params.delete(CONST_URLS.view);
        params.delete(CONST_URLS.id);
        return params;
      });
    }
  };

  const handleClickVersion = () => {
    getVersion(projectDetails[0].project_id);
    setSearchParams((params) => {
      params.set(CONST_URLS.page, PAGES.versions);
      params.delete(CONST_URLS.view);
      params.delete(CONST_URLS.child);
      params.delete(CONST_URLS.id);
      return params;
    });
  };

  useEffect(() => {
    if (user?.id) {
      fetchProjects(user?.id);
    }
  }, []);

  return (
    <div>
      <div className="w-full bg-white items-end flex px-14 title-bar relative border-b border-gray-300">
        <div className="flex items-center justify-start h-full">
          <div className="p-title font-inter font-semibold text-black">
            Project title
          </div>
          <button
            onClick={handleClickVersion}
            className="cursor-pointer title-version font-normal text-primary pl-3 underline text-sm"
          >
            #version {projectDetails[0]?.version_name}
          </button>
        </div>

        <div className="title-nav-items ml-[10%]">
          <ul className="flex flex-row">
            {titleBarItems?.map((item, index) => (
              <li key={`${item.name}-${index}`}>
                <TitleBarItems
                  displayText={item.name}
                  onItemClick={() => handleItemTextClick(item.name)} // Text click handler
                  onArrowClick={() => handleArrowClick(item.name)} // Arrow click handler
                  isSelected={!!selectedItem && item.name.includes(selectedItem)}
                  isOpen={openDropdown === item.name}
                  dropdownItems={item.subItems || []}
                  ref={(el) => (dropdownRefs.current[item.name] = el)}
                  onChildClick={(child) => {
                    setParams({
                      page: item.key,
                      child: child,
                    });
                    handleOpenSubitemFolder(child);
                  }}
                  onBlur={() => {
                    setOpenDropdown(null);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
