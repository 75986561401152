import React from "react";

interface CheckboxOptionProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxOption: React.FC<CheckboxOptionProps> = ({
  checked,
  onChange,
}) => (
  <label className="inline-flex items-center cursor-pointer">
    <input
      type="checkbox"
      aria-label="Toggle switch" // Provide an accessible name
      className="sr-only peer"
      checked={checked}
      onChange={onChange}
    />
    <div className="relative w-11 h-6 bg-success rounded-full peer peer-focus:ring-4 peer-focus:ring-success dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-success"></div>
  </label>
);

export default CheckboxOption;
