import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { forgotPassword, resetPassword } from "../../services/Api/Users";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { paths } from "../../constants/paths";
import LoginLogo from "../../assets/Images/login-logo.png";
import {
  LOGIN_LOGO_ALT,
  SAFETY_ARG_DESCRIPTION,
  EMAIL_ERROR_EMPTY,
  EMAIL_ERROR_INVALID,
  PASSWORD_ERROR_EMPTY,
  PASSWORD_ERROR_MISMATCH,
  OTP_ERROR_INVALID,
  RESEND_OTP_ERROR,
} from "./constants";

export default function ResetPass() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const otpInputs = useRef<HTMLInputElement[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get("step") || "1";

  useEffect(() => {
    if (otpInputs.current[0]) {
      otpInputs.current[0].focus();
    }
  }, [step]);

  const handleContinue = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError(EMAIL_ERROR_EMPTY);
      return;
    }
    if (!emailRegex.test(email)) {
      setError(EMAIL_ERROR_INVALID);
      return;
    }
    const forgotPasswordResponse = await forgotPassword(email);

    if (forgotPasswordResponse.success) {
      setError("");
      navigate(paths.resetPassword.step2);
      return;
    }

    setError(EMAIL_ERROR_INVALID);
  };

  const handleSubmitCode = () => {
    setError("");
    navigate(paths.resetPassword.step3);
  };

  const handlePasswordReset = async () => {
    if (!password || !repeatPassword) {
      setError(PASSWORD_ERROR_EMPTY);
      return;
    }
    if (password !== repeatPassword) {
      setError(PASSWORD_ERROR_MISMATCH);
      return;
    }
    const credentials = {
      username: email,
      code: otp.join(""),
      new_password: password,
    };
    const response = await resetPassword(credentials);
    if (response.success) {
      setError("");
      navigate(paths.login);
    }
    setError(OTP_ERROR_INVALID);
    navigate(paths.resetPassword.step2);

  };

  const handleOtpKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleSendOtpAgain = async () => {
    const forgotPasswordResponse = await forgotPassword(email);

    if (forgotPasswordResponse.success) {
      setError("");
      navigate(paths.resetPassword.step2);
      return;
    }
    setError(RESEND_OTP_ERROR);
  };

  return (
    <div className="reset-main bg-login-bg bg-contain bg-bottom bg-no-repeat flex flex-col gap-16 items-center justify-center h-screen">
      <div className="logo-desc">
        <img src={LoginLogo} alt={LOGIN_LOGO_ALT} className="w-[285px] h-[55px]" />
        <p className="text-center text-xs text-secondaryGrey600 font-semibold font-ita pt-[18px]">
          {SAFETY_ARG_DESCRIPTION}
        </p>
      </div>
      {step === "1" && (
        <StepOne
          email={email}
          setEmail={setEmail}
          error={error}
          handleContinue={handleContinue}
        />
      )}
      {step === "2" && (
        <StepTwo
          email={email}
          otp={otp}
          setOtp={setOtp}
          handleOtpKeyDown={handleOtpKeyDown}
          handleSendOtpAgain={handleSendOtpAgain}
          error={error}
          setError={setError}
          handleSubmitCode={handleSubmitCode}
        />
      )}
      {step === "3" && (
        <StepThree
          error={error}
          password={password}
          setPassword={setPassword}
          repeatPassword={repeatPassword}
          handlePasswordReset={handlePasswordReset}
          setRepeatPassword={setRepeatPassword}
        />
      )}
    </div>
  );
}
