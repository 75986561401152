import React from "react";
import Input from "../../Components/Input";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants/paths";
import {
  STEP_ONE_TITLE,
  BUTTON_CONTINUE,
  NEW_USER_TEXT,
  CONTACT_SUPPORT_TEXT,
} from "./constants";
import { EmailStepProps } from "./types";

const StepOne: React.FC<EmailStepProps> = ({ email, setEmail, error, handleContinue }) => {
  const navigate = useNavigate();
  return (
    <div className="reset-card w-[704px] bg-white shadow-2xl py-16 px-12">
      <p className="text-xl font-semibold font-['Inter'] text-mainBlack leading-7 text-center">
        {STEP_ONE_TITLE}
      </p>
      <div className="email-input flex flex-col gap-1 py-12">
        <Input
          label="E-mail"
          type="email"
          name="email"
          id="email-1"
          containerClassName="email-input flex flex-col gap-1"
          labelClassName="text-base font-medium text-mainBlack font-['Heebo'] leading-normal"
          inputClassName={`text-base font-normal font-['Heebo'] leading-normal py-4 px-3 focus:outline-none focus:ring-1 border-borderSecondaryGrey focus:border-transparent ${error ? 'border-red-500 focus:ring-red-500' : 'focus:ring-[#00a3b5]'}`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>

      <button
        onClick={handleContinue}
        className={`text-base font-semibold font-['Inter'] text-center w-full rounded-lg py-4 ${!email
          ? "bg-[#eeeeee] text-borderSecondaryGrey cursor-not-allowed"
          : "bg-primary text-white"
          }`}
        disabled={!email}
      >
        {BUTTON_CONTINUE}
      </button>

      <div className="text-center pt-6 font-medium text-base text-secondaryGrey600 ">
        {NEW_USER_TEXT}
        <button
          className="underline text-primary cursor-pointer"
          onClick={() => {
            navigate(paths.support);
          }}
        >
          {CONTACT_SUPPORT_TEXT}
        </button>
      </div>
    </div>
  );
}

export default StepOne;
