import React, { useRef } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  containerClassName?: string;
  fileInput?: boolean; // Custom prop to handle file input functionality
  handleFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  fileInput,
  handleFileChange,
  ...rest
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInput && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={`relative ${rest.containerClassName ?? ""}`}>
      {fileInput && (
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      )}
      <button
        type="button"
        className={`text-white bg-primary py-4 px-3 rounded-lg mt-6 w-full ${rest.className}`}
        onClick={handleButtonClick}
        {...rest}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
