import SettingDp from "../../assets/Images/setting-dp.png";
import Button from "../../Components/Button";
import { useAuth } from "../../hooks/useAuth";
import { SETTING_CARD_TEXT } from "./constants";

// Props for the SettingCard component
interface SettingCardProps {
  onChangePasswordClick: () => void;
}

export const SettingCard: React.FC<SettingCardProps> = ({ onChangePasswordClick }) => {
  const { state } = useAuth();
  const { user } = state;

  // Extracting user attributes
  const name = user?.userAttributes?.find((attr: any) => attr.Name === "name")?.Value;
  const email = user?.userAttributes?.find((attr: any) => attr.Name === "email")?.Value;
  const company = user?.userAttributes?.find((attr: any) => attr.Name === "custom:organization")?.Value;
  const location = user?.userAttributes?.find((attr: any) => attr.Name === "custom:location")?.Value;

  return (
    <div className="setting-card bg-white rounded-2xl w-[768px] h-auto px-12 pt-12 pb-16">
      <p className="text-black/opacity-20 text-2xl font-semibold leading-loose pb-5">
        {SETTING_CARD_TEXT.TITLE}
      </p>
      <div className="dp-info flex gap-20 items-center">
        <img src={SettingDp} alt="dp" height={160} width={160} />
        <div className="user-info flex flex-col gap-5">
          <div className="username">
            <span className="text-black/opacity-20 text-base font-extrabold">
              {SETTING_CARD_TEXT.USERNAME_LABEL}
            </span>
            <span className="text-black/opacity-20 text-base font-normal pl-4">{name}</span>
          </div>
          <div className="email">
            <span className="text-black/opacity-20 text-base font-extrabold">
              {SETTING_CARD_TEXT.EMAIL_LABEL}
            </span>
            <span className="text-black/opacity-20 text-base font-normal pl-4">{email}</span>
          </div>
          <div className="flex flex-row items-center">
            <span className="text-black/opacity-20 text-base font-extrabold">
              {SETTING_CARD_TEXT.PASSWORD_LABEL}
            </span>
            <span className="text-black/opacity-20 text-base flex gap-1 font-normal px-4">
              {Array(9)
                .fill("")
                .map((_, i) => (
                  <div key={i} className="w-2 h-2 bg-[#2d2d2d] rounded-full" />
                ))}
            </span>

            <Button
              className="text-cyan-600 text-base font-normal"
              onClick={onChangePasswordClick}
            >
              {SETTING_CARD_TEXT.CHANGE_PASSWORD_BUTTON}
            </Button>
          </div>
          <div className="company">
            <span className="text-black/opacity-20 text-base font-extrabold">
              {SETTING_CARD_TEXT.COMPANY_LABEL}
            </span>
            <span className="text-black/opacity-20 text-base font-normal pl-4">{company}</span>
          </div>
          <div className="location">
            <span className="text-black/opacity-20 text-base font-extrabold">
              {SETTING_CARD_TEXT.LOCATION_LABEL}
            </span>
            <span className="text-black/opacity-20 text-base font-normal pl-4">{location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
