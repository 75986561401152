import React, { ReactNode } from "react";
import "./Modal.css";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  showCloseButton?: boolean;
  customCloseButtonContent?: ReactNode;
  className?: string;
  overlayClickToClose?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  children,
  showCloseButton = true,
  customCloseButtonContent,
  className = "",
  overlayClickToClose = true,
}) => {

  if (!show) {
    return null;
  }
  const handleOverlayClick = (e: React.MouseEvent) => {

    if (e.target === e.currentTarget && overlayClickToClose) {
      onClose();
    }
  };

  return (
    // noinspection all
    <div className={`modal-overlay ${className}`} onClick={handleOverlayClick}>
      <div className="modal-content">
        {showCloseButton && (
          <button className=" p-2 close-button" onClick={onClose}>
            {customCloseButtonContent ?? <> &times;</>}
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;

