import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarLogo from "./../navbar-logo.png";
import ProfileIcon from "../assets/Images/profileicon.png";
import "../pages/Project/Project.css";
import HeaderItems from "./HeaderItems";
import UserInfo from "./Popups/UserInfo/UserInfo";
import { useProjectContext } from "../contexts/ProjectContext";
import { useAuth } from "../hooks/useAuth";
import { paths } from "../constants/paths";

const Header: React.FC = () => {
  const { state } = useAuth();
  const { user } = state;
  const name = user?.userAttributes?.find(
    (attr: any) => attr.Name === "name"
  )?.Value;

  const location = useLocation();
  const { projects } = useProjectContext();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate()

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownRefs = useRef<{ [key: string]: HTMLDivElement | null }>({
    Home: null,
    Projects: null,
    Reports: null,
    Admin: null,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !Object.values(dropdownRefs.current).some((ref) =>
          ref?.contains(event.target as Node)
        )
      ) {
        setIsDropdownOpen(false);
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (item: string) => {
    setOpenDropdown(openDropdown === item ? null : item);
  };
  const shouldRenderHeaderItems = !(
    location.pathname.includes("/account") ||
    location.pathname.includes("/support")
  );

  const projectTitles = projects.map((project) => project.project_name);

  return (
    <div className="navbar border-b-4 relative border-primary bg-login-bg bg-cover bg-no-repeat bg-center flex items-center h-[92px] px-10">
      <div className="flex justify-center w-full">
        <img
          onClick={() => {
            navigate(paths.project.project)
          }}
          src={NavbarLogo}
          alt="navbar-logo"
          className="w-[162px] h-[30px] cursor-pointer"
        />
      </div>

      {shouldRenderHeaderItems && (
        <>
          <div className="nav-items absolute bottom-0 left-10">
            <ul className="flex flex-row">
              <li>
                <HeaderItems
                  displayText="Projects"
                  onItemClick={() => handleItemClick("Projects")}
                  isOpen={openDropdown === "Projects"}
                  dropdownItems={projectTitles}
                  ref={(el) => (dropdownRefs.current.Projects = el)}
                />
              </li>
            </ul>
          </div>

          <div
            className="user-dp flex items-center gap-3 absolute right-10"
            ref={dropdownRef}
          >
            <button
              className="user-detail border-l-2 flex gap-1 pl-3 relative cursor-pointer"
              onClick={toggleDropdown}
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <img
                src={ProfileIcon}
                alt="user profile"
                className="w-6 h-6 rounded-full"
              />
              <p className="username text-base font-normal">{name}</p>
            </button>
            {isDropdownOpen && (
              <div
                style={{
                  borderRadius: "0px 0px 16px 16px",
                  background: "#FFF",
                  boxShadow: "4px 4px 20px 5px rgba(0, 0, 0, 0.10)",
                }}
                className="dropdown-content absolute z-10 top-full right-0 mt-2 bg-white shadow-lg rounded-md"
              >
                <UserInfo />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
