import { getUserId } from "../../utils/localStorage";
import { FUNC_REQ_ENDPOINTS } from "../ApiEndPoints";
import ApiManager from "../ApiManager";

const apiManager = ApiManager.getInstance();

export const getFuncReq = async (reqId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${FUNC_REQ_ENDPOINTS.GET_FUNC_REQ}/${reqId}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getFuncReqs = async (projectId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${FUNC_REQ_ENDPOINTS.GET_FUNC_REQS}/${projectId}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getFuncReqSchema = async (projectId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${FUNC_REQ_ENDPOINTS.GET_FUNC_REQS_SCHEMA}/${projectId}`;
    await apiManager.get(endpoint, {});
  } catch (error) {
    throw error;
  }
};
