import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, paginate }) => {
  const getPageNumbers = () => {
    const pages: (number | string)[] = [];
    const leftDots = currentPage > 3;
    const rightDots = currentPage < totalPages - 2;

    pages.push(1);

    if (leftDots) {
      pages.push('...');
    }

    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      if (i > 1 && i < totalPages) pages.push(i);
    }

    if (rightDots) {
      pages.push('...');
    }
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="h-12 mt-12 flex justify-end items-center px-2 bg-transparent w-full">
      <button
        className="p-1 rounded-md flex items-center justify-center text-greyText hover:bg-gray-200"
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <FaAngleLeft />
      </button>
      <div className="flex space-x-2">
        {pageNumbers.map((page, index) => (
          <button
            key={index}
            className={`w-6 h-6 flex items-center justify-center rounded-lg text-base font-semibold font-['Inter'] leading-normal ${currentPage === page ? "text-primary bg-dashboard" : "text-[#2d2d2d] bg-transparent"} hover:bg-gray-100`}
            onClick={() => {
              if (typeof page === 'number') {
                paginate(page);
              }
            }}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        className="p-1 rounded-md flex items-center justify-center text-greyText hover:bg-gray-200"
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <FaAngleRight />
      </button>
    </div>
  );
};

export default Pagination;
