import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { PiArrowElbowLeftUpLight } from "react-icons/pi";
import { VERSIONS_SECTIONS } from "../../constants/constants";
import { AccordionState, Section, ContentItem, Item } from './types'

const AccordionHeader: React.FC<{
  title: string;
  stats: { changed: number; added: number; deleted: number };
  isOpen: boolean;
  onToggle: () => void;
}> = ({ title, stats, isOpen, onToggle }) => (
  <div className="flex justify-between cursor-pointer" onClick={onToggle}>
    <div className="flex items-center gap-2">
      <h1 className="text-xl font-semibold leading-normal font-['Inter'] text-greyText">
        {title}
      </h1>
      <FaChevronDown
        className={`text-primary text-sm transform ${isOpen ? "rotate-180" : ""}`}
      />
    </div>
    <div className="flex items-center gap-2 text-base leading-normal font-normal">
      <p className="text-changed">Changed: {stats.changed}</p>
      <p className="text-success">Added: {stats.added}</p>
      <p className="text-danger">Deleted: {stats.deleted}</p>
    </div>
  </div>
);

const ItemDetail: React.FC<{ item: Item; isExpanded: boolean; onToggle: () => void }> = ({ item, isExpanded, onToggle }) => (
  <div key={item.details.id}>
    <div
      className={`flex items-center cursor-pointer ${item.details.data.length === 0 ? "text-success" : ""}`}
      onClick={onToggle}
    >
      <div className="flex gap-2 items-center text-base font-semibold leading-normal w-[10%]">
        <FaChevronRight
          className={`text-xs text-primary ${isExpanded ? "rotate-90" : ""}`}
        />
        <p className={`${item.details.data.length === 0 ? "text-success" : "text-changed"}`}>
          {item.main}
        </p>
      </div>
      <div className="flex-1 ml-4 py-4">
        {isExpanded && (
          <div className="flex justify-between items-center">
            {item.details.title.map((detail) => (
              <p className="w-[12.5%] text-base font-semibold" key={detail}>
                {detail}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
    {item.sub && (
      <div
        className={`flex items-center text-secondaryGrey500 text-base font-semibold leading-normal ${isExpanded ? "bg-secondaryGrey500/10" : ""}`}
      >
        <div className="flex gap-2 items-center ml6 w-[10%]">
          <PiArrowElbowLeftUpLight className="text-xl" />
          <p>{item.sub}</p>
        </div>
        <div className="flex-1 ml-4 py-2">
          {isExpanded && (
            <div className="flex justify-between items-center">
              {item.details.data.map((detail) => (
                <p className="w-[12.5%] text-start" key={detail}>
                  {detail}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);

const ContentSection: React.FC<{
  contentItem: ContentItem;
  expandedItems: { [key: string]: boolean };
  onItemToggle: (key: string) => void;
}> = ({ contentItem, expandedItems, onItemToggle }) => (
  <div>
    <div className="flex justify-between">
      <h1 className="text-base font-semibold leading-normal text-secondaryGrey600">
        {contentItem.title}
      </h1>
      <div className="flex items-center gap-2 text-xs font-normal leading-normal">
        <p className="text-changed">Changed: 2</p>
        <p className="text-success">Added: 2</p>
        <p className="text-danger">Deleted: 6</p>
      </div>
    </div>
    <hr className="border-gray-200 my-2" />
    {contentItem.items.map((item) => {
      const uniqueKey = `${item.main}-${item.details.id}`;
      return (
        <ItemDetail
          key={uniqueKey}
          item={item}
          isExpanded={expandedItems[uniqueKey]}
          onToggle={() => onItemToggle(uniqueKey)}
        />
      );
    })}
  </div>
);

const DetailedComparisonTable = () => {
  const [accordionState, setAccordionState] = useState<AccordionState>({
    isFunctionOpen: true,
    isHazardOpen: false,
    isSafetyGoalsOpen: false,
    isSotifOpen: false,
  });

  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});

  const toggleAccordion = (sectionId: string) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [sectionId]: !prevState[sectionId],
    }));
  };

  const toggleItemDetails = (uniqueKey: string) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [uniqueKey]: !prevState[uniqueKey],
    }));
  };

  return (
    <div>
      <div className="space-y-3 overflow-y-auto h-[70vh]">
        {VERSIONS_SECTIONS.map((section: Section) => (
          <div key={section.id}>
            <AccordionHeader
              title={section.title}
              stats={section.stats}
              isOpen={accordionState[section.id]}
              onToggle={() => toggleAccordion(section.id)}
            />
            {accordionState[section.id] && (
              <div className="pl-6">
                {section.content.map((contentItem) => (
                  <ContentSection
                    key={contentItem.title}
                    contentItem={contentItem}
                    expandedItems={expandedItems}
                    onItemToggle={toggleItemDetails}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailedComparisonTable;
