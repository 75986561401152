import { getUserId } from "../../utils/localStorage";
import { SAFETY_GOALS_ENDPOINTS } from "../ApiEndPoints";
import ApiManager from "../ApiManager";

const apiManager = ApiManager.getInstance();

export const getSafetyGoal = async (reqId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${SAFETY_GOALS_ENDPOINTS.GET_SAFETY_GOAL}/${reqId}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getSafetyGoals = async (projectId: string) => {
  try {
    const userId = getUserId() ?? "";
    apiManager.setUserId(userId);
    const endpoint = `${SAFETY_GOALS_ENDPOINTS.GET_SAFETY_GOALS}/${projectId}`;
    const response = await apiManager.get(endpoint, {});
    if (!response.detail) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getSafetyGoalsSchema = async () => {
  try {
    await apiManager.get(SAFETY_GOALS_ENDPOINTS.GET_SAFETY_GOAL_SCHEMA, {});
  } catch (error) {
    throw error;
  }
};
