import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from "react";
import { SAFETY_CHILDS, VIEWS } from "../constants/constants";
import useUrlParams from "../hooks/useUrlParams";
import { getSafetyGoal, getSafetyGoals } from "../services/Api/SafetyGoals";
import { getSafetyReq, getSafetyReqs } from "../services/Api/SafetyReq";
import {
  SafetyGoal,
  SafetyGoals,
  SafetyRequirements,
  SafetyRequirement,
  GoalsTableData,
  RequirementsTableData,
  SafetyContextType,
} from "../types/SafetyContextTypes";

// Initial state
const initialState: Omit<
  SafetyContextType,
  | "fetchSafetyRequirement"
  | "fetchSafetyGoal"
  | "fetchSafetyRequirements"
  | "fetchSafetyGoals"
  | "updateSafetyParams"
  | "setRequirementsParams"
  | "setGoalsParams"
> = {
  safetyGoals: [],
  safetyGoal: [],
  safetyRequirements: [],
  safetyRequirement: [],
  goalsTableTitle: [
    "ID",
    "Heading",
    "Reference Id",
    "Description",
    "Associated Child ID",
    "Associated Parent ID",
    "Associated HARA ID",
    "HARA Component Name",
  ],
  goalsTableData: [],
  requirementsTableTitle: [
    "ID",
    "Heading",
    "Reference ID",
    "Description",
    "Child ID",
    "Parent Id",
    "Safety Goal",
    "Item ID",
  ],
  requirementsTableData: [],
};

// Create Context
const SafetyContext = createContext<SafetyContextType | undefined>(undefined);

// Provider Component
export const SafetyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setParams } = useUrlParams();

  // State management for safety goals, requirements, and table data
  const [safetyGoals, setSafetyGoals] = useState<SafetyGoals[]>([]);
  const [safetyGoal, setSafetyGoal] = useState<SafetyGoal[]>([]);
  const [safetyRequirements, setSafetyRequirements] = useState<
    SafetyRequirements[]
  >([]);
  const [safetyRequirement, setSafetyRequirement] = useState<
    SafetyRequirement[]
  >([]);
  const [goalsTableTitle] = useState(initialState.goalsTableTitle);
  const [requirementsTableTitle] = useState(
    initialState.requirementsTableTitle
  );
  const [goalsTableData, setGoalsTableData] = useState<GoalsTableData[]>([]);
  const [requirementsTableData, setRequirementsTableData] = useState<
    RequirementsTableData[]
  >([]);

  // Function to generate table data for safety goals
  const getGoalsTableData = (): GoalsTableData[] => {
    return safetyGoals.map((goal) => ({
      id: goal.id || "N/A",
      heading: goal.heading || "N/A",
      reference_id: 'SG-' + goal.reference_id || "N/A",
      description: goal.description || "N/A",
      assParentRmsId:
        (goal.parent || []).map((p) => p.reference_req_id).join(", ") || "N/A",
      assChildRmsId:
        (goal.children || []).map((c) => c.reference_req_id).join(", ") ||
        "N/A",
      assHaraRmsId:
        (goal.HARA || []).map((hara) => hara.reference_rms_id).join(", ") ||
        "N/A",
      assHaraComponentName:
        (goal.HARA || []).map((hara) => hara.component_name).join(", ") ||
        "N/A",
    }));
  };

  // Function to generate table data for safety requirements
  const getRequirementsTableData = (): RequirementsTableData[] => {
    return safetyRequirements.map((requirement) => ({
      id: requirement.id,
      heading: requirement.heading,
      reference_id: 'SR-' + requirement.reference_id,
      description: requirement.description,
      assChildRmsId:
        requirement.associations.children
          .map((children) => children.reference_req_id)
          .join(", ") || "N/A",
      assParentRmsId:
        requirement.associations.parent
          .map((parent) => parent.reference_req_id)
          .join(", ") || "N/A",
      SafetyGoalRmsId: requirement.associations["Safety Goal"]
        ? requirement.associations["Safety Goal"]
          .map((goal) => goal.reference_rms_id)
          .join(", ")
        : "N/A",
      ItemRmsId: requirement.associations["Item Definition(s)"]
        ? requirement.associations["Item Definition(s)"]
          .map((item) => item.reference_rms_id)
          .join(", ")
        : "N/A",
    }));
  };

  // Update table data whenever the safety goals or requirements state changes
  useEffect(() => {
    if (safetyGoals.length > 0) {
      setGoalsTableData(getGoalsTableData());
    }
  }, [safetyGoals]);

  useEffect(() => {
    if (safetyRequirements.length > 0) {
      setRequirementsTableData(getRequirementsTableData());
    }
  }, [safetyRequirements]);

  // Set URL params for goals
  const setGoalsParams = (itemName: string) => {
    setParams({
      view: VIEWS.table,
      child: itemName,
      id: goalsTableData[0].id,
    });
  };

  // Set URL params for requirements
  const setRequirementsParams = (itemName: string) => {
    setParams({
      view: VIEWS.table,
      child: itemName,
      id: requirementsTableData[0].id,
    });
  };

  // Update safety params based on the item type
  const updateSafetyParams = (itemName: string) => {
    switch (itemName) {
      case SAFETY_CHILDS.safetyGoals:
        setGoalsParams(itemName);
        break;
      case SAFETY_CHILDS.safetyRequirements:
        setRequirementsParams(itemName);
        break;
      default:
        break;
    }
  };

  // Fetch all safety goals
  const fetchSafetyGoals = async (projectId: string) => {
    try {
      const responseData = await getSafetyGoals(projectId);
      if (Array.isArray(responseData)) {
        setSafetyGoals(responseData);
        setGoalsTableData(getGoalsTableData());
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch safety goals data", error);
    }
  };

  // Fetch all safety requirements
  const fetchSafetyRequirements = async (projectId: string) => {
    try {
      const responseData = await getSafetyReqs(projectId);
      if (Array.isArray(responseData)) {
        setSafetyRequirements(responseData);
        setRequirementsTableData(getRequirementsTableData());
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch safety requirement data", error);
    }
  };

  // Fetch details of a single safety goal
  const fetchSafetyGoal = async (reqId: string) => {
    try {
      const responseData = await getSafetyGoal(reqId);
      if (responseData) {
        const safetyGoalData: SafetyGoal = {
          attributes_schema: responseData.attributes_schema || [],
          associations_template: responseData.associations_template || {
            children: [],
            parent: [],
            HARA: [],
          },
          heading: responseData.heading || "N/A",
          reference_rms_id: responseData.reference_rms_id || "N/A",
          project_version: responseData.project_version || "N/A",
          attributes_and_value: responseData.attributes_and_value || {
            createdon: "N/A",
            createdby: "N/A",
            status: "N/A",
            modifiedon: "N/A",
            modifiedby: "N/A",
            text: "N/A",
            assignedTo: "N/A",
            deleted: false,
          },
          associations: responseData.associations || {
            children: [],
            parent: [],
            HARA: [],
          },
        };

        // Store the transformed data as an array
        setSafetyGoal([safetyGoalData]);
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch safety goal data", error);
    }
  };

  // Fetch details of a single safety requirement
  const fetchSafetyRequirement = async (reqId: string) => {
    try {
      const responseData = await getSafetyReq(reqId);
      if (responseData) {
        const safetyRequirementData: SafetyRequirement = {
          attributes_schema: responseData.attributes_schema || [],
          associations_template: responseData.associations_template || {
            children: [],
            parent: [],
            HARA: [],
          },
          heading: responseData.heading || "N/A",
          reference_rms_id: responseData.reference_rms_id || "N/A",
          project_version: responseData.project_version || "N/A",
          attributes_and_value: responseData.attributes_and_value || {
            createdon: "N/A",
            createdby: "N/A",
            status: "N/A",
            modifiedon: "N/A",
            modifiedby: "N/A",
            text: "N/A",
            assignedTo: "N/A",
            deleted: false,
          },
          associations: responseData.associations || {
            children: [],
            parent: [],
            HARA: [],
          },
        };

        // Store the transformed data as an array
        setSafetyRequirement([safetyRequirementData]);
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch safety requirement data", error);
    }
  };

  // Memoize the context value
  const value = useMemo(
    () => ({
      goalsTableTitle,
      goalsTableData,
      requirementsTableTitle,
      requirementsTableData,
      safetyGoals,
      safetyGoal,
      safetyRequirements,
      safetyRequirement,
      setGoalsParams,
      setRequirementsParams,
      updateSafetyParams,
      fetchSafetyGoals,
      fetchSafetyRequirements,
      fetchSafetyGoal,
      fetchSafetyRequirement,
    }),
    [
      goalsTableTitle,
      goalsTableData,
      requirementsTableTitle,
      requirementsTableData,
      safetyGoals,
      safetyGoal,
      safetyRequirements,
      safetyRequirement,
    ]
  );

  // Provide context value to children
  return (
    <SafetyContext.Provider value={value}>{children}</SafetyContext.Provider>
  );
};

// Custom hook to use the context
export const useSafety = () => {
  const context = useContext(SafetyContext);
  if (!context) {
    throw new Error("useSafety must be used within a SafetyProvider");
  }
  return context;
};
