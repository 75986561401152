import React from "react";
import { MdOutlineAssignment, MdOutlineViewList } from "react-icons/md";
import TableComparison from "./TableComparison";
import DetailedComparison from "./DetailedComparison";
import { CONST_URLS, VIEWS } from "../../constants/constants";
import useUrlParams from "../../hooks/useUrlParams";

function Comparison() {
  const { setParam } = useUrlParams()
  const { view } = useUrlParams()
  return (
    <>
      <div className="h-[56px] flex text-2xl items-center gap-4 justify-start text-zinc-500">
        <p className="text-greyText text-2xl font-semibold font-['Inter'] leading-loose">
          Comparison
        </p>
        <div>
          <MdOutlineViewList
            onClick={() => {
              setParam(CONST_URLS.view, VIEWS.table)
            }}
            className={`${view === VIEWS.table && "text-primary"
              } cursor-pointer`}
          />
        </div>
        <div>
          <MdOutlineAssignment
            onClick={() => {
              setParam(CONST_URLS.view, VIEWS.descriptive)
            }}
            className={`${view === VIEWS.descriptive && "text-primary"
              } cursor-pointer`}
          />
        </div>
      </div>
      {view === VIEWS.table && <TableComparison />}
      {view === VIEWS.descriptive && <DetailedComparison />}
    </>
  );
}

export default Comparison;
