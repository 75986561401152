import React from "react";
import { CircleTick } from "../../assets/Svg/Svg";
import { LOGIN_SUCCESS_STRINGS } from './constants';

function LoginSuccess() {
  return (
    <div className="modal-card w-[383px] h-[204px] bg-white p-12 flex flex-col justify-center items-center rounded-2xl">
      <p className="text-cyan-600 text-2xl font-semibold font-['Inter'] leading-loose pb-3">
        {LOGIN_SUCCESS_STRINGS.welcomeMessage}
      </p>
      <CircleTick />
      <p className="text-green-700 text-base font-normal font-['Inter'] leading-normal">
        {LOGIN_SUCCESS_STRINGS.successMessage}
      </p>
    </div>
  );
}

export default LoginSuccess;
