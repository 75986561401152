import { User } from "../../types/authTypes";
import ApiManager from "../ApiManager";
import { USER_API_ENDPOINTS } from "../ApiEndPoints";
import { removeStoredAuth, setStoredAuth } from "../../utils/localStorage";

const apiManager = ApiManager.getInstance();

export const login = async (email: string, password: string) => {
  try {
    const credentials = {
      username: email,
      password: password,
    };

    const response = await apiManager.post(
      USER_API_ENDPOINTS.LOGIN,
      credentials
    );

    if (response.detail) {
      throw new Error(response.detail);
    }

    // Extract the necessary details from the response
    const user = {
      username: response.user_info.Username,
      id: response.sgaf_user_id,
      userAttributes: response.user_info.UserAttributes,
    } as User;

    const token = response.access_token;

    // Store the token and user information securely in localStorage
    await setStoredAuth({ user: user, token: token });

    // Set the token in ApiManager for future requests
    apiManager.setToken(token);
    return { user, token };
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.detail || "Login failed. Please try again.";
    throw new Error(errorMessage);
  }
};

export const logout = async () => {
  try {
    const response = await apiManager.post(USER_API_ENDPOINTS.LOGOUT, {});
    if (response.success) {
      removeStoredAuth();
    }
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (username: string) => {
  try {
    const response = await apiManager.post(USER_API_ENDPOINTS.FORGOT_PASSWORD, {
      username,
    });
    if (!response.ok) {
      return response;
    }
    return response.detail;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (credentials: any) => {
  try {
    const response = await apiManager.post(USER_API_ENDPOINTS.RESET_PASSWORD, {
      credentials,
    });

    if (!response.ok) {
      return response;
    }
    return response.detail;
  } catch (error) {
    throw error;
  }
};
