export const USER_API_ENDPOINTS = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
};

export const PROJECTS_API_ENDPOINTS = {
  PRESIGNED_URL: "/presigned-url",
  GET_PROJECTS: "/projects",
  ADD_PROJECT: "/projects",
  IMPORT_NEW_VERSION: "/projects",
  GET_PROJECT: "/projects",
  GET_VERSIONS: "/projects",
};

export const FUNC_REQ_ENDPOINTS = {
  GET_FUNC_REQ: "/functional_requirements",
  GET_FUNC_REQS: "/functional_requirements/project",
  GET_FUNC_REQS_SCHEMA: "/functional_requirements/schema",
};

export const HARA_ENDPOINTS = {
  GET_HARA: "/haras",
  GET_HARAS: "/haras/project",
  GET_HARA_SCHEMA: "/haras/schema",
  ADD_HARA_DEF: "/haras",
};

export const SAFETY_GOALS_ENDPOINTS = {
  GET_SAFETY_GOAL: "/safety_goals",
  GET_SAFETY_GOALS: "/safety_goals/project",
  GET_SAFETY_GOAL_SCHEMA: "/safety_goals/schema",
};

export const SAFETY_REQ_ENDPOINTS = {
  GET_SAFETY_REQ: "/safety_requirements",
  GET_SAFETY_REQS: "/safety_requirements/project",
  GET_SAFETY_REQS_SCHEMA: "/safety_requirements/schema",
};

export const HAZARDS_ENDPOINTS = {
  GET_HAZARD: "/hazards",
  GET_HAZARD_LIST: "/hazards",
  ADD_HAZARDS: "/hazards",
  UPDATE: "/hazards",
};
