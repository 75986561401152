import React, { useEffect } from "react";
import WikiContent from "./WikiContent";
import { CONST_URLS, VIEWS } from "../../constants/constants";
import { IoMdClose } from "react-icons/io";
import { MdOutlineDone, MdOutlineEdit } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import EditWiki from "./EditWiki";
import useUrlParams from "../../hooks/useUrlParams";
import { useProjectContext } from "../../contexts/ProjectContext";

interface WikiProps {
  view: string;
}

const Wiki: React.FC<WikiProps> = ({ view }) => {
  const [, setSearchParams] = useSearchParams();
  const { setParam, projectId } = useUrlParams();
  const { fetchProjectDetails } = useProjectContext();

  const { projectDetails, loadingProjectDetails } = useProjectContext();

  const projectTitle =
    projectDetails.length > 0 ? projectDetails[0]?.heading : "N/A";
  const projectDescription =
    projectDetails.length > 0 ? projectDetails[0]?.description : "N/A";

  const handleEdit = () => {
    setParam(CONST_URLS.view, VIEWS.edit);
  };
  const handleCancel = () => {
    setSearchParams((params) => {
      params.delete(CONST_URLS.view);
      return params;
    });
  };

  const renderComponent = () => {
    if (view === VIEWS.edit) {
      return <EditWiki />;
    } else {
      return (
        <WikiContent
          loadingProjectDetails={loadingProjectDetails}
          projectTitle={projectTitle}
          projectDescription={projectDescription}
        />
      );
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectDetails(projectId);
    }
  }, []);

  return (
    <div className="h-full w-full flex flex-col">
      <div className="h-[56px] w-full flex items-center gap-2 pr-10 border-b border-black/opacity-20 justify-end pe-4">
        {view === VIEWS.edit ? (
          <div className="flex gap-2">
            <IoMdClose
              onClick={handleCancel}
              className="text-danger cursor-pointer text-2xl"
            />
            <MdOutlineDone className="text-primary cursor-pointer text-2xl" />
          </div>
        ) : (
          <MdOutlineEdit
            onClick={handleEdit}
            className="text-primary cursor-pointer text-2xl"
          />
        )}
      </div>
      <div className="flex-1 overflow-y-auto">{renderComponent()}</div>
    </div>
  );
};

export default Wiki;
