import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from "react";
import { FUNC_CHILD, PAGES, VIEWS } from "../constants/constants";
import useUrlParams from "../hooks/useUrlParams";
import {
  getFuncReq,
  getFuncReqs,
} from "../services/Api/FunctionalRequirements";
import { useHaraTable } from "../contexts/HaraContext";
import {
  FunctionRequirement,
  FunctionalReqs,
  HazardTableData,
  FunctionContextType,
} from "../types/FunctionContextTypes";

const initialState = {
  functionTableTitle: [
    "ID",
    "Heading",
    "Reference ID",
    "Description",
    "Associated Child Request IDs",
    "Associated Child RMS IDs",
    "Associated Child Heading",
    "Associated Child Component",
    "Associated Parent Request IDs",
    "Associated Parent RMS IDs",
    "Associated Parent Heading",
    "Associated Parent Component",
  ],
  triggeringEventTableTitle: [
    "ID",
    "Description",
    "Severity",
    "Failure",
    "Error",
    "...",
  ],
  triggeringEventTableData: [
    {
      id: "TE-AC001",
      description: "Failure to detect vehicle ahead",
      severity: "...",
      failure: "[F-001]",
      error: "[ERROR-001]",
      dot1: "...",
    },
  ],
  hazardTableTitle: [
    "ID",
    "Heading",
    "Reference ID",
    "Description",
    "ASIL",
    "Severity",
    "Exposure",
    "Created By",
    "Created On",
    "Modified By",
    "Modified On",
    "Controllability",
    "Associated Child ID",
    "Associated Parent ID",
    "System Element ID",
    "Safety Goals ",
  ],
  preliminaryArchitecture: [{ id: "ARCH-001" }, { id: "ARCH-002" }],
};

const FunctionContext = createContext<FunctionContextType | undefined>(
  undefined
);

export const FunctionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setParams } = useUrlParams();
  const { haraTableData } = useHaraTable();
  const [loading, setLoading] = useState(false);
  const [functionalRequirement, setFunctionalRequirement] = useState<
    FunctionRequirement[]
  >([]);
  const [functionalRequirements, setFunctionalRequirements] = useState<
    FunctionalReqs[]
  >([]);
  const [hazardTableData, setHazardTableData] = useState<HazardTableData[]>([]);

  const functionTableData = useMemo(() => {
    return functionalRequirements.map((req) => ({
      id: req.id || "N/A",
      heading: req.heading || "N/A",
      reference_id: "FR-" + req.reference_id || "N/A",
      description: req.description || "N/A",
      assChildReqId:
        req.associations.children.map((c) => c.reference_req_id).join(", ") ||
        "N/A",
      assChildRmsId:
        req.associations.children.map((c) => c.reference_rms_id).join(", ") ||
        "N/A",
      assChildHeading:
        req.associations.children.map((c) => c.reference_heading).join(", ") ||
        "N/A",
      assChildComponent:
        req.associations.children.map((c) => c.component_name).join(", ") ||
        "N/A",
      assParentReqId:
        req.associations.parent.map((p) => p.reference_req_id).join(", ") ||
        "N/A",
      assParentRmsId:
        req.associations.parent.map((p) => p.reference_rms_id).join(", ") ||
        "N/A",
      assParentHeading:
        req.associations.parent.map((p) => p.reference_heading).join(", ") ||
        "N/A",
      assParentComponent:
        req.associations.parent.map((p) => p.component_name).join(", ") ||
        "N/A",
    }));
  }, [functionalRequirements]);

  const hazardTableDataMemo = useMemo(() => {
    return haraTableData.map((hara) => ({
      id: hara.id || "N/A",
      heading: hara.heading || "N/A",
      reference_id: hara.reference_id || "N/A",
      description: hara.description || "N/A",
      asil_of_hazard: hara.asil_of_hazard || "N/A",
      severity: hara.severity || "N/A",
      exposure: hara.exposure || "N/A",
      created_by: hara.created_by || "N/A",
      created_on: hara.created_on || "N/A",
      modified_by: hara.modified_by || "N/A",
      modified_on: hara.modified_on || "N/A",
      controllability: hara.controllability || "N/A",
      assChildRmsId: hara.assChildRmsId || "N/A",
      assParentRmsId: hara.assParentRmsId || "N/A",
      assSystemRmsId: hara.assSystemRmsId || "N/A",
      safetyRmsId: hara.safetyRmsId || "N/A",
    }));
  }, [haraTableData]);

  useEffect(() => {
    setHazardTableData(hazardTableDataMemo);
  }, [hazardTableDataMemo]);

  const setFunctionParams = (itemName: string) => {
    setParams({
      child: itemName,
      view: VIEWS.table,
      id: functionTableData[0]?.id,
    });
  };

  const setTriggeringEventParams = (itemName: string) => {
    setParams({
      child: itemName,
      view: VIEWS.table,
      id: initialState.triggeringEventTableData[0]?.id,
    });
  };

  const setHazardsParams = (itemName: string) => {
    setParams({
      child: itemName,
      view: VIEWS.table,
      id: hazardTableData[0]?.id,
    });
  };

  const setPreArcParams = (itemName: string) => {
    setParams({
      child: itemName,
      view: VIEWS.architecture,
      id: initialState.preliminaryArchitecture[0]?.id,
    });
  };
  const setNewParams = (itemName: string) => {
    setParams({
      page: PAGES.function,
      child: itemName,
    });
  };

  const updateFunctionParams = (itemName: string) => {
    switch (itemName) {
      case FUNC_CHILD.functionalReq:
        setFunctionParams(itemName);
        break;
      case FUNC_CHILD.triggeringEvt:
        setTriggeringEventParams(itemName);
        break;
      case FUNC_CHILD.hazards:
        setHazardsParams(itemName);
        break;
      case FUNC_CHILD.preArch:
        setPreArcParams(itemName);
        break;
      default:
        setNewParams(itemName);
    }
  };

  const getFunctionalRequirements = async (projectId: string) => {
    try {
      const responseData = await getFuncReqs(projectId);
      if (Array.isArray(responseData)) {
        setFunctionalRequirements(responseData);
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch functional requirements", error);
    }
  };

  // get Functional Requirement API call using req id
  const getFunctionalRequirement = async (reqId: string) => {
    try {
      setLoading(true);
      const funReq = await getFuncReq(reqId);
      if (funReq) {
        // Ensure that the required fields are present
        const mappedFunReq: FunctionRequirement = {
          attributes_schema: funReq.attributes_schema || [],
          associations_template: funReq.associations_template || {
            children: [],
            parent: [],
            "Allocated to": [],
          },
          heading: funReq.heading || "",
          reference_rms_id: funReq.reference_rms_id || "",
          project_version: funReq.project_version,
          attributes_and_value: funReq.attributes_and_value || {
            createdon: "",
            createdby: "",
            status: "",
            modifiedon: "",
            modifiedby: "",
            text: "",
            complexity: null,
            "story points": 0,
            attachments: null,
            type: null,
            color: "",
            resolution: null,
            "assigned to": "",
            "abstraction level": null,
            deleted: false,
            "business value": null,
          },
          associations: funReq.associations || {
            children: [],
            parent: [],
          },
        };
        setFunctionalRequirement([mappedFunReq]);
      } else {
        console.warn("No data returned for functional requirement");
      }
    } catch (error) {
      console.error("Failed to fetch functional requirement", error);
    } finally {
      setLoading(false);
    }
  };

  const value = useMemo(
    () => ({
      functionalRequirements,
      functionalRequirement,
      functionTableTitle: initialState.functionTableTitle,
      functionTableData,
      triggeringEventTableTitle: initialState.triggeringEventTableTitle,
      triggeringEventTableData: initialState.triggeringEventTableData,
      hazardTableTitle: initialState.hazardTableTitle,
      hazardTableData,
      preliminaryArchitecture: initialState.preliminaryArchitecture,
      setFunctionParams,
      setTriggeringEventParams,
      setHazardsParams,
      setPreArcParams,
      setNewParams,
      updateFunctionParams,
      getFunctionalRequirements,
      getFunctionalRequirement,
      loading,
    }),
    [
      functionalRequirements,
      functionalRequirement,
      functionTableData,
      hazardTableData,
      loading,
    ]
  );

  return (
    <FunctionContext.Provider value={value}>
      {children}
    </FunctionContext.Provider>
  );
};

export const useFunctionTable = () => {
  const context = useContext(FunctionContext);
  if (!context) {
    throw new Error("useFunctionTable must be used within a FunctionProvider");
  }
  return context;
};
