import React from "react";
import { MdFolderOpen } from "react-icons/md";
import { ADD_NEW_CONSTANTS } from "./constants";
import "../../../pages/Project/Project.css";

const Addnew = ({ setSelectModal }: any) => {
  return (
    <div className="w-[320px] h-[168px] p-6 flex-col justify-start items-start gap-6 inline-flex">
      <div className="w-full h-6 text-zinc-800 text-2xl font-semibold font-['Inter']">
        {ADD_NEW_CONSTANTS.ADD_NEW_TITLE}
      </div>

      <div className="flex w-full h-16 justify-between items-center">
        <button
          onClick={() => setSelectModal(2)}
          className="w-[129px] cursor-pointer h-16 flex items-center justify-center rounded-[10px] cyan-shadow border-2 border-cyan-600 border-opacity-20 backdrop-blur-sm hover:bg-cyan-600 hover:bg-opacity-20"
          style={{ background: "none", border: "none", padding: 0 }}
        >
          <div className="mr-2 text-2xl">
            <MdFolderOpen />
          </div>
          <div className="text-black/opacity-20 text-base font-semibold font-['Inter'] leading-loose">
            {ADD_NEW_CONSTANTS.FOLDER_LABEL}
          </div>
        </button>

        <button
          onClick={() => setSelectModal(1)}
          className="w-[129px] cyan-shadow cursor-pointer h-16 flex items-center justify-center rounded-[10px] border-2 border-cyan-600 border-opacity-20 backdrop-blur-sm hover:bg-cyan-600 hover:bg-opacity-20"
          style={{ background: "none", border: "none", padding: 0 }}
        >
          <div className="mr-2 text-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <g clipPath="url(#clip0_14_23524)">
                <path
                  d="M14 2.56641H6C4.9 2.56641 4.01 3.46641 4.01 4.56641L4 20.5664C4 21.6664 4.89 22.5664 5.99 22.5664H18C19.1 22.5664 20 21.6664 20 20.5664V8.56641L14 2.56641ZM6 20.5664V4.56641H13V9.56641H18V20.5664H6Z"
                  fill="#616161"
                />
                <path
                  d="M14 2.56641H6C4.9 2.56641 4.01 3.46641 4.01 4.56641L4 20.5664C4 21.6664 4.89 22.5664 5.99 22.5664H18C19.1 22.5664 20 21.6664 20 20.5664V8.56641L14 2.56641ZM6 20.5664V4.56641H13V9.56641H18V20.5664H6Z"
                  fill="black"
                  fillOpacity="0.2"
                />
                <path
                  d="M14 2.56641H6C4.9 2.56641 4.01 3.46641 4.01 4.56641L4 20.5664C4 21.6664 4.89 22.5664 5.99 22.5664H18C19.1 22.5664 20 21.6664 20 20.5664V8.56641L14 2.56641ZM6 20.5664V4.56641H13V9.56641H18V20.5664H6Z"
                  fill="black"
                  fillOpacity="0.2"
                />
              </g>
              <defs>
                <clipPath id="clip0_14_23524">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.566406)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="text-black/opacity-20 text-base font-semibold font-['Inter'] leading-loose">
            {ADD_NEW_CONSTANTS.PAGE_LABEL}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Addnew;
