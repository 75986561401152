import React, { useState } from "react";
import { CONST_URLS, FUNC_CHILD, SAFETY_CHILDS, VIEWS } from "../../constants/constants";
import useUrlParams from "../../hooks/useUrlParams";
import { useFunctionTable } from "../../contexts/FunctionContext";
import { useHaraTable } from "../../contexts/HaraContext";
import { useSafety } from "../../contexts/SafetyContext";

interface FuncDescrepTextFieldProps {
  label: string;
  textValue: string;
  onTextChange: (newText: string) => void;
}

const FuncDescrepTextField: React.FC<FuncDescrepTextFieldProps> = ({
  label,
  textValue,
  onTextChange,
}) => {
  const { getFunctionalRequirement } = useFunctionTable();
  const { getHaraData } = useHaraTable();
  const { fetchSafetyGoal, fetchSafetyRequirement } = useSafety();

  const { id, setParam, child } = useUrlParams();
  const isHighlighted = id === label;

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onTextChange(e.target.value);
  };

  const handleClick = () => {
    if (selectedItem === label) {
      // If the same item is clicked again, perform the action
      if (child === FUNC_CHILD.functionalReq) {
        getFunctionalRequirement(label);
      } else if (child === FUNC_CHILD.hazards) {
        getHaraData(label);
      } else if (child === SAFETY_CHILDS.safetyGoals) {
        fetchSafetyGoal(label);
      } else if (child === SAFETY_CHILDS.safetyRequirements) {
        fetchSafetyRequirement(label);
      }
      setParam(CONST_URLS.view, VIEWS.detailed);
      // Reset selected item after action
      setSelectedItem(null);
    } else {
      // Select the current item
      setSelectedItem(label);
      setParam(CONST_URLS.id, label);
    }
  };

  return (
    <button
      className="w-full relative px-6 cursor-pointer"
      onClick={handleClick}
    >
      <div
        className={`text-xl font-semibold font-['Inter'] z-[1] bg-transparent leading-normal absolute -top-1 left-7 rounded-md ${isHighlighted ? "text-primary" : "text-gray-700"
          }`}
      >
        {label}
      </div>
      <div
        className={`py-6 h-auto w-full mb-2 flex items-start justify-start rounded-md ${isHighlighted ? "cyan-shadow" : "funcion-text-shadow"
          }`}
      >
        <textarea
          className="w-full px-5 py-3 border-none backdrop-blur-1 bg-transparent bg-opacity-20 rounded-md resize-none focus:outline-none focus:ring-0 placeholder-black"
          placeholder="Functional Description"
          value={textValue}
          onChange={handleChange}
          style={{ minHeight: "15rem" }}
        ></textarea>
      </div>
    </button>
  );
};

export default FuncDescrepTextField;
