import React from "react";
import Table from "./Table";
import { useFunctionTable } from "../../contexts/FunctionContext";
import { FUNC_CHILD } from "../../constants/constants";
import useUrlParams from "../../hooks/useUrlParams";
import { FunctionalReqData, TriggeringEventData, HazardData, TableData } from './types'

const FunctionTable: React.FC = () => {
  const {
    functionTableTitle,
    functionTableData,
    triggeringEventTableTitle,
    triggeringEventTableData,
    hazardTableTitle,
    hazardTableData,
  } = useFunctionTable();

  const { child } = useUrlParams();

  // Function to map data based on the table type
  const mapTableData = (
    data: FunctionalReqData[] | TriggeringEventData[] | HazardData[],
    type: string
  ): TableData => {
    switch (type) {
      case FUNC_CHILD.functionalReq:
        return (data as FunctionalReqData[]).map((data) => ({
          id: data.id,
          heading: data.heading,
          reference_id: data.reference_id,
          description: data.description,
          assChildReqId: data.assChildReqId,
          assChildRmsId: data.assChildRmsId,
          assChildHeading: data.assChildHeading,
          assChildComponent: data.assChildComponent,
          assParentReqId: data.assParentReqId,
          assParentRmsId: data.assParentRmsId,
          assParentHeading: data.assChildHeading,
          assParentComponent: data.assParentComponent,
        }));
      case FUNC_CHILD.triggeringEvt:
        return (data as TriggeringEventData[]).map((data) => ({
          id: data.id,
          description: data.description,
          severity: data.severity,
          failure: data.failure,
          error: data.error,
          dot1: data.dot1,
        }));
      case FUNC_CHILD.hazards:
        return (data as HazardData[]).map((hara) => ({
          id: hara.id,
          heading: hara.heading,
          reference_id: hara.reference_id,
          description: hara.description,
          asil_of_hazard: hara.asil_of_hazard,
          severity: hara.severity,
          exposure: hara.exposure,
          created_by: hara.created_by,
          created_on: hara.created_on,
          modified_by: hara.modified_by,
          modified_on: hara.modified_on,
          controllability: hara.controllability,
          assChildRmsId: hara.assChildRmsId,
          assParentRmsId: hara.assParentRmsId,
          assSystemRmsId: hara.assSystemRmsId,
          safetyRmsId: hara.safetyRmsId,
        }));
      default:
        return [];
    }
  };

  // Function to retrieve table title and data based on the child type
  const getTableData = (): { title: string[]; data: TableData } => {
    switch (child) {
      case FUNC_CHILD.functionalReq:
        return {
          title: functionTableTitle,
          data: mapTableData(functionTableData, FUNC_CHILD.functionalReq),
        };
      case FUNC_CHILD.triggeringEvt:
        return {
          title: triggeringEventTableTitle,
          data: mapTableData(
            triggeringEventTableData,
            FUNC_CHILD.triggeringEvt
          ),
        };
      case FUNC_CHILD.hazards:
        return {
          title: hazardTableTitle,
          data: mapTableData(hazardTableData, FUNC_CHILD.hazards),
        };
      default:
        return { title: [], data: [] };
    }
  };

  const { title: tableTitle, data: tableData } = getTableData();

  return (
    <div className="function-req-wrapper w-full flex flex-col h-[70vh] px-8">
      <Table tableTitle={tableTitle} tableData={tableData} />
    </div>
  );
};

export default FunctionTable;
