import React, { useState } from "react";
import ProfileIcon from "../../../assets/Images/profileicon.png";
import GermanFlag from "../../../assets/Images/german-flag.png";
import UkFlag from "../../../assets/Images/uk-flag.png";
import Modal from "../../Modal/Modal";
import Logout from "../Logout";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { paths } from "../../../constants/paths";
import { USER_INFO_CONSTANTS } from "./constants";

const languages = [
  { name: "Deutsch", flag: GermanFlag },
  { name: "English", flag: UkFlag },
];

function UserInfo() {
  const { state } = useAuth();
  const { user } = state;
  const name = user?.userAttributes?.find(
    (attr: any) => attr.Name === "name"
  )?.Value;

  const [showModal, setShowModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("English"); // Default to English

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
  };

  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <Logout onClose={closeModal} />
      </Modal>
      <div className="modal-card w-[360px] h-[364px] bg-white py-6 px-8 flex flex-col rounded-b-2xl">
        <div className="user-dp flex items-center gap-4">
          <img
            src={ProfileIcon}
            alt="user-dp"
            className="w-[60px] h-[60px] rounded-full"
          />
          <div className="email">
            <p className="text-zinc-800 text-base font-semibold font-['Inter'] leading-normal">
              {name}
            </p>
            <p className="text-zinc-800 text-base font-normal font-['Heebo'] leading-normal">
              {user?.username}
            </p>
          </div>
        </div>
        <div className="settings pt-6">
          <Link to={paths.account}>
            <p className="text-zinc-800 text-base font-normal font-['Heebo'] leading-normal hover:opacity-40 w-fit cursor-pointer">
              {USER_INFO_CONSTANTS.ACCOUNT_SETTINGS_TEXT}
            </p>
          </Link>
          <p className="text-zinc-800 text-base font-normal font-['Heebo'] leading-normal hover:opacity-40 w-fit py-2 cursor-pointer">
            {USER_INFO_CONSTANTS.PREFERENCES_TEXT}
          </p>
          <Link to={paths.support}>
            <p className="text-zinc-800 text-base font-normal font-['Heebo'] leading-normal hover:opacity-40 w-fit cursor-pointer">
              {USER_INFO_CONSTANTS.HELP_TEXT}
            </p>
          </Link>
        </div>
        <div className="language py-4 my-4 border-y-2 border-stone-300">
          {languages.map((language) => (
            <button
              key={language.name}
              className={`flex items-center gap-2 hover:opacity-40 w-fit cursor-pointer`}
              onClick={() => handleLanguageSelect(language.name)}
            >
              <img src={language.flag} alt="flag" className="h-4 w-[22px]" />
              <div
                className={`text-base font-normal font-['Heebo'] leading-normal ${selectedLanguage === language.name ? "text-gray-400" : ""}`}
              >
                {language.name}
              </div>
            </button>
          ))}
        </div>
        <button
          className="cursor-pointer text-zinc-800 text-base font-normal font-['Heebo'] leading-normal hover:opacity-40 w-fit"
          onClick={openModal}
        >
          {USER_INFO_CONSTANTS.LOG_OUT_TEXT}
        </button>
      </div>
    </>
  );
}

export default UserInfo;
