import React, { forwardRef } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../contexts/ProjectContext";
import { paths } from "../constants/paths";

interface HeaderItemsProps {
  displayText: string;
  onItemClick: () => void;
  isOpen: boolean;
  dropdownItems: string[];
}

const HeaderItems: React.ForwardRefRenderFunction<
  HTMLDivElement,
  HeaderItemsProps
> = ({ displayText, onItemClick, isOpen, dropdownItems }, ref) => {
  const navigate = useNavigate();
  const { projects, fetchProjectDetails } = useProjectContext();

  const onTextClick = () => {
    navigate(paths.project.project);
  };

  const handleDropdownItemClick = (item: string) => {
    const project = projects.find((p) => p.project_name === item);
    if (project) {
      fetchProjectDetails(project.project_id); //fetch specific project details
      navigate(paths.project.projectWikiPage(project.project_id));
    }
  };

  return (
    <div className="relative" ref={ref}>
      <div
        className={`no-select cursor-pointer font-semibold border-t-2 border-r-2 border-gray-200 font-internship flex h-10 px-4 py-1 justify-center items-center gap-1 rounded-t-2xl bg-primary text-white`}
      >
        <button onClick={onTextClick}>{displayText}</button>
        <FaAngleDown
          className={`transition-transform z-30 duration-300 ${isOpen ? "rotate-180" : ""
            }`}
          onClick={onItemClick}
        />
      </div>
      {isOpen && dropdownItems.length > 0 && (
        <div className="absolute left-0 shadow-md bg-white border border-gray-200 py-2 mt-1 rounded-b-lg w-[230px] max-h-[250px] overflow-auto z-10">
          <div className="scrollbar scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {dropdownItems.map((item, index) => (
              <button
                key={index}
                className="dropdown-item px-4 py-2 hover:bg-gray-200 text-left"
                onClick={() => handleDropdownItemClick(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(HeaderItems);
