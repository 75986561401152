import React from "react";
import arch from "../../assets/Svg/arch-001.svg";

function FunctionArchitecture() {
  return (
    <div className="p-5">
      <img src={arch} alt="architecture" />
    </div>
  );
}

export default FunctionArchitecture;
