import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants/paths";
import {
  OTP_STEP_TITLE,
  CHANGE_EMAIL_TEXT,
  DID_NOT_RECEIVE_CODE_TEXT,
  SEND_AGAIN_TEXT
} from "./constants";
import { OtpStepProps } from "./types";

const StepTwo: React.FC<OtpStepProps> = ({
  email,
  otp,
  setOtp,
  handleOtpKeyDown,
  handleSendOtpAgain,
  error,
  setError,
  handleSubmitCode
}) => {
  const navigate = useNavigate();
  const otpInputs = useRef<HTMLInputElement[]>([]);

  const handleOtpChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  return (
    <div className="otp-card w-[498px] bg-white shadow-2xl py-16 px-12">
      <p className="text-xl font-semibold font-['Inter'] leading-7 text-mainBlack text-center">
        {OTP_STEP_TITLE}
      </p>
      <div className="change-email text-center pt-3">
        <span className="text-center text-base font-normal font-['Heebo'] leading-normal text-mainBlack">
          {email}
        </span>
        <button
          onClick={() => {
            setError("");
            navigate(paths.resetPassword.step1);
          }}
          className="text-center text-base font-medium font-['Heebo'] underline leading-normal text-primary pl-1"
        >
          {CHANGE_EMAIL_TEXT}
        </button>
      </div>
      <div className="py-12 ">
        <div className="otp-input flex gap-3 justify-center ">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (otpInputs.current[index] = el!)}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleOtpChange(index, e.target.value)}
              onKeyDown={(e) => handleOtpKeyDown(index, e)}
              className={`focus:ring-0 focus:outline-none focus:ring-borderSecondaryGrey border h-[56px] w-[56px] border-borderSecondaryGrey py-4 px-3 rounded-lg text-center ${error ? "border-red-500" : "border-borderSecondaryGrey"
                }`}
            />
          ))}
        </div>
        {error && (
          <p className="ml-2 text-red-500 text-base font-normal font-['Heebo'] leading-normal">
            {error}
          </p>
        )}
      </div>
      <button
        onClick={handleSubmitCode}
        disabled={otp.some((digit) => digit === "")}
        className={`text-base font-semibold font-['Inter'] leading-normal text-white text-center w-full rounded-lg py-4 ${otp.some((digit) => digit === "")
          ? "bg-[#eeeeee] text-borderSecondaryGrey cursor-not-allowed"
          : "bg-primary"
          }`}
      >
        <span className="text-base font-semibold">Submit</span>
      </button>
      <div className="flex justify-center text-base font-medium font-['Heebo'] leading-normal text-center pt-6">
        <p className="text-secondaryGrey500">
          {DID_NOT_RECEIVE_CODE_TEXT}
        </p>
        <button
          onClick={handleSendOtpAgain}
          className="underline text-primary cursor-pointer ml-1"
        >
          {SEND_AGAIN_TEXT}
        </button>
      </div>
    </div>
  );
}

export default StepTwo;
