import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useVersions } from "../../contexts/VersionsContext";
import VersionsTable from "./VersionsTable";
import Modal from "../../Components/Modal/Modal";
import ComparePopup from "../../Components/Popups/Version/ComparePopup";
import Comparison from "./Comparison";
import { CONST_URLS, PAGES, VIEWS } from "../../constants/constants";
import useUrlParams from "../../hooks/useUrlParams";
import { ALL_VERSIONS_TEXT } from "./constants";

function AllVersions() {
  const [, setSearchParams] = useSearchParams();
  const { versionTableTitles, versionTableData } = useVersions();
  const [showModal, setShowModal] = useState(false);
  const { view, setParam } = useUrlParams();

  const openModal = () => {
    setShowModal(true);
    setSearchParams((params) => {
      params.delete(CONST_URLS.version1);
      params.delete(CONST_URLS.version2);
      return params;
    });
    setParam(CONST_URLS.view, "popup");
  };

  const closeModal = () => {
    setShowModal(false);
    setSearchParams((params) => {
      params.delete(CONST_URLS.view);
      params.delete(CONST_URLS.version1);
      params.delete(CONST_URLS.version2);
      return params;
    });
  };

  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <ComparePopup onClose={closeModal} />
      </Modal>
      <div className="px-4 h-[56px] flex text-2xl items-center gap-4 border-b border-black/opacity-20 justify-between text-zinc-500 ">
        <button
          onClick={() => {
            setSearchParams((params) => {
              if (view === VIEWS.table || view === VIEWS.descriptive) {
                params.delete(CONST_URLS.view);
                params.delete(CONST_URLS.version1);
                params.delete(CONST_URLS.version2);
              } else {
                params.set(CONST_URLS.page, PAGES.wiki);
              }
              return params;
            });
          }}
          className="text-primary cursor-pointer pl-5 flex justify-start items-center h-full text-base font-semibold font-['Inter']"
        >
          <FaAngleLeft />
          {view === VIEWS.table || view === VIEWS.descriptive ? (
            <p>{ALL_VERSIONS_TEXT.BACK_TO_ALL_VERSIONS}</p>
          ) : (
            <p className="pl-1 ">{ALL_VERSIONS_TEXT.BACK}</p>
          )}
        </button>
        <button
          onClick={openModal}
          className="px-4 py-2 text-center text-white text-xs font-semibold font-['Inter'] leading-tight absolute right-7 h-8 flex justify-center items-center rounded-3xl bg-primary"
        >
          {ALL_VERSIONS_TEXT.COMPARE_VERSIONS_BUTTON}
        </button>
      </div>

      <div className="w-full">
        <div className="px-11 py-3">
          <div>
            {view === VIEWS.table && <Comparison />}
            {view === VIEWS.descriptive && <Comparison />}
            {view !== VIEWS.table && view !== VIEWS.descriptive && (
              <div>
                <p className="text-greyText text-2xl font-semibold font-['Inter'] leading-loose">
                  {ALL_VERSIONS_TEXT.VERSIONS_TITLE}
                </p>
                <VersionsTable
                  tableTitle={versionTableTitles}
                  tableData={versionTableData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllVersions;
