import React from "react";
import tick from "../../../assets/Svg/tick-circle.svg";
import { IMPORT_SUCCESSFUL_STRINGS } from './constants';

interface ImportSuccessfulProps {
  redirectText: string;
}

const ImportSuccessful: React.FC<ImportSuccessfulProps> = ({ redirectText }) => {
  return (
    <div className="modal-card w-[350px] h-auto bg-white py-8 px-4 flex flex-col justify-center items-center gap-4 rounded-2xl">
      <img alt="checkmark" src={tick} />
      <p className="text-green-700 text-base font-semibold font-['Inter'] leading-normal">
        {IMPORT_SUCCESSFUL_STRINGS.title}
      </p>
      <p className="text-center text-cyan-600 text-base font-normal font-['Inter'] leading-normal px-8">
        {redirectText}
      </p>
    </div>
  );
};

export default ImportSuccessful;
