import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import HeaderLayout from "../../Components/HeaderLayout";
import { CONTACT_SUPPORT_TEXT } from "./constants";
import { paths } from "../../constants/paths";

function ContactSupport() {
  return (
    <HeaderLayout>
      <Link to={paths.login}>
        <div className="border-b-2 cursor-pointer h-14 w-screen pl-10 flex items-center justify-start text-teal text-base font-semibold font-['Inter']">
          <MdKeyboardArrowLeft className="text-teal text-2xl" />
          {CONTACT_SUPPORT_TEXT.BACK_BUTTON}
        </div>
      </Link>
      <div className="w-screen min-h-[85vh] bg-login-bg flex justify-center items-center">
        <div className="support-card bg-white rounded-2xl px-12 pt-12 pb-16 w-[538px]">
          <div className="text-black/opacity-20 text-2xl font-semibold leading-loose">
            {CONTACT_SUPPORT_TEXT.HEADING}
          </div>
          <div className="text-black/opacity-20 text-base font-normal leading-normal py-5">
            {CONTACT_SUPPORT_TEXT.DESCRIPTION}
          </div>
          <a
            href="/"
            className="text-cyan-600 text-2xl font-medium underline leading-loose"
          >
            {CONTACT_SUPPORT_TEXT.SUPPORT_EMAIL}
          </a>
        </div>
      </div>
    </HeaderLayout>
  );
}

export default ContactSupport;
