export const CONST_URLS = {
  page: "page",
  view: "view",
  child: "child",
  id: "id",
  pageNo: "pageNo",
  version1: "version1",
  version2: "version2",
};

export const PAGES = {
  wiki: "Wiki",
  function: "Function",
  hara: "Hazard",
  safety: "Safety",
  analysis: "Analysis",
  versions: "versions",
  hazard: "Hazards & Risk Assessment",
  stoif: "SOTIF Analysis",
};

export const VIEWS = {
  table: "table",
  descriptive: "descriptive",
  detailed: "detailed",
  graph: "graph",
  architecture: "architecture",
  edit: "edit",
};

export const FUNC_CHILD = {
  functionalReq: "Functional requirement",
  triggeringEvt: "Triggering Events (HAZOP)",
  hazards: "Hazards",
  preArch: "Preliminary Architecture",
};

export const HARA_CHILDS = {
  haraTables: "Hara Tables",
  operConditions: "Operational Conditions",
};

export const SAFETY_CHILDS = {
  safetyGoals: "Safety Goals",
  safetyRequirements: "Safety Requirements",
};

const createFuncReqTextFieldValues = (count: number) => {
  return Array.from({ length: count }, (_, index) => ({
    id: `${index + 1}`,
    label: `FR-00${index + 1}`,
    textValue: "",
  }));
};

export const FUNC_REQ_TEXTFILED_VALUES = createFuncReqTextFieldValues(3);

export const FUNC_REQ_EDIT_VALUES = [
  ["ID", "Type", "Status", "Label", "Label2"],
  ["Parent", "Child Reference", "Label", "Label2", "Label3"],
  ["Label", "Label2", "Label3", "Label4", "Label5"],
];

const createVersionSectionItems = (id: string, main: string, sub: string) => ({
  main,
  sub,
  details: {
    id,
    title: [
      "Maintain safe following distance",
      "...",
      "...",
      "-",
      "FR-ACC01A,FR-ACC01B",
      "HZ-ACC01",
      "SG-ACC01",
    ],
    data: [
      "Vehicle detection system",
      "...",
      "...",
      "FR-ACC01",
      "FR-ACC01A,FR-ACC01B",
      "HZ-ACC01",
      "SG-ACC01",
    ],
  },
});

export const VERSIONS_SECTIONS = [
  {
    id: "isFunctionOpen",
    title: "Function",
    stats: { changed: 2, added: 2, deleted: 6 },
    content: [
      {
        title: "Functional Requirements",
        items: [
          createVersionSectionItems("01", "FR-AC001", "FR-AC001.1"),
          createVersionSectionItems("02", "FR-AC002", "FR-AC002.1"),
        ],
      },
      {
        title: "Triggering Events",
        items: [createVersionSectionItems("03", "-", "TE-AC002")],
      },
      {
        title: "Hazards",
        items: [
          createVersionSectionItems("04", "HZ-AC001", "HZ-AC001.1"),
          {
            main: "HZ-AC002",
            sub: "",
            details: {
              id: "05",
              title: [
                "Sensor malfunction",
                "S",
                "E",
                "C",
                "ASIL D",
                "FR-ACC001",
                "SG-ACC01",
              ],
              data: [],
            },
          },
        ],
      },
    ],
  },
  {
    id: "isHazardOpen",
    title: "Hazard & Risk Assessment",
    stats: { changed: 3, added: 1, deleted: 4 },
    content: [
      {
        title: "Hara Tables",
        items: [createVersionSectionItems("06", "HZ-ACC03.5", "HZ-AC003.5")],
      },
      {
        title: "Operational Condition",
        items: [
          createVersionSectionItems("07", "Operational Condition", "OC-AC002"),
        ],
      },
    ],
  },
  {
    id: "isSafetyGoalsOpen",
    title: "Safety Goals",
    stats: { changed: 1, added: 3, deleted: 2 },
    content: [
      {
        title: "Safety Goals",
        items: [createVersionSectionItems("08", "SG-ACC02", "SG-ACC03")],
      },
    ],
  },
  {
    id: "isSotifOpen",
    title: "SOTIF Analysis",
    stats: { changed: 2, added: 2, deleted: 1 },
    content: [
      {
        title: "SOTIF Analysis",
        items: [createVersionSectionItems("09", "SOTIF Analysis", "SO-AC002")],
      },
    ],
  },
];
