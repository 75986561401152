import React, { useEffect } from "react";
import "../../pages/Project/Project";
import FunctionTable from "./FunctionTable";
import FunctionGraph from "./FunctionGraph";
import FunctionArchitecture from "./FunctionArchitecture";
import Descriptive from "../../Components/Descriptive/Descriptive";
import Detailed from "../../Components/Detailed";
import ViewList from "../../Components/ViewList";
import { VIEWS } from "../../constants/constants";
import EditFunctionalReq from "./EditFunctionReq/EditFunctionalReq";
import { useFunctionTable } from "../../contexts/FunctionContext";
import { useHaraTable } from "../../contexts/HaraContext";
import { useSafety } from "../../contexts/SafetyContext";
import useUrlParams from "../../hooks/useUrlParams";
import { useProjectContext } from "../../contexts/ProjectContext";

interface FunctionProps {
  view: string;
}

const RenderFunction: React.FC<FunctionProps> = ({ view }) => {
  const { getFunctionalRequirements } = useFunctionTable();
  const { getHarasData } = useHaraTable();
  const { fetchSafetyGoals, fetchSafetyRequirements } = useSafety();
  const { projectId } = useUrlParams();
  const { fetchProjectDetails } = useProjectContext();

  const renderComponent = () => {
    switch (view) {
      case VIEWS.descriptive:
        return <Descriptive />;
      case VIEWS.detailed:
        return <Detailed />;
      case VIEWS.graph:
        return <FunctionGraph />;
      case VIEWS.architecture:
        return <FunctionArchitecture />;
      case VIEWS.edit:
        return <EditFunctionalReq />;
      case VIEWS.table:
      default:
        return <FunctionTable />;
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchProjectDetails(projectId);
      getFunctionalRequirements(projectId);
      getHarasData(projectId);
      fetchSafetyGoals(projectId);
      fetchSafetyRequirements(projectId);
    }
  }, []);

  return (
    <div className="function-req-wrapper w-full">
      {view !== VIEWS.architecture && <ViewList />}
      {renderComponent()}
    </div>
  );
};

export default RenderFunction;
