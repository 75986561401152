export const LOGIN_SUCCESS_STRINGS = {
  welcomeMessage: "Welcome Back!",
  successMessage: "Login Successful",
};

export const LOGOUT_STRINGS = {
  sadMessage: "Sad to see you go...",
  confirmMessage: "Are you sure you want to logout?",
  cancelButton: "Cancel",
  okButton: "Ok",
};
