import React from "react";

interface RadioOptionProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const RadioOption: React.FC<RadioOptionProps> = ({ name, value, checked, onChange, label }) => (
  <label className="inline-flex items-center">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      className="form-radio hidden"
    />
    <div
      className={`relative w-6 h-6 rounded-full border-2 ${checked ? "bg-primary border-primary" : "bg-white border-gray-300"}`}
    >
      {checked && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-3 h-3 bg-white rounded-full"></div>
        </div>
      )}
    </div>
    <span className="ml-2 text-greyText text-base font-semibold font-['Inter'] leading-normal">
      {label}
    </span>
  </label>
);

export default RadioOption;
