import React from "react";
import TextMenuBar from "../../../Components/TextMenuBar/TextMenuBar";
import AssociationHistory from "../AssociationHistory";
import FuncReqEditCard from "./FuncReqEditCard";
import useUrlParams from "../../../hooks/useUrlParams";

const EditFunctionalReq: React.FC = () => {
  const { id } = useUrlParams()
  let label = id;

  if (id) {
    if (id.startsWith("FR-")) {
      label = `Functional Requirement ${id.slice(3)}`;
    } else if (id.startsWith("TE-")) {
      label = `Triggering Event ${id.slice(3)}`;
    } else if (id.startsWith("HZ-")) {
      label = `Hazard ${id.slice(3)}`;
    }
  } else {
    label = "Unknown ID";
  }
  return (
    <div className="px-2">
      <div className="overflow-y-auto h-[70vh]">
        <p className='text-mainBlack text-2xl font-semibold font-["Inter"] leading-loose'>
          {label}
        </p>
        <div className="relative">
          <div className="my-4 absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-10 z-0 rounded-xl" />
          <div className="relative z-10 px-6 py-9">
            <p className='absolute top-1 left-2 text-base font-semibold font-["Inter"] leading-normal text-mainBlack'>
              Description
            </p>
            <TextMenuBar placeholder="Input text" height="30vh" />
          </div>
        </div>
        <div>
          <FuncReqEditCard />
          <div className="my-4">
            <AssociationHistory />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFunctionalReq;
