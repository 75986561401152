export const ADD_NEW_CONSTANTS = {
  ADD_NEW_TITLE: "Add New",
  FOLDER_LABEL: "Folder",
  PAGE_LABEL: "Page",
};

export const ADD_NEW_FOLDER_STRINGS = {
  title: "Add a New Folder",
  folderNameLabel: "Folder Name:",
  folderNamePlaceholder: "Enter folder name",
  alertEmptyFolderName: "Folder name cannot be empty",
  cancelButton: "Cancel",
  saveButton: "Save",
};

export const ADD_NEW_PAGE_STRINGS = {
  title: "Add a New Page",
  chooseFolderLabel: "Choose a Folder:",
  selectFolderPlaceholder: "Select a Folder",
  pageNameLabel: "Page Name:",
  pageNamePlaceholder: "Input Text",
  wikiContentLabel: "Wiki content:",
  alertEmptyPageName: "Page name cannot be empty",
  alertSelectFolder: "Please select a folder",
  cancelButton: "Cancel",
  saveButton: "Save",
};

export const ADD_WIDGET_STRINGS = {
  title: "New Widget",
  buttons: [
    { label: "Folder" },
    { label: "Page" },
    { label: "History" },
    { label: "Analysis" },
    { label: "Tasks" },
    { label: "Comments" },
  ],
};
