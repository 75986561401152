import React, { useState } from "react";
import { FUNC_REQ_EDIT_VALUES } from "../../../constants/constants";
import { IoMdArrowDropdown } from "react-icons/io";
import FuncReqChoose from "../../../Components/Popups/Function/FuncReqChoose";
import Modal from "../../../Components/Modal/Modal";
import ClickableText from "./ClickableText";
import RadioOption from "../../../Components/RadioOption";
import CheckboxOption from "../../../Components/CheckboxOption";
import useUrlParams from "../../../hooks/useUrlParams";

const FuncReqEditCard = () => {
  const { id } = useUrlParams();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [isArrowRotated, setIsArrowRotated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => setIsChecked((prev) => !prev);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedOption(event.target.value);
  const handleInitiation = () => {
    setIsDetailsVisible((prev) => !prev);
    setIsArrowRotated((prev) => !prev);
  };
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  /* eslint-disable */
  const renderColumnItems = (items: string[], column: number) =>
    items.map((item, index) => (
      <div
        className="rows w-full flex flex-row my-1 space-x-20"
        key={"index_" + index}
      >
        <div className="basis-1/5 text-neutral-400 text-base font-normal font-['Inter']">
          {item.toLowerCase().includes("label") ? "Label" : item}
        </div>
        <div className="basis-1/5 text-zinc-800 text-base font-semibold font-['Inter'] leading-normal">
          {item === "ID" && <p className="text-neutral-400">{id}</p>}
          {item === "Parent" && <p className="text-neutral-400">Description</p>}
          {item === "Child Reference" && (
            <p className="text-neutral-400">Description</p>
          )}
          {item === "Label3" && <p className="text-neutral-400">Description</p>}

          {item === "Type" && (
            <ClickableText onClick={openModal}>Type 1</ClickableText>
          )}
          {item === "Status" && (
            <div className="relative flex items-center gap-1">
              <p className="text-danger">Initiation</p>
              <div className="relative">
                <IoMdArrowDropdown
                  onClick={handleInitiation}
                  className={`text-xl cursor-pointer transition-transform duration-300 ${isArrowRotated ? "rotate-180" : ""
                    }`}
                />
                {isDetailsVisible && (
                  <div className="absolute top-full left-0 w-max p-2 bg-white rounded-lg shadow border border-[#e0e0e0] flex-col justify-center items-start">
                    <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                      <div className="text-[#ff9800] text-xs font-semibold font-['Inter'] leading-tight">
                        Analyse
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {item === "Label" && (
            <ClickableText onClick={openModal}>Description</ClickableText>
          )}
          {item === "Label2" && (
            <ClickableText onClick={openModal} className="underline">
              link.here
            </ClickableText>
          )}
        </div>
      </div>
    ));

  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <FuncReqChoose onClose={closeModal} />
      </Modal>
      <div className="w-full h-auto relative mb-5">
        <div className="func-req-div w-full h-auto bg-cyan-600 bg-opacity-10 backdrop-blur-sm rounded-[10px] shadow-md px-4 py-4 pt-6 flex flex-row items-center justify-center">
          <div className="w-1/3 h-full flex flex-col pl-2">
            {renderColumnItems(FUNC_REQ_EDIT_VALUES[0], 0)}
          </div>
          <div className="w-1/3 h-full flex flex-col border-l-2 border-black/20 pl-5">
            {renderColumnItems(FUNC_REQ_EDIT_VALUES[1], 1)}
          </div>
          <div className="w-1/3 h-full flex flex-col border-l-2 border-black/20 pl-5">
            {FUNC_REQ_EDIT_VALUES[2].map((item, index) => (
              <div
                className="rows w-full flex flex-row my-1 space-x-20"
                key={item}
              >
                <div className="basis-1/5 text-neutral-400 text-base font-normal font-['Inter']">
                  {item.toLowerCase().includes("label") ? "Label" : item}
                </div>
                <div className="basis-1/5 text-zinc-800 text-base font-semibold font-['Heebo']">
                  {item === "Label" && (
                    <div className="flex space-x-4">
                      <RadioOption
                        name={`radio-group-${index}`}
                        value="Option1"
                        checked={selectedOption === "Option1"}
                        onChange={handleRadioChange}
                        label="Label"
                      />
                      <RadioOption
                        name={`radio-group-${index}`}
                        value="Option2"
                        checked={selectedOption === "Option2"}
                        onChange={handleRadioChange}
                        label="Label"
                      />
                    </div>
                  )}
                  {item === "Label2" && (
                    <div className="flex gap-2">
                      <CheckboxOption
                        checked={isChecked}
                        onChange={handleToggle}
                      />
                      <p>Description</p>
                    </div>
                  )}
                  <div className='text-primary cursor-pointer text-base font-semibold font-["Inter"] leading-normal'>
                    {item === "Label3" && (
                      <ClickableText onClick={openModal}>
                        Description
                      </ClickableText>
                    )}
                    {item === "Label4" && (
                      <ClickableText onClick={openModal}>
                        Description
                      </ClickableText>
                    )}
                    {item === "Label5" && (
                      <ClickableText onClick={openModal}>
                        Description
                      </ClickableText>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FuncReqEditCard;
