import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
} from "react";
import { getVersions, importNewVersion, presignedUrl } from "../services/Api/Projects";
import { VersionTableData, VersionComparisonTableData, VersionContextType } from "../types/VersionsTypes";

// Base data object
const baseData = {
  versionTable: {
    titles: [
      "select",
      "Version",
      "Description",
      "Comment",
      "Created by",
      "Imported",
      "more",
    ],
    rows: [],
  },
  versionComparisonTable: {
    titles: ["Data", "...", "...", "Changed", "Added", "Deleted"],
    rows: [],
  },
};

// Function to generate version table data
const generateVersionTableData = (versions: string[]): VersionTableData[] =>
  versions.map((version) => ({
    select: "select",
    version,
    description: `N/A`,
    comment: "N/A",
    createdBy: "N/A",
    imported: "N/A",
    more: "more",
  }));

// Function to generate version comparison table data
const generateVersionComparisonTableData = (
  rows: any[]
): VersionComparisonTableData[] =>
  rows.map((row) => ({
    data: row.data,
    dot1: "...",
    dot2: "...",
    changed: row.changed,
    added: row.added,
    deleted: row.deleted,
  }));

const getInitialState = (): VersionContextType => ({
  versionTableTitles: baseData.versionTable.titles,
  versionTableData: [],
  selectedIndexes: [],
  setSelectedIndexes: () => { },
  versionComparisonTableTitles: baseData.versionComparisonTable.titles,
  versionComparisonTableData: generateVersionComparisonTableData(
    baseData.versionComparisonTable.rows
  ),
  getVersion: () => { },
  importVersion: () => { }
});

const VersionsContext = createContext<VersionContextType>(getInitialState());

export const VersionsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(
    getInitialState().selectedIndexes
  );
  const [versionTableData, setVersionTableData] = useState<VersionTableData[]>(
    getInitialState().versionTableData
  );
  // get versions api call
  const getVersion = async (projectId: string) => {
    try {
      const response = await getVersions(projectId);
      if (response && Array.isArray(response.versions)) {
        const newVersionTableData = generateVersionTableData(response.versions);
        setVersionTableData(newVersionTableData);
      }
    } catch (error) {
      console.error("Failed to fetch versions", error);
    }
  };

  // import new version api call
  const importVersion = async (
    projectDetails: any) => {
    try {
      const fileName = generateFileName()
      const preUrlResponse = await presignedUrl(fileName, projectDetails[0].s3_root_folder_name)
      const uploadResponse = await fetch(preUrlResponse.upload_url, {
        method: "PUT"
      });
      if (!uploadResponse.ok) {
        throw new Error("File upload failed");
      }
      await importNewVersion(projectDetails[0], preUrlResponse);
    } catch (error) {
      console.error("Failed to fetch versions", error);
    }
  };


  const value = useMemo(
    () => ({
      ...getInitialState(),
      selectedIndexes,
      setSelectedIndexes,
      versionTableData,
      getVersion,
      importVersion
    }),
    [selectedIndexes, versionTableData]
  );

  return (
    <VersionsContext.Provider value={value}>
      {children}
    </VersionsContext.Provider>
  );
};

export const useVersions = () => useContext(VersionsContext);

export default VersionsContext;


const generateFileName = () => {
  const now = new Date();
  const formattedDate = now.toISOString().replace(/:/g, '-');
  return `file_${formattedDate}.reqifz`;
};