export const ACCOUNT_TEXT = {
  BACK_BUTTON_TEXT: "Back",
  EDIT_ACCOUNT_TEXT: "Edit Account",
};

export const CHANGE_PASSWORD_TEXT = {
  HEADING: "Change password",
  CURRENT_PASSWORD_LABEL: "Current Password",
  NEW_PASSWORD_LABEL: "New Password",
  CONFIRM_PASSWORD_LABEL: "Confirm Password",
  CANCEL_BUTTON: "Cancel",
  SAVE_BUTTON: "Save",
  CURRENT_PASSWORD_ERROR: "Current password is incorrect",
  NEW_PASSWORD_ERROR: "Passwords do not match",
};

export const CONTACT_SUPPORT_TEXT = {
  BACK_BUTTON: "Back",
  HEADING: "Contact Support",
  DESCRIPTION: "Our team is happy to help you with any questions or problems.",
  SUPPORT_EMAIL: "contact.support@example.email",
};

export const SETTING_CARD_TEXT = {
  TITLE: "My Account",
  USERNAME_LABEL: "Username:",
  EMAIL_LABEL: "Email:",
  PASSWORD_LABEL: "Password:",
  CHANGE_PASSWORD_BUTTON: "Change Password",
  COMPANY_LABEL: "Company:",
  LOCATION_LABEL: "Location:",
};
