import React from "react";
import useUrlParams from "../../hooks/useUrlParams";
import { CONST_URLS, VIEWS } from "../../constants/constants";

interface MatchingChildData {
  [key: string]: any;
}

interface DetailsSectionProps {
  dataForCurrentChild: MatchingChildData[];
  id?: string | null;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  dataForCurrentChild,
  id,
}) => {
  const { setParam } = useUrlParams();

  // Find the matching child data by id or hazardId
  const matchingChildData = dataForCurrentChild.find(
    (child) => child.id === id || child.hazardId === id
  );

  // Key mapping for more descriptive names
  const keyMapping: { [key: string]: string } = {
    assChildComponent: "Child Component",
    assChildHeading: "Child Heading",
    assChildReqId: "Child Req ID",
    assChildRmsId: "Child RMS ID",
    assParentComponent: "Parent Component",
    assParentHeading: "Parent Heading",
    assParentReqId: "Parent Req ID",
    assParentRmsId: "Parent RMS ID",
    description: "Description",
    heading: "Heading",
    id: "ID",
    reference_id: "Reference ID",
    assSystemRmsId: "System RMS ID",
    controllability: "Controllability",
    created_by: "Created By",
    created_on: "Created On",
    exposure: "Exposure",
    modified_by: "Modified By",
    modified_on: "Modified On",
    safetyRmsId: "Safety RMS ID",
    severity: "Severity",
    asil_of_hazard: "ASIL of Hazard",
    assHaraComponentName: "HARA Component Name",
    assHaraRmsId: "HARA RMS ID",
    ItemRmsId: "Item RMS ID",
    SafetyGoalRmsId: "Safety Goal RMS ID",
  };

  // Convert matchingChildData object into key-value pairs and map them
  const entries = matchingChildData ? Object.entries(matchingChildData) : [];

  const handleClick = (key: string) => {
    if (key === "id") {
      setParam(CONST_URLS.view, VIEWS.detailed);
    }
  };

  return (
    <div>
      {matchingChildData ? (
        entries.map(([key, value]) => {
          // Use the key mapping to get a display name
          const displayName = keyMapping[key] || key;

          // Determine the text color class based on the key or value
          let textColorClass = 'text-base font-semibold font-["Inter"]';

          if (value === "N/A") {
            textColorClass = "text-greyText";
          } else if (
            key.includes("Id") ||
            key.includes("ID") ||
            key.includes("id")
          ) {
            textColorClass = "text-primary";
          }

          return (
            <div key={key} className="flex justify-between my-1">
              <span className="text-neutral-400 text-base font-normal w-1/2 break-words">
                {displayName}
              </span>
              <span className="w-1/2 break-words">
                <div
                  className={textColorClass}
                  onClick={() => handleClick(key)}
                  style={key === "id" ? { cursor: "pointer" } : {}}
                >
                  {value !== undefined && value !== null
                    ? value.toString()
                    : "N/A"}
                </div>
              </span>
            </div>
          );
        })
      ) : (
        <div>No data found</div>
      )}
    </div>
  );
};

export default DetailsSection;
