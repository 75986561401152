import React, { useRef, useState, useMemo } from "react";
import Button from "../Button";
import InputField from "../Input";
import Dropdown from "../Dropdown";
import { MdOutlineInsertDriveFile } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { ImportIcon } from "../../assets/Svg/Svg";
import ImportSuccessful from "../Popups/Import/ImportSuccessful";
import ImportFailed from "../Popups/Import/ImportFailed";
import Modal from "../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../contexts/ProjectContext";
import { FaAngleLeft } from "react-icons/fa6";
import Spinner from "../Spinner";
import { useAuth } from "../../hooks/useAuth";
import { paths } from "../../constants/paths";

export default function AddNewProjectForm({
  hideAddProject,
}: Readonly<{
  hideAddProject: () => void;
}>) {
  const { state } = useAuth();
  const { user } = state;
  const navigate = useNavigate();
  const { addProject } = useProjectContext();

  const [importSuccessModalVisible, setImportSuccessModalVisible] =
    useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | null>(null);
  let [buttonDisabled] = useState(true);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [template1, setTemplate1] = useState<string | null>(null);
  const [template2, setTemplate2] = useState<string | null>(null);
  const [importErrorModalVisible, setImportErrorModalVisible] = useState(false);
  const [submitSuccessModalVisible, setSubmitSuccessModalVisible] =
    useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];

    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension !== "reqifz") {
        setImportErrorModalVisible(true);
        setTimeout(() => setImportErrorModalVisible(false), 2000);
        setFileInputKey(Date.now());
        setFileUploaded(false);
        setFileName(null);
        setFile(null);
      } else {
        setFile(file);
        setFileName(file.name);
        setFileInputKey(Date.now());
        setFileUploaded(true);
        setImportSuccessModalVisible(true);
        setTimeout(() => setImportSuccessModalVisible(false), 2000);
      }
    } else {
      setFile(null);
      setFileName(null);
      setFileUploaded(false);
    }
  };

  const handleImportNew = () => {
    setFileName("");
    setPopupVisible(false);
    setTimeout(() => {
      fileInputRef.current?.click();
    }, 0);
  };

  const onSubmit = async () => {
    if (!file) return; // Ensure file is present

    setLoading(true);

    try {
      const newProject = {
        file: file,
        project_name: name,
        description: description || "",
        s3_root_folder_name: "",
        creation_date: new Date().toISOString(),
        source_rms: template1 || "",
        template_name: template2 || "",
        version: "",
        version_creation_date: new Date().toISOString(),
      };

      // Add the project and get the newly added project's ID
      const projectId = await addProject(newProject, user);
      setSubmitSuccessModalVisible(true);
      setTimeout(() => {
        setSubmitSuccessModalVisible(false);
        navigate(paths.project.projectWikiPage(projectId));
      }, 2000);
    } catch (error) {
      // Handle any errors here
      console.error("Error adding project:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleDotsClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setPopupVisible(!popupVisible);
  };

  const handleRemove = () => {
    setFileName(null);
    setFileUploaded(false);
    setPopupVisible(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };
  buttonDisabled = useMemo(() => {
    return !(name && template1 && template2 && description && fileUploaded);
  }, [name, template1, template2, description, fileUploaded]);

  return (
    <div className="add-project-wrapper">
      {loading && <Spinner />}
      <div className="add-topbar w-full h-14 border-b border-black border-opacity-20 px-10 py-4">
        <Button className="w-auto flex items-center" onClick={hideAddProject}>
          <div className="">{/* <ArrowSmall /> */}</div>
          <p className="text-primary text-base font-semibold font-['Inter'] flex flex-row items-center leading-normal gap-1">
            <FaAngleLeft />
            All Projects
          </p>
        </Button>
      </div>

      <div className="add-project-main bg-login-bg bg-bottom bg-no-repeat bg-cover p-24 justify-center items-center h-screen">
        <h3 className="focus:ring-0 focus:outline-none px-3 py-2 text-black text-opacity-80 border-b-2 border-primary border-opacity-20 text-2xl font-semibold font-['Inter'] leading-loose pb-6">
          Create a New Project
        </h3>
        <form className="inputs-textarea flex gap-20 pt-6">
          <div className="inputs w-[640px] relative">
            <label
              className={`bg-transparent w-max flex items-center gap-2 border-2 rounded-3xl py-2 px-4 mb-4 cursor-pointer`}
              htmlFor={fileName ? undefined : "fileInput"}
              onClick={(e) => {
                if (fileName) e.preventDefault();
              }}
            >
              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                key={fileInputKey}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {fileName ? (
                <>
                  <MdOutlineInsertDriveFile
                    onClick={(e) => e.stopPropagation()}
                  />
                  <span
                    className="text-base font-['Inter'] leading-normal"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {fileName}
                  </span>
                  <HiOutlineDotsVertical
                    onClick={handleDotsClick}
                    className="cursor-pointer"
                  />
                </>
              ) : (
                <>
                  <span className="text-primary text-base font-semibold font-['Inter'] leading-normal">
                    Import ReqIfz file
                  </span>
                  <ImportIcon />
                </>
              )}
            </label>

            {popupVisible && (
              <div className="z-20 left-[100px] absolute top-7 h-16 bg-white rounded-lg shadow border border-[#e0e0e0] flex-col justify-center items-start inline-flex mt-2">
                <button
                  className="w-full text-left hover:bg-gray-200 pl-1 px-2"
                  onClick={handleImportNew}
                >
                  Import New
                </button>
                <button
                  className="w-full text-left text-red-500 border-t-2 px-2 border-gray-100 pl-1 hover:bg-gray-200"
                  onClick={handleRemove}
                >
                  Remove
                </button>
              </div>
            )}

            <InputField
              label="Name"
              placeholder="Project Name"
              inputClassName={`focus:ring-0 focus:outline-none px-3 py-2 ${
                (template1 || template2 || description) && !name
                  ? "border-red-500"
                  : ""
              }`}
              labelClassName={`text-primary text-base font-medium font-['Heebo'] leading-normal ${
                (template1 || template2 || description) && !name
                  ? "text-red-500"
                  : ""
              }`}
              required
              onChange={handleChange}
            />

            <Dropdown
              className="w-full rounded-lg my-4"
              options={["codebeamer"]}
              onSelect={(selectedOption) => setTemplate1(selectedOption)}
              label="Industry"
              error={(template2 || description) && !template1}
            />

            <Dropdown
              className="w-full rounded-lg"
              options={["iso_26262"]}
              onSelect={(selectedOption) => setTemplate2(selectedOption)}
              label="Template"
              error={description && !template1}
            />
          </div>
          <div className="flex justify-end flex-col w-[640px]">
            <div className="textarea">
              <div className="flex flex-col h-full">
                <label
                  htmlFor="desc-1"
                  className="text-primary text-base font-medium font-['Heebo'] leading-normal"
                >
                  Description
                </label>
                <textarea
                  placeholder="Input Text"
                  onChange={(e) => {
                    setDescription(e?.target?.value);
                  }}
                  name="desc"
                  id="desc-1"
                  className="focus:ring-0 focus:outline-none rounded-lg border border-stone-300 py-2 px-3 h-full resize-none min-h-[273px]"
                ></textarea>
              </div>
            </div>
            <div className="flex flex-end self-end gap-5 pt-[48px]">
              <Button className="text-white text-center w-full rounded-3xl py-2 px-4 mb-4">
                <span
                  className="text-primary text-base font-semibold leading-normal"
                  onClick={hideAddProject}
                >
                  Cancel
                </span>
              </Button>

              <Button
                className={`text-white text-center w-full rounded-3xl ${
                  buttonDisabled ? "bg-[#EEEEEE]" : "bg-primary"
                } py-2 px-4 mb-4`}
                disabled={buttonDisabled}
                onClick={onSubmit}
              >
                <span className="text-white text-base font-semibold font-['Inter'] leading-normal">
                  Submit
                </span>
              </Button>
            </div>
          </div>
        </form>
      </div>

      {importSuccessModalVisible && (
        <Modal
          show={importSuccessModalVisible}
          onClose={() => setImportSuccessModalVisible(false)}
          showCloseButton={false}
        >
          <ImportSuccessful redirectText="" />
        </Modal>
      )}

      {submitSuccessModalVisible && (
        <Modal
          show={submitSuccessModalVisible}
          onClose={() => setSubmitSuccessModalVisible(false)}
          showCloseButton={false}
        >
          <ImportSuccessful redirectText="Redirecting to Wiki Page..." />
        </Modal>
      )}

      {importErrorModalVisible && (
        <Modal
          show={importErrorModalVisible}
          onClose={() => setImportErrorModalVisible(false)}
          showCloseButton={false}
        >
          <ImportFailed />
        </Modal>
      )}
    </div>
  );
}
