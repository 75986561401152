export const paths = {
  home: "/",
  login: "/login",
  support: "/support",
  account: "/account",
  profile: "/profile",
  project: {
    project: "/project",
    projectWiki: "/project-wiki",
    projectWikiId: (projectId: string) =>
      `/project-wiki?projectId=${projectId}`,
    projectWikiPage: (projectId: string) =>
      `/project-wiki?projectId=${projectId}&page=Wiki`,
  },

  resetPassword: {
    resetPassword: "/reset-password",
    step1: "/reset-password?step=1",
    step2: "/reset-password?step=2",
    step3: "/reset-password?step=3",
  },
};
