import React, { ReactNode } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string | null;
  showError?: boolean | null;
  containerClassName?: string; // Use this prop to control container CSS dynamically
  labelClassName?: string; // Use this prop to control container CSS dynamically
  inputClassName?: string; // Use this prop to control container CSS dynamically
  link?: {
    href: string;
    children: ReactNode;
  };
}

const Input: React.FC<InputProps> = ({
  label,
  error,
  showError = true,
  containerClassName,
  labelClassName,
  inputClassName,
  link,
  type,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`flex flex-col gap-1 ${containerClassName}`}>
      <div className="flex justify-between flex-row">
        {label && (
          <label
            className={`text-base font-medium leading-6 ${labelClassName}`}
            htmlFor={rest.id || rest.name}
          >
            {label}
          </label>
        )}
        {link && (
          <Link
            to={link.href}
            className={`underline text-primary font-medium text-base font-['Heebo'] leading-normal`}
          >
            {link.children}
          </Link>
        )}
      </div>
      <div
        className={` ${type === "password"
          ? " flex justify-between items-center h-14 relative "
          : ""
          }`}
      >
        {type === "password" &&
          (showPassword ? (
            <svg
              className="absolute right-2 cursor-pointer"
              onClick={() => setShowPassword(false)}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6.75996C15.79 6.75996 19.17 8.88996 20.82 12.26C20.23 13.48 19.4 14.53 18.41 15.38L19.82 16.79C21.21 15.56 22.31 14.02 23 12.26C21.27 7.86996 17 4.75996 12 4.75996C10.73 4.75996 9.51 4.95996 8.36 5.32996L10.01 6.97996C10.66 6.84996 11.32 6.75996 12 6.75996ZM10.93 7.89996L13 9.96996C13.57 10.22 14.03 10.68 14.28 11.25L16.35 13.32C16.43 12.98 16.49 12.62 16.49 12.25C16.5 9.76996 14.48 7.75996 12 7.75996C11.63 7.75996 11.28 7.80996 10.93 7.89996ZM2.01 4.62996L4.69 7.30996C3.06 8.58996 1.77 10.29 1 12.26C2.73 16.65 7 19.76 12 19.76C13.52 19.76 14.98 19.47 16.32 18.94L19.74 22.36L21.15 20.95L3.42 3.20996L2.01 4.62996ZM9.51 12.13L12.12 14.74C12.08 14.75 12.04 14.76 12 14.76C10.62 14.76 9.5 13.64 9.5 12.26C9.5 12.21 9.51 12.18 9.51 12.13ZM6.11 8.72996L7.86 10.48C7.63 11.03 7.5 11.63 7.5 12.26C7.5 14.74 9.52 16.76 12 16.76C12.63 16.76 13.23 16.63 13.77 16.4L14.75 17.38C13.87 17.62 12.95 17.76 12 17.76C8.21 17.76 4.83 15.63 3.18 12.26C3.88 10.83 4.9 9.64996 6.11 8.72996Z"
                fill="#757575"
              />
            </svg>
          ) : (
            <svg
              className="absolute right-2 cursor-pointer"
              onClick={() => setShowPassword(true)}
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g clipPath="url(#clip0_205_4924)">
                <path
                  d="M12.2559 6C16.0459 6 19.4259 8.13 21.0759 11.5C19.4259 14.87 16.0459 17 12.2559 17C8.46586 17 5.08586 14.87 3.43586 11.5C5.08586 8.13 8.46586 6 12.2559 6ZM12.2559 4C7.25586 4 2.98586 7.11 1.25586 11.5C2.98586 15.89 7.25586 19 12.2559 19C17.2559 19 21.5259 15.89 23.2559 11.5C21.5259 7.11 17.2559 4 12.2559 4ZM12.2559 9C13.6359 9 14.7559 10.12 14.7559 11.5C14.7559 12.88 13.6359 14 12.2559 14C10.8759 14 9.75586 12.88 9.75586 11.5C9.75586 10.12 10.8759 9 12.2559 9ZM12.2559 7C9.77586 7 7.75586 9.02 7.75586 11.5C7.75586 13.98 9.77586 16 12.2559 16C14.7359 16 16.7559 13.98 16.7559 11.5C16.7559 9.02 14.7359 7 12.2559 7Z"
                  fill="#757575"
                />
              </g>
              <defs>
                <clipPath id="clip0_205_4924">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.255859)"
                  />
                </clipPath>
              </defs>
            </svg>
          ))}
        <input
          className={`border rounded-lg w-full ${inputClassName} ${error && error.length > 0
            ? "border-red-500"
            : "border-borderSecondaryGrey"
            }`}
          type={showPassword ? "text" : type}
          {...rest}
        />
      </div>

      {/* Error message with reserved space */}
      {error && error.length > 0 && showError && (
        <p className="text-[#e31b0c] text-base font-normal font-['Heebo'] leading-normal">{error}</p>
      )}
    </div>
  );
};

export default Input;


