import React, { useState } from "react";
import Modal from "./Modal/Modal";
import AddVersion from "./Popups/Version/AddVersion";

export default function ImportProjectBtn() {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <AddVersion onClose={closeModal} />
      </Modal>
      <div className="group fixed left-10 bottom-5 flex flex-col">
        <div className="w-[249px] h-[72px] ml-8 px-4 py-6 hidden group-hover:inline-flex bg-white rounded-2xl shadow flex-col justify-center items-center gap-4  ">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="flex-col justify-center items-center gap-12 flex">
              <div className="text-neutral-500 text-base font-normal font-['Inter'] leading-normal">
                Import a new project version
              </div>
            </div>
          </div>
        </div>
        {/* on click open add new version popup */}
        <button
          onClick={openModal}
          className="w-14 h-14 p-4 cursor-pointer bg-cyan-600 rounded-[32px] shadow justify-center items-center gap-1 inline-flex "
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_14_23184)">
              <path
                d="M19.7969 12.0771V19.0771H5.79688V12.0771H3.79688V19.0771C3.79688 20.1771 4.69687 21.0771 5.79688 21.0771H19.7969C20.8969 21.0771 21.7969 20.1771 21.7969 19.0771V12.0771H19.7969ZM13.7969 12.7471L16.3869 10.1671L17.7969 11.5771L12.7969 16.5771L7.79688 11.5771L9.20687 10.1671L11.7969 12.7471V3.07715H13.7969V12.7471Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_14_23184">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.796875 0.0771484)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </>
  );
}
