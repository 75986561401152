import React, { useEffect, useRef } from "react";
import "./Project.css";
import LoginSuccess from "../../Components/Popups/LoginSuccess";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectBody from "../../Components/ProjectBody/ProjectBody";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useAuth } from "../../hooks/useAuth";
import HeaderLayout from "../../Components/HeaderLayout";

function Project() {
  const { state } = useAuth();
  const { fetchProjects } = useProjectContext();
  const { user } = state;

  const location = useLocation();
  const navigate = useNavigate();

  const notificationShown = useRef(false);

  const showNotification = () => {
    if (!notificationShown.current) {
      toast(<LoginSuccess />, {
        position: "top-right",
        autoClose: 3000,
        bodyStyle: { padding: "0px" },
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        closeButton: false,
        draggable: false,
      });
      notificationShown.current = true;
    }
  };

  useEffect(() => {
    if (location?.state?.fromLogin) {
      showNotification();
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate, user?.id]);

  useEffect(() => {
    if (user?.id) {
      fetchProjects(user.id);
    }
  }, [user?.id]);

  return (
    <HeaderLayout>
      <div className="profile-main">
        <ProjectBody />
      </div>
    </HeaderLayout>
  );
}

export default Project;
