import React from "react";
import { MdOutlineImportExport } from "react-icons/md";
import VersionsTable from "./VersionsTable";
import { useVersions } from "../../contexts/VersionsContext";
import useUrlParams from "../../hooks/useUrlParams";

function TableComparison() {
  const { versionComparisonTableTitles, versionComparisonTableData } =
    useVersions();
  const { version1, version2 } = useUrlParams()
  return (
    <div className="relative mt-6 p-9 bg-white/20 rounded-[10px] shadow backdrop-blur-sm">
      <div className='absolute -top-6 left-0 flex items-center text-2xl font-semibold leading-loose font-["Inter"] gap-2'>
        <p>{version1}</p>
        <MdOutlineImportExport className="rotate-90" />
        <p>{version2}</p>
      </div>
      <div>
        <VersionsTable
          tableTitle={versionComparisonTableTitles}
          tableData={versionComparisonTableData}
        />
      </div>
    </div>
  );
}

export default TableComparison;
