import React from "react";
import Input from "../../Components/Input";
import {
  NEW_PASSWORD_TITLE,
  PASSWORD_LABEL,
  REPEAT_PASSWORD_LABEL
} from "./constants";
import { StepThreeProps } from "./types";

const StepThree: React.FC<StepThreeProps> = ({ password, setPassword, repeatPassword, setRepeatPassword, error, handlePasswordReset }) => {
  return (
    <div className="otp-card w-[498px] bg-white shadow-2xl py-16 px-12">
      <p className="text-xl font-semibold font-['Inter'] leading-7 text-mainBlack text-center">
        {NEW_PASSWORD_TITLE}
      </p>
      <div className="pass-input flex flex-col pb-6 pt-12 gap-1">
        <div className="flex justify-between items-center relative">
          <Input
            label={PASSWORD_LABEL}
            labelClassName="text-base font-medium font-['Heebo'] leading-normal"
            name="password"
            error={error}
            type="password"
            inputClassName={`py-4 px-3 focus:ring-0 focus:outline-none border-borderSecondaryGrey focus:border-transparent ${!error ? 'focus:ring-1 focus:ring-[#00a3b5]' : 'focus:ring-1 focus:ring-red-500'}`}
            value={password}
            showError={false}
            onChange={(e) => setPassword(e.target.value)}
            containerClassName="pass-input flex flex-col w-full"
          />
        </div>
      </div>
      <div className="rep-pass-input flex flex-col pb-12 gap-1">
        <div className="flex justify-between items-center relative w-full">
          <Input
            label={REPEAT_PASSWORD_LABEL}
            labelClassName="text-base font-medium font-['Heebo'] leading-normal"
            name="repeat-password"
            type="password"
            inputClassName={`py-4 px-3 focus:ring-0 focus:outline-none border-borderSecondaryGrey focus:border-transparent ${!error ? 'focus:ring-1 focus:ring-[#00a3b5]' : 'focus:ring-1 focus:ring-red-500'}`}
            showError={false}
            error={error}
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            containerClassName="pass-input flex flex-col w-full"
          />
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>
      <button
        className={`text-base font-semibold font-['Inter'] leading-normal text-center w-full rounded-lg py-4 ${!password || !repeatPassword || password !== repeatPassword ? 'bg-[#eeeeee] text-borderSecondaryGrey cursor-not-allowed' : 'bg-primary text-white'}`}
        onClick={handlePasswordReset}
        disabled={!password || !repeatPassword || password !== repeatPassword}
      >
        Save
      </button>
    </div>
  );
};

export default StepThree;
