import React from "react";

interface ClickableTextProps {
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

const ClickableText: React.FC<ClickableTextProps> = ({ onClick, className = "", children }) => (
  <button onClick={onClick} className={`text-primary cursor-pointer ${className}`}>
    {children}
  </button>
);

export default ClickableText;
