import { getStoredAuth, removeStoredAuth } from "../utils/localStorage";

interface ApiResponse {
  message: string | number;
  [key: string]: any;
}

interface ApiParams {
  [key: string]: string | number | boolean;
}

export default class ApiManager {
  private static singleton: ApiManager | null = null;
  private token?: string;
  private userId?: string;
  private subscription?: any;
  private generalSettings?: any;

  static readonly POST = "POST";
  static readonly GET = "GET";
  static readonly DELETE = "DELETE";
  static readonly PUT = "PUT";
  static readonly PATCH = "PATCH";

  // Returns an instance of this class
  static getInstance(): ApiManager {
    if (ApiManager.singleton == null) {
      ApiManager.singleton = new ApiManager();
    }
    return ApiManager.singleton;
  }

  // Use this function to make POST calls to the server
  post = (endpoint: string, body: any): Promise<ApiResponse> => {
    const endpointUrl = this.generateEndpointUrl(endpoint);
    return this._callServer(endpointUrl, body, ApiManager.POST);
  };

  // Use this function to make GET calls to the server
  get = (endpoint: string, params: ApiParams): Promise<any> => {
    const endpointUrl = `${this.generateEndpointUrl(
      endpoint
    )}${this.convertToGetParams(params)}`;
    return this._callServer(endpointUrl, null, ApiManager.GET);
  };

  // Use this function to make PUT calls to the server
  put = (endpoint: string, body: any): Promise<ApiResponse> => {
    const endpointUrl = this.generateEndpointUrl(endpoint);
    return this._callServer(endpointUrl, body, ApiManager.PUT);
  };

  patch = (endpoint: string, body: any): Promise<ApiResponse> => {
    const endpointUrl = this.generateEndpointUrl(endpoint);
    return this._callServer(endpointUrl, body, ApiManager.PATCH);
  };

  // Use this function to make DELETE calls to the server
  delete = (endpoint: string, body?: any): Promise<ApiResponse> => {
    const endpointUrl = this.generateEndpointUrl(endpoint);
    return this._callServer(endpointUrl, body, ApiManager.DELETE);
  };

  // Generate the URL for the specific endpoint
  private generateEndpointUrl = (endpoint: string): string => {
    return `${
      process.env.REACT_APP_API_BASE_URL ??
      "https://aai-sgaf-dev.automotive-ai.com/sgaf/v1"
    }${endpoint}`;
  };

  // Convert object to GET params
  private convertToGetParams = (params: ApiParams): string => {
    const getParams = Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key].toString()
          )}`
      )
      .join("&");
    return `?${getParams}`;
  };

  // Make the actual call to the server here. This should not be called from outside this class
  private async _callServer(
    endpoint: string,
    body: any = null,
    method: string = ApiManager.GET
  ): Promise<ApiResponse> {
    // Fetch the token and userId asynchronously
    const token = this.token ?? (await getStoredAuth())?.token;
    const headers: HeadersInit = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
      ...(this.userId && { "user-id": this.userId }),
    };

    try {
      const response = await fetch(endpoint, {
        method: method,
        headers: headers,
        mode: "cors",
        body: body !== null ? JSON.stringify(body) : null,
      });

      const json: ApiResponse = await response.json();
      if (json.detail === "Signature has expired.") {
        removeStoredAuth();
      }
      if (
        ["1003", "1004", 5014, 5067].includes(json.message) ||
        json.message === "Unauthenticated." ||
        json.message === "Unauthenticated"
      ) {
        throw new Error(String(json.message));
      }

      return json;
    } catch (error) {
      // Ensure the error is an instance of Error
      if (!(error instanceof Error)) {
        error = new Error("An unknown error occurred");
      }

      throw error;
    }
  }

  // Sets the token in the session
  setToken = (token: string): void => {
    this.token = token;
  };

  // Returns the token in the session
  getToken = (): string | undefined => {
    return this.token;
  };

  // Sets the user-id in the session
  setUserId = (userId: string): void => {
    this.userId = userId;
  };

  // Returns the user-id in the session
  getUserId = (): string | undefined => {
    return this.userId;
  };

  setSubscription = (subscription: any): void => {
    this.subscription = subscription;
  };

  getSubscription = (): any => {
    return this.subscription;
  };

  setGeneralSettings = (generalSettings: any): void => {
    this.generalSettings = generalSettings;
  };

  getGeneralSettings = (): any => {
    return this.generalSettings;
  };
}
