import React, { useMemo } from "react";
import PlaceholderImg from "../../assets/Images/placeholder.png";
import ImportProjectBtn from "../../Components/ImportProjectBtn";
import Spinner from "../../Components/Spinner";

interface WikiContentProps {
  loadingProjectDetails: boolean;
  projectTitle: string;
  projectDescription: string; // This can be either a JSON string or plain text
}

const WikiContent: React.FC<WikiContentProps> = ({
  loadingProjectDetails,
  projectTitle,
  projectDescription,
}) => {
  const parsedContent = useMemo(() => {
    try {
      return JSON.parse(projectDescription);
    } catch (e) {
      // If parsing fails, treat it as plain text
      return {
        blocks: [
          {
            key: "1",
            text: projectDescription,
            type: "unstyled",
            inlineStyleRanges: [],
          },
        ],
      };
    }
  }, [projectDescription]);

  // Function to render inline styles using Tailwind CSS
  const renderTextWithStyles = (text: string, inlineStyleRanges: any[]) => {
    const segments: JSX.Element[] = [];
    let lastIndex = 0;

    inlineStyleRanges.forEach(({ offset, length, style }) => {
      if (lastIndex < offset) {
        segments.push(
          <span key={`${lastIndex}-${offset}`}>
            {text.slice(lastIndex, offset)}
          </span>
        );
      }

      const styleClasses = style
        .split(" ")
        .map((styleName: string) => {
          switch (styleName) {
            case "BOLD":
              return "font-bold";
            case "ITALIC":
              return "italic";
            case "UNDERLINE":
              return "underline";
            default:
              return "";
          }
        })
        .join(" ");

      segments.push(
        <span key={`${offset}-${length}`} className={styleClasses}>
          {text.slice(offset, offset + length)}
        </span>
      );

      lastIndex = offset + length;
    });

    if (lastIndex < text.length) {
      segments.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return <>{segments}</>;
  };

  const renderBlock = (block: any) => {
    switch (block.type) {
      case "unstyled":
        return (
          <p key={block.key}>
            {renderTextWithStyles(block.text, block.inlineStyleRanges)}
          </p>
        );
      case "unordered-list-item":
        return (
          <ul key={block.key} className="list-disc list-inside ml-4">
            <li>{renderTextWithStyles(block.text, block.inlineStyleRanges)}</li>
          </ul>
        );
      case "ordered-list-item":
        return (
          <ol key={block.key} className="list-decimal list-inside ml-4">
            <li>{renderTextWithStyles(block.text, block.inlineStyleRanges)}</li>
          </ol>
        );
      case "header-one":
        return (
          <h1 key={block.key} className="text-4xl font-bold">
            {renderTextWithStyles(block.text, block.inlineStyleRanges)}
          </h1>
        );
      case "header-two":
        return (
          <h2 key={block.key} className="text-3xl font-bold">
            {renderTextWithStyles(block.text, block.inlineStyleRanges)}
          </h2>
        );
      case "header-three":
        return (
          <h3 key={block.key} className="text-2xl font-bold">
            {renderTextWithStyles(block.text, block.inlineStyleRanges)}
          </h3>
        );
      case "blockquote":
        return (
          <blockquote
            key={block.key}
            className="border-l-4 border-gray-400 pl-4 italic"
          >
            {renderTextWithStyles(block.text, block.inlineStyleRanges)}
          </blockquote>
        );
      default:
        return null;
    }
  };

  return (
    <div className="profile-main">
      {loadingProjectDetails && <Spinner />}
      <div className="wiki-body-wrapper">
        <div className="ml-4 mt-3">
          <div className="text-zinc-800 text-2xl font-semibold font-['Inter']">
            Welcome to {projectTitle}
          </div>
          <div className="relative text-secondaryGrey600 text-base font-semibold font-['Inter'] leading-normal">
            <p className="absolute top-4 left-2">Description</p>
          </div>
          <div className="h-[766px] bg-cyan-600/10 rounded-[10px] p-4 mt-8 overflow-auto">
            {parsedContent.blocks && parsedContent.blocks.length > 0 ? (
              <div>{parsedContent.blocks.map(renderBlock)}</div>
            ) : (
              <p className="text-zinc-800 text-base font-normal leading-normal">
                No content available
              </p>
            )}
            <div className="flex gap-5 pt-4 pb-4">
              <img
                alt="temp"
                className="w-[49%] rounded-3xl"
                src={PlaceholderImg}
              />
              <img
                alt="temp"
                className="w-[49%] rounded-3xl"
                src={PlaceholderImg}
              />
            </div>
          </div>
        </div>
      </div>
      <ImportProjectBtn />
    </div>
  );
};

export default WikiContent;
