import React, { createContext, useContext, ReactNode, useMemo, useState, useEffect } from "react";
import { HARA_CHILDS, VIEWS } from "../constants/constants";
import useUrlParams from "../hooks/useUrlParams";
import { getHara, getHaras } from "../services/Api/Hara";
import { HaraContextType, Haras, HaraTableData, Hara } from "../types/HaraContextTypes";

const initialState: Omit<HaraContextType, "updateHazardParams" | "setHaraTableParams" | "setOperConditionsParams" | "getHarasData" | "getHaraData"> = {
  haras: [],
  hara: [],
  haraTableTitle: [
    "ID", "Heading", "Reference ID", "Description", "ASIL", "Severity", "Exposure", "Created By", "Created On",
    "Modified By", "Modified On", 'Controllability', 'Associated Child ID', 'Associated Parent ID',
    "System Element ID", 'Safety Goals '
  ],
  haraTableData: [],
  operConditionalTitle: [
    "Operational Condition ID", "Time of the day", "Weather Condition", "Road Condition", "Type of Road", "Road Marking /Shape", "Location", "Intersection", "Objects", "Events"
  ],
  operConditionData: [
    {
      id: "OC-001", time: "Evening", weather: "Rain", roadCondition: "All", roadType: "Highway", roadShape: "Lane Marking",
      location: "No Location", intersection: "No Intersection", objects: "Vehicle", events: "No Events", description: "Description"
    },
    {
      id: "OC-002", time: "Evening", weather: "Rain", roadCondition: "All", roadType: "Highway", roadShape: "Lane Marking",
      location: "No Location", intersection: "No Intersection", objects: "Vehicle", events: "No Events", description: "Description"
    }
  ],
};

const HaraContext = createContext<HaraContextType | undefined>(undefined);

export const HaraProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { setParams } = useUrlParams();
  const [hara, setHara] = useState<Hara[]>([]);
  const [haras, setHaras] = useState<Haras[]>([]);
  const [haraTableTitle] = useState(initialState.haraTableTitle);
  const [operConditionalTitle] = useState(initialState.operConditionalTitle);
  const [operConditionData] = useState(initialState.operConditionData);

  const getHarasTableData = (): HaraTableData[] => {
    return haras.map(hara => ({
      id: hara.id || 'N/A',
      heading: hara.heading || 'N/A',
      reference_id: 'HZ-' + hara.reference_id || 'N/A',
      description: hara.description || 'N/A',
      asil_of_hazard: hara.asil_of_hazard || 'N/A',
      severity: hara.severity || 'N/A',
      exposure: hara.exposure || 'N/A',
      created_by: hara.created_by || 'N/A',
      created_on: hara.created_on || 'N/A',
      modified_by: hara.modified_by || 'N/A',
      modified_on: hara.modified_on || 'N/A',
      controllability: hara.controllability || 'N/A',
      assChildRmsId: hara.associations?.children?.[0]?.reference_rms_id || "N/A",
      assParentRmsId: hara.associations?.parent?.[0]?.reference_rms_id || "N/A",
      assSystemRmsId: hara.associations?.["System Element"]?.map(se => se.reference_rms_id).join(", ") || "N/A",
      safetyRmsId: hara.associations?.["Safety Goal(s)"]?.map(sg => sg.reference_rms_id).join(", ") || "N/A",
    }));
  };

  const [haraTableData, setHaraTableData] = useState<HaraTableData[]>([]);
  useEffect(() => {
    if (haras.length > 0) {
      setHaraTableData(getHarasTableData());
    }
  }, [haras]);

  const setHaraTableParams = (itemName: string) => {
    setParams({ view: VIEWS.table, child: itemName, id: haraTableData[0]?.id || "N/A" });
  };

  const setOperConditionsParams = (itemName: string) => {
    setParams({ view: VIEWS.table, child: itemName, id: operConditionData[0]?.id || "N/A" });
  };

  const updateHazardParams = (itemName: string) => {
    switch (itemName) {
      case HARA_CHILDS.haraTables:
        setHaraTableParams(itemName);
        break;
      case HARA_CHILDS.operConditions:
        setOperConditionsParams(itemName);
        break;
      default:
        break;
    }
  };

  // api call to fetch all hara data
  const getHarasData = async (projectId: string) => {
    try {
      const responseData = await getHaras(projectId);
      if (Array.isArray(responseData)) {
        setHaras(responseData);
        setHaraTableData(getHarasTableData());
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch HARA data", error);
    }
  };

  // api call to fetch a detailed hara data
  const getHaraData = async (reqId: string) => {
    try {
      const responseData = await getHara(reqId);
      // Transform responseData to fit the Hara type
      if (responseData) {
        const transformedHara: Hara = {
          attributes_schema: responseData.attributes_schema || [],
          associations_template: responseData.associations_template || {
            children: [],
            parent: [],
            "System Element": [],
            "Safety Goal(s)": [],
          },
          heading: responseData.heading || '',
          reference_rms_id: responseData.reference_rms_id || '',
          project_version: responseData.project_version || '',
          attributes_and_value: responseData.attributes_and_value || {
            status: '',
            text: '',
            risk: 0,
            "risk after reduction": 0,
            "asil check": '',
            "likelihood after reduction": 0,
            attachments: null,
            "fault tolerance time:": null,
            type: null,
            "planned risk reduction": null,
            "asil of safety goal": null,
            harm: null,
            "severity after reduction": 0,
            "assigned to": '',
            "safe state": null,
            likelihood: 0,
            "risk severity": 0,
            h: null,
          },
          associations: responseData.associations || {
            children: [],
            parent: [],
            "System Element": [],
            "Safety Goal(s)": [],
          }
        };

        setHara([transformedHara]);
      } else {
        console.warn("Received unexpected data format", responseData);
      }
    } catch (error) {
      console.error("Failed to fetch HARA data", error);
    }
  };

  const value = useMemo(() => ({
    hara,
    haras, haraTableTitle, haraTableData, operConditionalTitle, operConditionData, setHaraTableParams,
    setOperConditionsParams, updateHazardParams, getHarasData, getHaraData
  }), [haraTableTitle, haraTableData, operConditionalTitle, operConditionData, haras, hara]);

  return <HaraContext.Provider value={value}>{children}</HaraContext.Provider>;
};

export const useHaraTable = () => {
  const context = useContext(HaraContext);
  if (!context) throw new Error("useHaraTable must be used within a HaraProvider");
  return context;
};
