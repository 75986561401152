import React, { useState } from "react";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import { CHANGE_PASSWORD_TEXT } from "./constants";

interface ChangePasswordProps {
  handleBackToSettingsClick: () => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  handleBackToSettingsClick,
}) => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [currentPasswordError, setCurrentPasswordError] = useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState<string>("");
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<string>("");

  const handleSaveClick = (): void => {
    const validOldPassword = "AAI@2024";
    const isCurrentPasswordValid = currentPassword === validOldPassword;
    const isNewPasswordValid = newPassword.length > 5 && newPassword === repeatPassword;

    setCurrentPasswordError(!isCurrentPasswordValid);
    setNewPasswordError(!isNewPasswordValid);

    if (!isCurrentPasswordValid) {
      setCurrentPasswordErrorMessage(CHANGE_PASSWORD_TEXT.CURRENT_PASSWORD_ERROR);
    } else {
      setCurrentPasswordErrorMessage("");
    }

    if (!isNewPasswordValid) {
      setNewPasswordErrorMessage(CHANGE_PASSWORD_TEXT.NEW_PASSWORD_ERROR);
    } else {
      setNewPasswordErrorMessage("");
    }

    if (isCurrentPasswordValid && isNewPasswordValid) {
      // Add the logic to save the new password here
    }
  };

  return (
    <div className="change-pass-card  w-[496px] h-auto bg-white rounded-2xl px-12 pt-12 pb-16">
      <h1 className="text-black/opacity-20 text-2xl font-semibold font-['Inter'] leading-loose">
        {CHANGE_PASSWORD_TEXT.HEADING}
      </h1>
      <div className="inputs flex flex-col gap-6 py-12">
        <Input
          label={CHANGE_PASSWORD_TEXT.CURRENT_PASSWORD_LABEL}
          labelClassName="text-zinc-800 text-base font-medium font-['Heebo'] leading-normal"
          name="password"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          containerClassName="pass-input flex flex-col w-full"
          inputClassName="h-14 p-2"
          showError={currentPasswordError}
          error={currentPasswordErrorMessage}
        />
        <Input
          label={CHANGE_PASSWORD_TEXT.NEW_PASSWORD_LABEL}
          labelClassName="text-zinc-800 text-base font-medium font-['Heebo'] leading-normal"
          name="password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          containerClassName="pass-input flex flex-col w-full"
          inputClassName="h-14 p-2"
        />
        <Input
          label={CHANGE_PASSWORD_TEXT.CONFIRM_PASSWORD_LABEL}
          labelClassName="text-zinc-800 text-base font-medium font-['Heebo'] leading-normal"
          name="repeat-password"
          type="password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          containerClassName="pass-input flex flex-col w-full"
          inputClassName="h-14 p-2"
          showError={newPasswordError}
          error={newPasswordErrorMessage}
        />
      </div>
      <div className="btns flex gap-6">
        <Button
          containerClassName="flex-1"
          className="text-neutral-500 flex-1 text-base font-semibold font-['Inter'] leading-normal bg-white rounded-lg border border-neutral-500 w-full h-14"
          onClick={handleBackToSettingsClick}
        >
          {CHANGE_PASSWORD_TEXT.CANCEL_BUTTON}
        </Button>
        <Button
          containerClassName="flex-1"
          className="text-white flex-1 text-base font-semibold font-['Inter'] leading-normal bg-primary rounded-lg w-full h-14"
          onClick={handleSaveClick}
        >
          {CHANGE_PASSWORD_TEXT.SAVE_BUTTON}
        </Button>
      </div>
    </div>
  );
};

export default ChangePassword;
