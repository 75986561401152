import React, { useEffect, useState } from "react";
import LoginLogo from "../../assets/Images/login-logo.png";
import Button from "../../Components/Button";
import InputField from "../../Components/Input";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { login } from "../../services/Api/Users";
import Spinner from "../../Components/Spinner";
import { paths } from "../../constants/paths";
import {
  LOGIN_TITLE,
  USERNAME_OR_EMAIL_LABEL,
  PASSWORD_LABEL,
  FORGOT_PASSWORD_TEXT,
  REMEMBER_ME_LABEL,
  LOGIN_BUTTON_TEXT,
  NEW_USER_TEXT,
  CONTACT_SUPPORT_TEXT,
  LOGIN_DESCRIPTION,
} from "./constants";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { dispatch, state } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isAuthenticated && email === "") {
      navigate(paths.project.project);
    }
  }, [state.isAuthenticated, navigate]);

  const isSubmitDisabled = !email || !password;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    try {
      dispatch({ type: "LOGIN_REQUEST" });
      const { user, token } = await login(email, password);
      dispatch({ type: "LOGIN_SUCCESS", payload: { user, token } });
      navigate(paths.project.project, { state: { fromLogin: true } });
    } catch (error: any) {
      setPasswordError(error.message);
      dispatch({ type: "LOGIN_FAIL", payload: error.message });
    }
  };

  return (
    <div className="auth-login-main bg-login-bg bg-contain bg-bottom bg-no-repeat flex items-center justify-center h-screen">
      {state.loading && <Spinner />}
      <div className="login-logo flex max-w-7xl justify-between items-center w-full z-10 mx-10">
        <div className="login-card w-[576px] bg-white shadow-2xl py-16 px-12">
          <p className="text-2xl font-semibold leading-8 text-black font-['Inter']">
            {LOGIN_TITLE}
          </p>
          <form onSubmit={handleSubmit} className="login-inputs pt-12">
            <InputField
              label={USERNAME_OR_EMAIL_LABEL}
              type="email"
              name="email"
              id="email-1"
              containerClassName="email-input flex flex-col gap-1"
              labelClassName="text-base font-medium font-['Heebo'] leading-normal text-mainBlack leading-normal"
              inputClassName={`text-mainBlack text-base font-normal font-['Heebo'] leading-normal py-4 px-3 focus:ring-0 focus:outline-none border-borderSecondaryGrey focus:border-transparent ${
                !emailError
                  ? "focus:ring-1 focus:ring-[#00a3b5]"
                  : "focus:ring-1 focus:ring-red-500"
              }`}
              value={email}
              error={emailError}
              onChange={(e) => setEmail(e.target.value)}
            />

            <InputField
              label={PASSWORD_LABEL}
              labelClassName="text-base font-medium font-['Heebo'] text-mainBlack leading-normal"
              name="password"
              type="password"
              value={password}
              error={passwordError}
              onChange={(e) => setPassword(e.target.value)}
              link={{
                href: "/reset-password",
                children: FORGOT_PASSWORD_TEXT,
              }}
              inputClassName={`text-mainBlack text-base font-normal font-['Heebo'] leading-normal py-4 px-3 focus:ring-0 focus:outline-none border-borderSecondaryGrey focus:border-transparent ${
                !passwordError
                  ? "focus:ring-1 focus:ring-[#00a3b5]"
                  : "focus:ring-1 focus:ring-red-500"
              }`}
              containerClassName="pass-input flex flex-col py-6 gap-1 font-['Heebo']"
              autoComplete="off"
            />
            <div className="rem-pass flex items-center gap-1 accent-primary  font-medium text-secondaryGrey600">
              <input
                type="checkbox"
                name="rem-pass"
                id="rem-pass-1"
                className="cursor-pointer"
              />
              <label htmlFor="rem-pass-1">{REMEMBER_ME_LABEL}</label>
            </div>
            <Button
              className={`mt-12 text-base font-semibold font-['Inter'] text-center w-[480px] h-14 rounded-lg py-4 ${
                isSubmitDisabled
                  ? "bg-[#eeeeee] text-borderSecondaryGrey cursor-not-allowed"
                  : "bg-primary text-white"
              }`}
              type="submit"
              disabled={isSubmitDisabled}
            >
              {LOGIN_BUTTON_TEXT}
            </Button>
          </form>
          <div className="text-base font-medium font-['Heebo'] leading-normal text-center pt-6 text-secondaryGrey600">
            {NEW_USER_TEXT}{" "}
            <span
              className="underline text-primary cursor-pointer text-base font-medium font-['Heebo'] leading-normal"
              onClick={() => {
                navigate(paths.support);
              }}
            >
              {CONTACT_SUPPORT_TEXT}
            </span>
          </div>
        </div>
        <div className="logo-desc ml-10">
          <img src={LoginLogo} alt="main-logo" />
          <p className="text-center text-2xl text-secondaryGrey500 font-semibold font-ita pt-9">
            {LOGIN_DESCRIPTION}
          </p>
        </div>
      </div>
    </div>
  );
}
