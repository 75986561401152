import React from "react";
import ProjectCard from "./ProjectCard";
import ProjectImg from "../../assets/Images/project-img.png";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../contexts/ProjectContext";
import Spinner from "../Spinner";
import DataFilter from "../DataFilter";
import { paths } from "../../constants/paths";
import { CARD_VIEW_CONSTANTS } from "./constants";

interface Project {
  project_id: string;
  project_name: string;
  description: string;
  version: string;
}

export default function CardViewComponent() {
  const navigate = useNavigate();
  const { projects, loadingProjects, searchTerm } = useProjectContext();
  const [currentDescription, setCurrentDescription] = React.useState<string>(CARD_VIEW_CONSTANTS.PROJECT_PREVIEW_MESSAGE);
  const [hoveredProject, setHoveredProject] = React.useState<string | null>(null);

  const handleMouseEnter = (id: string, description: string) => {
    setCurrentDescription(description);
    setHoveredProject(id);
  };

  const handleMouseLeave = () => {
    setCurrentDescription(CARD_VIEW_CONSTANTS.PROJECT_PREVIEW_MESSAGE);
    setHoveredProject(null);
  };

  const handleNavigateWiki = (id: string) => {
    navigate(paths.project.projectWikiPage(id));
  };

  return (
    <div className="projects-card-view bg-login-bg bg-no-repeat bg-cover bg-top flex pt-6 pl-10 overflow-auto">
      {loadingProjects && <Spinner />}
      <DataFilter<Project>
        data={projects}
        searchTerm={searchTerm}
        getKey={(project) => project.project_id}
        filterCondition={(project, searchTerm) =>
          project.project_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          project.description.toLowerCase().includes(searchTerm.toLowerCase())
        }
      >
        {(filteredProjects) => (
          <div className="projects bg-transparent flex flex-wrap basis-3/4 gap-4 h-14">
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project) => (
                <ProjectCard
                  key={project.project_id}
                  project={project}
                  handleMouseEnter={() => handleMouseEnter(project.project_id, project.description)}
                  handleMouseLeave={handleMouseLeave}
                  handleNavigateWiki={handleNavigateWiki}
                  ProjectImg={ProjectImg}
                />
              ))
            ) : (
              <div className="no-projects-message text-center w-full p-4">
                {CARD_VIEW_CONSTANTS.NO_PROJECTS_FOUND_MESSAGE}
              </div>
            )}
          </div>
        )}
      </DataFilter>
      <div className="desc bg-primary bg-opacity-10 rounded-tl-[10px] p-4 flex-1">
        <p className="title text-[#616161] font-semibold">{CARD_VIEW_CONSTANTS.DESCRIPTION_TITLE}</p>
        <p
          className={`detail text-[#2E2E2E] leading-6 pl-5 pt-4 transition-opacity duration-300 ${hoveredProject !== null ? "opacity-100" : "opacity-30"
            }`}
        >
          {currentDescription}
        </p>
      </div>
    </div>
  );
}
