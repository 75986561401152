import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";


const AssociationHistory: React.FC = () => {
  const [selected, setSelected] = useState<string>("");

  const handleSelection = (item: string) => {
    setSelected(item);
  };

  return (
    <div className="w-full h-16 bg-primary rounded-lg bg-opacity-10 mt-6 funcion-text-shadow">
      <div className="flex flex-row gap-4 pl-2 -mt-4 backdrop-blur-1 w-[17vw] items-center text-base font-semibold font-['Inter']">
        <span
          className={`cursor-pointer ${selected === "Comments" ? " text-primary" : ""}`}
          onClick={() => handleSelection("Comments")}
        >
          Comments
        </span>
        <span
          className={`cursor-pointer ${selected === "Association" ? " text-primary" : ""}`}
          onClick={() => handleSelection("Association")}
        >
          Association
        </span>
        <span
          className={`cursor-pointer ${selected === "History" ? " text-primary" : ""}`}
          onClick={() => handleSelection("History")}
        >
          History
        </span>
      </div>

      <div className="info-div px-6 flex items-center justify-between h-12">
        <div>Information</div>
        <div><FaAngleDown /></div>
      </div>
    </div>
  );
};

export default AssociationHistory;
