import React, { useMemo } from "react";

interface DataFilterProps<T> {
  readonly data: readonly T[];
  readonly searchTerm: string;
  readonly getKey: (item: T) => string;
  readonly filterCondition: (item: T, searchTerm: string) => boolean;
  readonly children: (filteredData: readonly T[]) => React.ReactNode;
}

// Generic DataFilter component
function DataFilter<T>({
  data,
  searchTerm,
  filterCondition,
  children,
}: Readonly<DataFilterProps<T>>) {
  // Filter the data based on the search term and filter condition
  const filteredData = useMemo(() => {
    return data.filter((item) => filterCondition(item, searchTerm));
  }, [data, searchTerm, filterCondition]);

  return <>{children(filteredData)}</>;
}

export default DataFilter;
