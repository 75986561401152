import React, { useState } from "react";

interface DropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  className?: string;
  svgClassName?: string;
  label?: string;
  error: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  className,
  svgClassName,
  label,
  error
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = event.target.value;
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div className={`relative inline-block text-left ${className}`}>
      {label && (
        <label className={` ${error ? 'text-red-500' : 'text-primary'} text-base font-medium font-['Heebo'] leading-normal`}>
          {label}
        </label>
      )}

      <select
        value={selectedOption}
        onChange={handleChange}
        className={`${error ? 'border-red-500' : 'border-stone-300'} text-base font-normal font-['Heebo'] leading-normal block appearance-none w-full bg-white border backdrop-blur-sm px-3 py-2 rounded-lg focus:outline-none focus:shadow-outline ${selectedOption ? "text-black" : "text-neutral-400"
          }`}
      >
        <option value="" disabled>
          Choose
        </option>
        {options.map((option, index) => (
          <option key={`${option}-${index}`} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div
        className={`pointer-events-none absolute top-8 right-0 flex items-center px-2 text-gray-700 ${svgClassName}`}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_402_12575)">
            <path
              d="M7.66357 10.5L12.6636 15.5L17.6636 10.5H7.66357Z"
              fill="#2E2E2E"
            />
          </g>

          <defs>
            <clipPath id="clip0_402_12575">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.663574 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Dropdown;
