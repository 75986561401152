import React from "react";
import { SAFETY_CHILDS } from "../../constants/constants";
import Table from "../Function/Table";
import { useSafety } from "../../contexts/SafetyContext";
import useUrlParams from "../../hooks/useUrlParams";


const SafetyTableTemplate = () => {
  const { goalsTableTitle, goalsTableData, requirementsTableTitle, requirementsTableData } = useSafety()
  const { child } = useUrlParams()

  let tableTitle: string[] = [];
  let tableData: Array<{ [key: string]: string }> = [];

  if (child === SAFETY_CHILDS.safetyGoals) {
    tableTitle = goalsTableTitle;
    tableData = goalsTableData.map((goal) => ({
      id: goal.id,
      heading: goal.heading,
      reference_id: goal.reference_id,
      description: goal.description,
      assParentRmsId: goal.assParentRmsId,
      assChildRmsId: goal.assChildRmsId,
      assHaraRmsId: goal.assHaraRmsId,
      assHaraComponentName: goal.assHaraComponentName,
    }));
  } else if (child === SAFETY_CHILDS.safetyRequirements) {
    tableTitle = requirementsTableTitle;
    tableData = requirementsTableData.map((requirement) => ({
      id: requirement.id,
      heading: requirement.heading,
      reference_id: requirement.reference_id,
      description: requirement.description,
      assChildRmsId: requirement.assChildRmsId,
      assParentRmsId: requirement.assParentRmsId,
      SafetyGoalRmsId: requirement.SafetyGoalRmsId,
      ItemRmsId: requirement.ItemRmsId,
    }))
  }

  return (
    <div className="safety-req-wrapper w-full flex flex-col h-[70vh] px-8">
      <Table tableTitle={tableTitle} tableData={tableData} />
    </div>
  );
};

export default SafetyTableTemplate;
