import React from "react";
import { useProjectContext } from "../../contexts/ProjectContext";

export default function ProjectCard({
  project,
  handleMouseEnter,
  handleMouseLeave,
  handleNavigateWiki,
  ProjectImg,
}: any) {
  const { fetchProjectDetails } = useProjectContext();

  const handleCardClick = (project_id: string) => {
    fetchProjectDetails(project_id); //fetch specific project details
    handleNavigateWiki(project_id);
  };
  return (
    <div className="bg-white">
      <button
        className="flex project-card flex-col items-center hover:bg-primary hover:bg-opacity-20 justify-center shadow-custom rounded-lg w-[180px] h-[190px] p-4"
        key={project.project_id}
        onMouseEnter={() => handleMouseEnter(project.description)}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleCardClick(project.project_id);
        }}
      >
        <img
          src={ProjectImg}
          alt="project-img"
          className="w-[88px] h-[95px] pb-2"
        />
        <p className="self-start text-base font-medium leading-6">
          {project.project_name}
        </p>
        <p className="self-start text-[10px] font-normal leading-4">
          {project.description}
        </p>
      </button>
    </div>
  );
}
