import React from "react";

const Traceability: React.FC = () => {
  return (
    <div className="w-[101%] h-[35%] overflow-y-auto relative px-2 ">
      <div className="traceability-header h-9 w-[50%] bg-transparent absolute top-1 z-[1] flex flex-row ">
        <div className="bg-white py-1 px-2 rounded-md font-semibold text-base mr-5	">
          Traceability
        </div>
        <div className="bg-white py-1 px-2 rounded-md flex flex-row space-x-4 items-center">
          <label className="flex items-center">
            <span className="mr-2">Number of Levels:</span>
            <select className="border rounded p-1 cursor-pointer">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox w-4 h-4 cursor-pointer" />
            <span className="ml-2">Show Description</span>
          </label>
        </div>
      </div>

      <div className="w-[99%] h-[93%]  bg-white bg-opacity-20 rounded-xl funcion-text-shadow  backdrop-blur-1 absolute bottom-2 "></div>
    </div>
  );
};

export default Traceability;
