import React from 'react';
import { CircleCross } from '../../../assets/Svg/Svg';
import { IMPORT_FAILED_STRINGS } from './constants';

function ImportFailed() {
  return (
    <div className="modal-card w-[383px] h-[160px] bg-white py-6 px-4 flex flex-col justify-center items-center gap-4 rounded-2xl">
      <CircleCross />
      <p className="text-red-500 text-base font-semibold font-['Inter'] leading-normal">
        {IMPORT_FAILED_STRINGS.title}
      </p>
      <p className="text-red-500 text-base font-normal font-['Inter'] leading-normal">
        {IMPORT_FAILED_STRINGS.message}
      </p>
    </div>
  );
}

export default ImportFailed;
