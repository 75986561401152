import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoIosCheckboxOutline, IoMdFolderOpen } from "react-icons/io";
import {
  MdOutlineAnalytics,
  MdOutlineInsertDriveFile,
  MdOutlineModeComment,
} from "react-icons/md";
import { ADD_WIDGET_STRINGS } from './constants';

interface AddWidgetProps {
  onClose: () => void;
}

const AddWidget: React.FC<AddWidgetProps> = () => {
  const buttons = [
    { icon: <IoMdFolderOpen className="text-2xl mb-1" />, label: ADD_WIDGET_STRINGS.buttons[0].label },
    {
      icon: <MdOutlineInsertDriveFile className="text-2xl mb-1" />,
      label: ADD_WIDGET_STRINGS.buttons[1].label,
    },
    {
      icon: <AiOutlineClockCircle className="text-2xl mb-1" />,
      label: ADD_WIDGET_STRINGS.buttons[2].label,
    },
    {
      icon: <MdOutlineAnalytics className="text-2xl mb-1" />,
      label: ADD_WIDGET_STRINGS.buttons[3].label,
    },
    {
      icon: <IoIosCheckboxOutline className="text-2xl mb-1" />,
      label: ADD_WIDGET_STRINGS.buttons[4].label,
    },
    {
      icon: <MdOutlineModeComment className="text-2xl mb-1" />,
      label: ADD_WIDGET_STRINGS.buttons[5].label,
    },
  ];

  return (
    <div className="max-w-screen-md bg-white rounded-2xl shadow flex flex-col gap-10 mx-auto">
      <div className="p-8 flex flex-col h-full">
        <div>
          <p className="text-greyText text-2xl font-semibold font-['Inter'] leading-loose">
            {ADD_WIDGET_STRINGS.title}
          </p>
        </div>
        <div className="mt-6 flex flex-wrap gap-4">
          {buttons.map((button) => (
            <button
              key={button.label}
              className="flex gap-2 items-center py-4 px-6 border border-primary/40 text-mainBlack rounded-xl text-base font-semibold font-['Inter'] leading-loose"
            >
              <span>{button.icon}</span>
              <span>{button.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddWidget;
