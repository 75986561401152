import React from "react";
import { LogoutIcon } from "../../assets/Svg/Svg";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/Api/Users";
import { useAuth } from "../../hooks/useAuth";
import { paths } from "../../constants/paths";
import { LOGOUT_STRINGS } from './constants';

function Logout({ onClose }: { onClose: () => void }) {
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    logout();
    navigate(paths.login);
    onClose();
  };

  return (
    <div className="modal-card w-[383px] h-[313px] bg-white py-6 px-4 flex flex-col justify-center items-center gap-4 rounded-2xl">
      <LogoutIcon />
      <p className="text-cyan-600 text-2xl font-semibold font-['Inter'] leading-loose">
        {LOGOUT_STRINGS.sadMessage}
      </p>
      <p className="text-zinc-800 text-base font-normal font-['Inter'] leading-normal">
        {LOGOUT_STRINGS.confirmMessage}
      </p>
      <div className="btns flex items-center">
        <Button
          onClick={onClose}
          className="w-[120px] h-[40px] mt-0 bg-white text-cyan-600 text-base font-semibold font-['Inter'] leading-normal rounded-lg"
        >
          {LOGOUT_STRINGS.cancelButton}
        </Button>
        <Button
          onClick={handleLogout}
          className="w-[120px] h-[40px] mt-0 bg-primary text-white text-base font-semibold font-['Inter'] leading-normal rounded-lg"
        >
          {LOGOUT_STRINGS.okButton}
        </Button>
      </div>
    </div>
  );
}

export default Logout;
