import React from "react";
import { useHaraTable } from "../../contexts/HaraContext";
import { HARA_CHILDS } from "../../constants/constants";
import useUrlParams from "../../hooks/useUrlParams";
import Table from "../Function/Table";
import { HaraData, OperConditionData, TableData } from './types'

const HaraTableTemplate: React.FC = () => {
  const {
    haraTableTitle,
    haraTableData,
    operConditionalTitle,
    operConditionData,
  } = useHaraTable();
  const { child } = useUrlParams();

  const mapHaraData = (hara: HaraData): HaraData => ({
    id: hara.id,
    heading: hara.heading,
    reference_id: hara.reference_id,
    description: hara.description,
    asil_of_hazard: hara.asil_of_hazard,
    severity: hara.severity,
    exposure: hara.exposure,
    created_by: hara.created_by,
    created_on: hara.created_on,
    modified_by: hara.modified_by,
    modified_on: hara.modified_on,
    controllability: hara.controllability,
    assChildRmsId: hara.assChildRmsId,
    assParentRmsId: hara.assParentRmsId,
    assSystemRmsId: hara.assSystemRmsId,
    safetyRmsId: hara.safetyRmsId,
  });

  const mapOperConditionData = (
    data: OperConditionData
  ): OperConditionData => ({
    id: data.id,
    time: data.time,
    weather: data.weather,
    roadCondition: data.roadCondition,
    roadType: data.roadType,
    roadShape: data.roadShape,
    location: data.location,
    intersection: data.intersection,
    objects: data.objects,
    events: data.events,
  });

  const getTableData = (): TableData => {
    switch (child) {
      case HARA_CHILDS.haraTables:
        return {
          title: haraTableTitle,
          data: haraTableData.map(mapHaraData),
        };
      case HARA_CHILDS.operConditions:
        return {
          title: operConditionalTitle,
          data: operConditionData.map(mapOperConditionData),
        };
      default:
        return { title: [], data: [] };
    }
  };

  const { title: tableTitle, data: tableData } = getTableData();

  return (
    <div className="function-req-wrapper w-full flex flex-col h-[70vh] px-8">
      <Table tableTitle={tableTitle} tableData={tableData} />
    </div>
  );
};

export default HaraTableTemplate;
