import React, { useCallback, useEffect, useState, memo } from "react";
import { IoMdMore, IoMdRadioButtonOn } from "react-icons/io";
import { useVersions } from "../../contexts/VersionsContext";
import useUrlParams from "../../hooks/useUrlParams";
import { useProjectContext } from "../../contexts/ProjectContext";
import { VERSION_TABLE_STRINGS } from "./constants";

interface VersionTableProps {
  tableTitle: string[];
  tableData: Array<{ [key: string]: any }>;
}

const VersionsTable: React.FC<VersionTableProps> = memo(({ tableTitle, tableData }) => {
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const { selectedIndexes, setSelectedIndexes } = useVersions();
  const { projectDetails } = useProjectContext();
  const currentVersion = projectDetails.length > 0 ? projectDetails[0].version_name : "";

  const { view } = useUrlParams();

  const handleMoreClick = useCallback((index: number) => {
    setOpenModalIndex(index === openModalIndex ? null : index);
  }, [openModalIndex]);

  const handleCheckboxChange = useCallback((index: number) => {
    setSelectedIndexes(prevSelected => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter(i => i !== index);
      } else if (prevSelected.length < 2) {
        return [...prevSelected, index];
      }
      return prevSelected;
    });
  }, [setSelectedIndexes]);

  const filteredTableTitles = view ? tableTitle : tableTitle.filter(title => title !== "select");
  /* eslint-disable */
  const filteredTableData = view ? tableData : tableData.map(({ select, ...rest }) => rest);

  useEffect(() => {
    setSelectedIndexes([]);
  }, [setSelectedIndexes]);

  return (
    <div className={`${view === "table" && "min-h-[60vh] max-h-[60vh]"}`}>
      <div
        className={`w-full relative ${view === "table" && "h-full"} ${view === "popup" && "min-h-min max-h-[40vh]"} ${!view && "h-[70vh]"} overflow-y-auto`}
      >
        <div className="min-w-max w-full">
          <div className="h-12 py-1 bg-white border-b-2 border-black/opacity-20 flex sticky top-0 z-[1]">
            {filteredTableTitles.map((item, index) => (
              <div key={index} className="flex-1 px-2 py-1 flex items-center min-w-12 max-w-32">
                <h1
                  className={`text-mainBlack text-base font-semibold font-['Inter'] leading-normal ${item === VERSION_TABLE_STRINGS.changed
                    ? "text-changed"
                    : item === VERSION_TABLE_STRINGS.added
                      ? "text-success"
                      : item === VERSION_TABLE_STRINGS.deleted
                        ? "text-danger"
                        : ""
                    }`}
                >
                  {item !== "more" && item !== "select" ? item : ""}
                </h1>
              </div>
            ))}
          </div>
          <div>
            {filteredTableData.map((item, index) => (
              <div
                key={index}
                className={`hover:bg-[#00a3b5]/20 min-h-8 py-1 flex ${index % 2 !== 0 ? "bg-transparent" : "bg-stone-200 bg-opacity-50"
                  }`}
              >
                {filteredTableTitles.map((_, titleIndex) => (
                  <div
                    key={titleIndex}
                    className="flex-1 px-2 py-1 flex items-center min-w-12 max-w-32"
                  >
                    <p className="text-greyText text-base font-semibold font-['Inter'] leading-normal  relative">
                      {Object.keys(item)[titleIndex] !== "more" &&
                        Object.keys(item)[titleIndex] !== "select" ? (
                        item[Object.keys(item)[titleIndex]]
                      ) : (
                        <div className="flex items-center space-x-2">
                          {Object.keys(item)[titleIndex] === "select" && (
                            <input
                              type="checkbox"
                              style={{
                                width: "1rem",
                                height: "1rem",
                                accentColor: "#00A3B5",
                              }}
                              checked={selectedIndexes.includes(index)}
                              onChange={() => handleCheckboxChange(index)}
                              disabled={
                                !selectedIndexes.includes(index) &&
                                selectedIndexes.length >= 2
                              }
                            />
                          )}
                          {Object.keys(item)[titleIndex] === "more" && !view && (
                            <>
                              <IoMdMore
                                className="text-greyText text-xl cursor-pointer"
                                onClick={() => handleMoreClick(index)}
                              />
                              {item.version === currentVersion && (
                                <IoMdRadioButtonOn className="text-primary text-xl" />
                              )}
                            </>
                          )}
                        </div>
                      )}
                      {Object.keys(item)[titleIndex] === "more" &&
                        openModalIndex === index && (
                          <div className="more-modal w-[200px] text-greyText text-xs font-medium font-['Inter'] leading-tight absolute top-full left-0 mt-2 py-2 bg-white rounded-lg shadow-lg z-20">
                            <div className=" px-2 py-1 rounded-tr-lg rounded-br-lg">
                              <div className="h-4 flex items-center">
                                <span>{VERSION_TABLE_STRINGS.displayVersion}</span>
                              </div>
                            </div>
                            <div className="px-2 py-1">
                              <div className="h-4 flex items-center">
                                <span>{VERSION_TABLE_STRINGS.restoreVersion}</span>
                              </div>
                            </div>
                            <div className="px-2 py-1">
                              <div className="h-4 flex items-center">
                                <span>{VERSION_TABLE_STRINGS.compareToLatest}</span>
                              </div>
                            </div>
                          </div>
                        )}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default VersionsTable;
