import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, RichUtils, AtomicBlockUtils, Modifier, convertToRaw } from "draft-js";
import { useState } from "react";
import LinkSvg from "../../assets/Svg/link.svg";
import TableChartSvg from "../../assets/Svg/table_chart.svg";
import NumberedListSvg from "../../assets/Svg/format_list_numbered.svg";
import BulletListSvg from "../../assets/Svg/format_list_bulleted.svg";
import ItalicSvg from "../../assets/Svg/format_italic.svg";
import BoldSvg from "../../assets/Svg/format_bold.svg";
import PasteSvg from "../../assets/Svg/content_paste.svg";
import CutSvg from "../../assets/Svg/content_cut.svg";
import CopySvg from "../../assets/Svg/content_copy.svg";
import ImageSvg from "../../assets/Svg/image.svg";
import UndoSvg from "../../assets/Svg/undo.svg";
import { useEditorContext } from "../../contexts/EditorContext";

interface TextEditorProps {
  placeholder: string;
  height: string;
}

interface CustomToolbarProps {
  editorState: EditorState;
  onChange: (newState: EditorState) => void;
}

const CustomTable = (props: { block?: any; blockProps?: any }) => {
  const { blockProps } = props;
  const { html } = blockProps.contentState
    .getEntity(props.block.getEntityAt(0))
    .getData();

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ border: "1px solid #ddd", padding: "4px" }}
    />
  );
};

const TextEditor: React.FC<TextEditorProps> = ({ placeholder, height }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { setEditorContent } = useEditorContext();
  const logEditorContent = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(content));
    setEditorContent(rawContent);
  };

  const onChange = (newState: EditorState) => {
    setEditorState(newState);
    logEditorContent(newState);
  };
  const CustomToolbar: React.FC<CustomToolbarProps> = ({
    editorState,
    onChange,
  }) => {
    // add table
    const addTable = () => {
      const rows = parseInt(prompt("Enter the number of rows:") || "0", 10);
      const cols = parseInt(prompt("Enter the number of columns:") || "0", 10);
      if (isNaN(rows) || isNaN(cols) || rows <= 0 || cols <= 0) {
        alert("Please enter valid positive numbers for rows and columns.");
        return;
      }

      let tableHTML = "<table><tbody>";
      for (let i = 0; i < rows; i++) {
        tableHTML += "<tr>";
        for (let j = 0; j < cols; j++) {
          tableHTML += "<td>Cell</td>";
        }
        tableHTML += "</tr>";
      }
      tableHTML += "</tbody></table>";

      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "TABLE",
        "IMMUTABLE",
        { html: tableHTML }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      const newState = AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        " "
      );
      onChange(newState);
    };

    const addImageFromUrl = () => {
      const url = window.prompt("Enter the URL of the image");
      if (url) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
          "IMAGE",
          "MUTABLE",
          { src: url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
        const newState = AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        );
        onChange(newState);
      }
    };

    const addImageFromFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const src = e.target?.result as string;
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "MUTABLE",
            { src }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
          });
          const newState = AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            " "
          );
          onChange(newState);
        };
        reader.readAsDataURL(file);
      }
    };

    const copyText = (): void => {
      if (window) {
        navigator.clipboard.writeText(window.getSelection()?.toString() ?? "");
      }
    };

    const cutText = () => {
      document.execCommand("cut");
    };

    const pasteText = async () => {
      const text = await navigator.clipboard.readText();
      const selection = editorState.getSelection();
      if (selection.isCollapsed()) {
        const contentState = Modifier.insertText(
          editorState.getCurrentContent(),
          selection,
          text
        );
        const newEditorState = EditorState.push(
          editorState,
          contentState,
          "insert-characters"
        );
        onChange(newEditorState);
      }
    };

    const toggleInlineStyle = (style: string) => {
      onChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (blockType: string) => {
      onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const undo = () => {
      onChange(EditorState.undo(editorState));
    };

    const redo = () => {
      onChange(EditorState.redo(editorState));
    };

    return (
      <div className="h-6 justify-start items-start gap-2 inline-flex">
        <div className="p-1 bg-[#eeeeee] rounded-lg justify-start items-start gap-1 flex">
          {/* image from url */}
          <div className="w-4 h-4 relative" onClick={addImageFromUrl}>
            <img src={LinkSvg} alt="Link" className="cursor-pointer" />
          </div>

          {/* table creation */}
          <div className="w-4 h-4 relative" onClick={addTable}>
            <img src={TableChartSvg} alt="Image" className="cursor-pointer" />
          </div>

          {/* image upload from device */}
          <div className="w-4 h-4 relative">
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="fileInput"
              onChange={addImageFromFile}
            />
            <label htmlFor="fileInput">
              <img src={ImageSvg} alt="Upload" className="cursor-pointer" />
            </label>
          </div>
        </div>
        <div className="p-1 bg-[#eeeeee] rounded-lg justify-start items-start gap-1 flex">
          <div className="w-4 h-4 relative" onClick={copyText}>
            <img src={CopySvg} alt="Copy" />
          </div>
          <div className="w-4 h-4 relative" onClick={cutText}>
            <img src={CutSvg} alt="Cut" />
          </div>
          <div className="w-4 h-4 relative" onClick={pasteText}>
            <img src={PasteSvg} alt="Paste" />
          </div>
        </div>
        <div className="p-1 bg-[#eeeeee] rounded-lg justify-start items-start gap-1 flex">
          <div
            className="w-4 h-4 relative"
            onClick={() => toggleInlineStyle("BOLD")}
          >
            <img src={BoldSvg} alt="Bold" />
          </div>
          <div
            className="w-4 h-4 relative"
            onClick={() => toggleInlineStyle("ITALIC")}
          >
            <img src={ItalicSvg} alt="Italic" />
          </div>
          <div
            className="w-4 h-4 relative"
            onClick={() => toggleBlockType("unordered-list-item")}
          >
            <img src={BulletListSvg} alt="Bullet List" />
          </div>
          <div
            className="w-4 h-4 relative"
            onClick={() => toggleBlockType("ordered-list-item")}
          >
            <img src={NumberedListSvg} alt="Numbered List" />
          </div>
        </div>
        <div className="p-1 bg-[#eeeeee] rounded-lg justify-start items-start gap-1 flex">
          <div className="w-4 h-4 relative" onClick={undo}>
            <img src={UndoSvg} alt="Undo" />
          </div>
          <div className="w-4 h-4 relative scale-x-[-1]" onClick={redo}>
            <img src={UndoSvg} alt="Redo" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Editor
      wrapperClassName={`flex flex-col-reverse rounded-lg bg-white`}
      editorClassName="h-[400px] pl-3 pt-2 editor-content "

      wrapperStyle={{ borderWidth: 1, height: height }}
      editorStyle={{ height: `calc(${height} - 100px)` }}
      toolbarStyle={{ border: 0 }}
      toolbar={{ options: [] }}
      toolbarCustomButtons={[
        <CustomToolbar editorState={editorState} onChange={setEditorState} />,
      ]}
      placeholder={placeholder}
      customBlockRenderFunc={(block) => {
        if (block.getType() === "atomic") {
          const entityType = block.getEntityAt(0);
          if (entityType) {
            const entity = editorState
              .getCurrentContent()
              .getEntity(entityType);
            if (entity.getType() === "TABLE") {
              return {
                component: CustomTable,
                editable: false,
                props: {
                  contentState: editorState.getCurrentContent(),
                },
              };
            }
          }
        }
        return null;
      }}
      onEditorStateChange={onChange}
    />
  );
};

export default TextEditor;
