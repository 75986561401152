import { useSearchParams } from "react-router-dom";

const useUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = (key: string) => {
    return searchParams.get(key);
  };

  const setParam = (key: string, value: string) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set(key, value);
      return newParams;
    });
  };

  const setParams = (params: Record<string, string>) => {
    setSearchParams((currentParams) => {
      const newParams = new URLSearchParams(currentParams);
      Object.entries(params).forEach(([key, value]) => {
        newParams.set(key, value);
      });
      return newParams;
    });
  };

  const deleteParams = (keys: string[]) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      keys.forEach((key) => newParams.delete(key));
      return newParams;
    });
  };

  return {
    page: getParam("page"),
    id: getParam("id"),
    view: getParam("view"),
    child: getParam("child"),
    version1: getParam("version1"),
    version2: getParam("version2"),
    projectId: getParam("projectId"),
    pageNo: getParam("pageNo"),
    setParam,
    setParams,
    deleteParams,
  };
};

export default useUrlParams;
