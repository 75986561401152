import React, { useState, useEffect } from "react";
import "../pages/Project/Project.css";
import Sidebar from "./Sidebar";
import ProjectWiki from "../pages/Wiki/Wiki";
import { useSearchParams } from "react-router-dom";
import CustomFunction from "../pages/Function/Function";
import AllVersions from "../pages/Versions/AllVersions";
import Hara from "../pages/Hara/Hara";
import { PAGES } from "../constants/constants";
import Safety from "../pages/Safety/Safety";
import useUrlParams from "../hooks/useUrlParams";
import TitleBar from "./TitleBar";
import HeaderLayout from "./HeaderLayout";

const PagesWrapper: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const { page, view } = useUrlParams();

  useEffect(() => {
    setSelectedItem(page);
  }, [searchParams]);

  const getComponent = () => {
    switch (page) {
      case PAGES.wiki:
        return <ProjectWiki view={view ?? ""} />;
      case PAGES.function:
        return <CustomFunction view={view ?? ""} />;
      case PAGES.hara:
        return <Hara view={view ?? ""} />;
      case PAGES.safety:
        return <Safety view={view ?? ""} />;
    }
  };

  return (
    <HeaderLayout>
      {page === PAGES.versions ? (
        <div className="h-svh bg-login-bg bg-contain bg-no-repeat bg-center">
          <AllVersions />
        </div>
      ) : (
        <div>
          <TitleBar selectedItem={selectedItem} />
          <div className="h-screen flex flex-row bg-login-bg bg-contain bg-no-repeat bg-center">
            <Sidebar />
            <div className="flex w-4/5">{getComponent()}</div>
          </div>
        </div>
      )}
    </HeaderLayout>
  );
};

export default PagesWrapper;
