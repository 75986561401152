import React, { useEffect, useState } from "react";
import { HiOutlinePlusCircle } from "react-icons/hi";
import Modal from "./Modal/Modal";
import Addnew from "./Popups/AddNew/Addnew";
import AddNewPage from "./Popups/AddNew/AddNewPage";
import AddNewFolder from "./Popups/AddNew/AddNewFolder";
import { useSearchParams } from "react-router-dom";
import { useFunctionTable } from "../contexts/FunctionContext";
import { useSideBar } from "../contexts/SideBarContext";
import { useHaraTable } from "../contexts/HaraContext";
import { useSafety } from "../contexts/SafetyContext";
import useUrlParams from "../hooks/useUrlParams";
import SearchInput from "../Components/SearchInput";
import Accordion from "./Accordian";
import { PAGES } from "../constants/constants";
import { useProjectContext } from "../contexts/ProjectContext";

interface SidebarItem {
  name: string;
  subItems?: string[];
}

const SideBar: React.FC = () => {
  const {
    functionTableData,
    triggeringEventTableData,
    hazardTableData,
    preliminaryArchitecture,
  } = useFunctionTable();
  const { haraTableData, operConditionData } = useHaraTable();
  const { goalsTableData, requirementsTableData } = useSafety();

  const {
    sidebarSafetyItems,
    sidebarWikiItems,
    sidebarFunctionItems,
    sidebarHazardsItems,
    updateSidebarFunctionItems,
    updateSidebarHaraItems,
    updateSidebarSafetyItems,
  } = useSideBar();

  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  const [accordionItems, setAccordionItems] =
    useState<SidebarItem[]>(sidebarWikiItems);
  const [showModal, setShowModal] = useState(false);
  const [selectModal, setSelectModal] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [accordianTitle, setAccordianTitle] = useState("Dashboard");
  const { setSearchTerm } = useProjectContext();

  const { page, child } = useUrlParams();

  useEffect(() => {
    if (page === PAGES.wiki) {
      setAccordionItems(sidebarWikiItems);
      setAccordianTitle("Dashboard");
    } else if (page === PAGES.function) {
      setAccordionItems(sidebarFunctionItems);
      setAccordianTitle("Function name --(ID)");
    } else if (page === PAGES.hara) {
      setAccordionItems(sidebarHazardsItems);
      setAccordianTitle("Hazards Analysis");
    } else if (page === PAGES.safety) {
      setAccordionItems(sidebarSafetyItems);
      setAccordianTitle("Safety Analysis");
    }
  }, [
    page,
    searchParams,
    sidebarSafetyItems,
    sidebarFunctionItems,
    sidebarHazardsItems,
    sidebarWikiItems,
  ]);

  useEffect(() => {
    if (page === PAGES.function) {
      updateSidebarFunctionItems(
        functionTableData,
        triggeringEventTableData,
        hazardTableData,
        preliminaryArchitecture
      );
    } else if (page === PAGES.hara) {
      updateSidebarHaraItems(haraTableData, operConditionData);
    } else if (page === PAGES.safety) {
      updateSidebarSafetyItems(goalsTableData, requirementsTableData);
    }
  }, [
    child,
    page,
    functionTableData,
    triggeringEventTableData,
    hazardTableData,
    preliminaryArchitecture,
    haraTableData,
    operConditionData,
    goalsTableData,
    requirementsTableData,
  ]);

  const handleAccordionToggle = (accordionKey: string) => {
    setOpenAccordion(openAccordion === accordionKey ? null : accordionKey);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectModal(0);
  };

  useEffect(() => {
    setSearchTerm("");
  }, []);

  return (
    <div className="w-1/5 border-r-2 rounded-tr-2xl h-auto border-black-20 bg-transparent">
      <Modal show={showModal} onClose={closeModal}>
        {(() => {
          switch (selectModal) {
            case 1:
              return <AddNewPage closeModal={closeModal} />;
            case 2:
              return <AddNewFolder closeModal={closeModal} />;
            default:
              return <Addnew setSelectModal={setSelectModal} />;
          }
        })()}
      </Modal>
      <form
        action=""
        className="flex items-center h-14 border-b border-black/opacity-20 pl-6"
      >
        <div className="flex items-center gap-2 w-full max-w-full">
          <SearchInput className="flex-1 w-full md:w-[12vw] 2xl:w-[14vw] 3xl:w-[17vw]" />
          <HiOutlinePlusCircle
            className="text-gray-500 text-2xl cursor-pointer mr-3"
            onClick={openModal}
          />
        </div>
      </form>

      <div className="pr-4 pt-3">
        <Accordion
          key="accordion1"
          title={accordianTitle}
          items={accordionItems}
          isOpen={openAccordion === "accordion1"}
          onToggle={() => handleAccordionToggle("accordion1")}
        />
      </div>
    </div>
  );
};

export default SideBar;
