import React, { useState, useMemo, useCallback } from "react";
import { AddCircleBlue, Hazard } from "../../assets/Svg/Svg";
import { MdLightbulbOutline, MdOutlineFlashOn } from "react-icons/md";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import useUrlParams from "../../hooks/useUrlParams";
import {
  CONST_URLS,
  FUNC_CHILD,
  SAFETY_CHILDS,
  VIEWS,
} from "../../constants/constants";
import Pagination from "../../Components/Pagination";
import { useFunctionTable } from "../../contexts/FunctionContext";
import { useSafety } from "../../contexts/SafetyContext";
import { useHaraTable } from "../../contexts/HaraContext";

interface TableProps {
  tableTitle: string[];
  tableData: Array<{ [key: string]: any }>;
}

const Table: React.FC<TableProps> = ({ tableTitle, tableData }) => {
  const { id, setParams, setParam, child } = useUrlParams();
  const { getFunctionalRequirement } = useFunctionTable();
  const { getHaraData } = useHaraTable();
  const { fetchSafetyGoal, fetchSafetyRequirement } = useSafety();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const [hoveredRow, setHoveredRow] = useState<string | null>(null); // State to track hovered row

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return tableData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, tableData]);

  const totalPages = useMemo(
    () => Math.ceil(tableData.length / itemsPerPage),
    [tableData]
  );

  const paginate = useCallback(
    (pageNumber: number) => {
      if (pageNumber !== currentPage) {
        setParams({
          pageno: pageNumber.toString(),
        });
        setCurrentPage(pageNumber);
      }
    },
    [currentPage, setParams]
  );

  const handleSelect = useCallback(
    (rowId: string) => {
      if (rowId === id) return; // Avoid unnecessary API calls if id is the same

      if (child === FUNC_CHILD.functionalReq) {
        getFunctionalRequirement(rowId);
      } else if (child === FUNC_CHILD.hazards) {
        getHaraData(rowId);
      } else if (child === SAFETY_CHILDS.safetyGoals) {
        fetchSafetyGoal(rowId);
      } else if (child === SAFETY_CHILDS.safetyRequirements) {
        fetchSafetyRequirement(rowId);
      }
      setParams({
        view: VIEWS.descriptive,
        id: rowId,
        pageNo: currentPage.toString(),
      });
    },
    [
      id,
      child,
      getFunctionalRequirement,
      getHaraData,
      fetchSafetyGoal,
      fetchSafetyRequirement,
      setParams,
      currentPage,
    ]
  );

  const shouldHighlight = useCallback((value: string) => {
    return ["FR-", "HZ-", "SG-", "TE-", "SR-"].some((prefix) =>
      value.startsWith(prefix)
    );
  }, []);

  const getItemClasses = useCallback(
    (
      value: string,
      isSelected: boolean,
      isHovered: boolean,
      isIdColumn: boolean
    ) => {
      if (value === "N/A") {
        return "text-zinc-600 text-base";
      }
      if (isIdColumn || ((isSelected || isHovered) && shouldHighlight(value))) {
        return "underline text-primary text-base cursor-pointer";
      }
      return "text-zinc-600 text-base";
    },
    [shouldHighlight]
  );

  const renderIcon = useCallback((value: string) => {
    if (value.startsWith("FR-")) return <MdLightbulbOutline />;
    if (value.startsWith("HZ-")) return <Hazard />;
    if (value.startsWith("SG-") || value.startsWith("SR-"))
      return <AiOutlineSafetyCertificate />;
    if (value.startsWith("TE-")) return <MdOutlineFlashOn />;
    return null;
  }, []);

  const renderDescription = useCallback(
    (description: string, itemId: string) => {
      const maxLength = 50;
      return description.length > maxLength ? (
        <>
          {description.slice(0, maxLength)}...{" "}
          <button
            onClick={() => handleSelect(itemId)}
            className="text-primary underline"
          >
            Show More
          </button>
        </>
      ) : (
        description
      );
    },
    [handleSelect]
  );

  const handleId = () => {
    setParam(CONST_URLS.view, VIEWS.detailed);
  };

  const isIdColumn = useCallback((title: string) => {
    const lowerTitle = title.toLowerCase();
    return lowerTitle.includes("id");
  }, []);

  return (
    <>
      <div className="w-full h-[calc(100vh-250px)] overflow-y-auto">
        <div className="overflow-x-auto min-w-max w-full">
          <div className="h-20 py-2 bg-white border-b-2 border-mainBlack/opacity-20 flex sticky top-0">
            {tableTitle.map((item, index) => (
              <div
                key={index}
                className="flex-1 px-2 py-2 flex items-center min-w-[50px] max-w-[250px]"
              >
                <h1 className="text-base font-semibold font-['Inter'] leading-normal text-mainBlack">
                  {item}
                </h1>
              </div>
            ))}
          </div>
          {tableData.length > 0 ? (
            <div>
              {currentItems.map((item, index) => {
                const isSelected = item.id === id;
                const isHovered = item.id === hoveredRow;

                return (
                  <div
                    onMouseEnter={() =>
                      setHoveredRow(item.id || item.hazardId || "")
                    }
                    onMouseLeave={() => setHoveredRow(null)}
                    onClick={() => handleSelect(item.id || item.hazardId || "")}
                    key={item.id || item.hazardId}
                    className={`hover:bg-primary/20 min-h-24 py-2 ${
                      isSelected
                        ? "bg-primary/20"
                        : index % 2 === 0
                        ? "bg-greyText/10"
                        : "bg-transparent"
                    } backdrop-blur-sm flex`}
                  >
                    {Object.entries(item).map(([key, value], idx) => (
                      <div
                        onClick={handleId}
                        key={idx}
                        className="cursor-pointer flex-1 px-2 py-2 flex flex-col items-start justify-center min-w-[50px] max-w-[250px] break-all whitespace-normal"
                      >
                        {key === "description" ? (
                          <p className="text-greyText text-base font-semibold font-['Inter'] leading-normal">
                            {renderDescription(value, item.id)}
                          </p>
                        ) : (
                          <div className="flex flex-col space-y-4">
                            {value
                              .split(",")
                              .map((segment: string, segmentIdx: number) => (
                                <div
                                  key={`${idx}-${segmentIdx}`}
                                  className="flex items-center break-all whitespace-normal"
                                >
                                  {renderIcon(segment.trim())}
                                  <p
                                    className={`text-base font-semibold font-['Inter'] leading-normal ${getItemClasses(
                                      segment,
                                      isSelected,
                                      isHovered,
                                      isIdColumn(key)
                                    )} ml-1`}
                                  >
                                    {segment.trim() === "add" ? (
                                      <AddCircleBlue />
                                    ) : (
                                      segment.trim()
                                    )}
                                  </p>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-10 text-zinc-500">
              No data available
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      {tableData.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
      )}
    </>
  );
};

export default Table;
