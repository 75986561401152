import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import {
  MdFolderOpen,
  MdLightbulbOutline,
  MdOutlineFlashOn,
  MdArchitecture,
  MdOutlineFlag,
} from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { useFunctionTable } from "../contexts/FunctionContext";
import {
  FUNC_CHILD,
  HARA_CHILDS,
  SAFETY_CHILDS,
  VIEWS,
  PAGES,
  CONST_URLS,
} from "../constants/constants";
import { useHaraTable } from "../contexts/HaraContext";
import { Hara, Hazard } from "../assets/Svg/Svg";
import { useSafety } from "../contexts/SafetyContext";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import useUrlParams from "../hooks/useUrlParams";
import { useProjectContext } from "../contexts/ProjectContext";
import DataFilter from "./DataFilter";

interface AccordionProps {
  title: string;
  items: {
    name: string;
    subItems?: string[];
  }[];
  isOpen: boolean;
  onToggle: () => void;
}

const renderIcon = (
  itemName: string,
  id: string | null,
  isSubItem: boolean = false
) => {
  const baseClass = "mr-2 text-xl";
  const activeClass = id === itemName ? "text-primary" : "";

  const iconMap = {
    [FUNC_CHILD.functionalReq]: (
      <MdLightbulbOutline className={`${baseClass} ${activeClass}`} />
    ),
    [FUNC_CHILD.triggeringEvt]: (
      <MdOutlineFlashOn className={`${baseClass} ${activeClass}`} />
    ),
    [FUNC_CHILD.hazards]: <Hazard className={`${baseClass} ${activeClass}`} />,
    [FUNC_CHILD.preArch]: (
      <MdArchitecture className={`${baseClass} ${activeClass}`} />
    ),
    [HARA_CHILDS.haraTables]: (
      <Hara className={`${baseClass} ${activeClass}`} />
    ),
    [HARA_CHILDS.operConditions]: (
      <MdOutlineFlag className={`${baseClass} ${activeClass}`} />
    ),
    [SAFETY_CHILDS.safetyGoals]: (
      <AiOutlineSafetyCertificate className={`${baseClass} ${activeClass}`} />
    ),
  };

  // For main folders, always return the folder icon if there's no match
  if (!isSubItem) {
    return (
      iconMap[itemName] || (
        <MdFolderOpen className={`${baseClass} ${activeClass}`} />
      )
    );
  }

  // For sub-items, return the icon from the map or null
  return iconMap[itemName] || null;
};

const Accordion: React.FC<AccordionProps> = ({
  title,
  items,
  isOpen: isOpenProps,
  onToggle,
}) => {
  const {
    functionTableData,
    triggeringEventTableData,
    hazardTableData,
    preliminaryArchitecture,
    updateFunctionParams,
  } = useFunctionTable();
  const { haraTableData, operConditionData, updateHazardParams } =
    useHaraTable();
  const { goalsTableData, requirementsTableData, updateSafetyParams } =
    useSafety();
  const [, setSearchParams] = useSearchParams();
  const [openSubItems, setOpenSubItems] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(isOpenProps);
  const { searchTerm } = useProjectContext();

  const { id, child, page, setParams } = useUrlParams();

  const deletePrms = () => {
    setSearchParams((params) => {
      params.delete(CONST_URLS.view);
      params.delete(CONST_URLS.child);
      params.delete(CONST_URLS.id);
      return params;
    });
  };

  const handleSubToggle = (itemName: string) => {
    const isItemOpen = openSubItems === itemName;
    const newOpenSubItems = isItemOpen ? null : itemName;
    setOpenSubItems(newOpenSubItems);

    const updateSearchParams = (id: string | null, view: string) => {
      setSearchParams((params) => {
        if (!view) {
          updateParams(itemName);
          return params;
        }
        params.set("view", view);
        if (id) params.set("id", id);

        if (child === itemName) {
          openSubItems ? deletePrms() : updateParams(itemName);
        } else {
          params.set("child", itemName);
        }
        return params;
      });
    };

    const updateParams = (itemName: string) => {
      if (page === PAGES.function) {
        updateFunctionParams(itemName);
      } else if (page === PAGES.hara) {
        updateHazardParams(itemName);
      } else if (page === PAGES.safety) {
        updateSafetyParams(itemName);
      }
    };

    if (page === PAGES.function) {
      switch (itemName) {
        case FUNC_CHILD.functionalReq:
          updateSearchParams(functionTableData[0]?.id || null, "table");
          break;
        case FUNC_CHILD.triggeringEvt:
          updateSearchParams(triggeringEventTableData[0]?.id || null, "table");
          break;
        case FUNC_CHILD.hazards:
          updateSearchParams(hazardTableData[0]?.id || null, "table");
          break;
        case FUNC_CHILD.preArch:
          updateSearchParams(
            preliminaryArchitecture[0]?.id || null,
            "architecture"
          );
          break;
        default:
          updateParams(itemName);
          break;
      }
    } else if (page === PAGES.hara) {
      switch (itemName) {
        case HARA_CHILDS.haraTables:
          updateSearchParams(haraTableData[0]?.id || null, "table");
          break;
        case HARA_CHILDS.operConditions:
          updateSearchParams(operConditionData[0]?.id || null, "table");
          break;
        default:
          updateParams(itemName);
          break;
      }
    } else if (page === PAGES.safety) {
      switch (itemName) {
        case SAFETY_CHILDS.safetyGoals:
          updateSearchParams(goalsTableData[0]?.id || null, "table");
          break;
        case SAFETY_CHILDS.safetyRequirements:
          updateSearchParams(requirementsTableData[0]?.id || null, "table");
          break;
        default:
          updateParams(itemName);
          break;
      }
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    onToggle();
  };

  const itemsPerPage = 4;

  const handleSubItemClick = (subItem: string, subIndex: number) => {
    const calculatedPageNumber = Math.floor(subIndex / itemsPerPage) + 1;
    setParams({
      pageNo: calculatedPageNumber.toString(),
      id: subItem,
    });
  };

  const handleSubItemDoubleClick = (subItem: string) => {
    setParams({
      view: VIEWS.descriptive,
      id: subItem,
    });
  };

  useEffect(() => {
    if (child) {
      const itemNames = items.map((item) => item.name);
      if (itemNames.includes(child)) {
        setOpenSubItems(child);
        setIsOpen(true);
      }
    }
  }, [child, items]);

  return (
    <div className="relative">
      <button
        className={`w-full dashboard-div h-10 rounded-r-lg flex items-center p-3 text-primary font-semibold cursor-pointer justify-between ${
          isOpen ? "bg-dashboard" : ""
        }`}
        onClick={handleToggle}
      >
        <span className="ml-8">{title}</span>
        <FaAngleDown
          className={`transition-transform duration-300 ${
            isOpen ? "rotate-180 " : "rotate-0"
          }`}
        />
      </button>
      {isOpen && (
        <div className="relative pl-6 before:absolute before:top-0 before:left-3 before:h-full before:w-0.5">
          {items.map((item, index) => (
            <div
              key={index + "item"}
              className="no-select text-gray-700 font-semibold relative pl-6 py-1 before:absolute before:top-0 before:left-3 before:h-full before:w-0.5 before:bg-gray-300"
            >
              <button
                className={`w-full flex move-left items-center justify-between hover:border-l-4 hover:rounded-r-lg hover:text-primary h-9 cursor-pointer ${
                  openSubItems === item.name
                    ? "text-primary fix-acc transition-delayed border-l-4 border-l-primary"
                    : ""
                }`}
                onClick={() => handleSubToggle(item.name)}
              >
                <div
                  className={` flex items-center cursor-pointer text-nowrap overflow-hidden text-ellipsis`}
                >
                  {renderIcon(item.name, openSubItems)}
                  <span className="overflow-hidden text-ellipsis">
                    {item.name}
                  </span>
                </div>
                {item.subItems && (
                  <FaAngleDown
                    className={`text-gray-500 transition-transform duration-300 ${
                      openSubItems === item.name
                        ? "rotate-180 text-primary"
                        : "rotate-0"
                    }`}
                  />
                )}
              </button>
              <div
                className={`overflow-hidden transition duration-300 ease-out ${
                  openSubItems === item.name
                    ? "max-h-screen transition-delayed"
                    : "max-h-0"
                }`}
              >
                {item.subItems && openSubItems === item.name && (
                  <DataFilter
                    data={item.subItems}
                    searchTerm={searchTerm}
                    getKey={(subItem) => subItem}
                    filterCondition={(subItem, searchTerm) =>
                      subItem.toLowerCase().includes(searchTerm.toLowerCase())
                    }
                  >
                    {(filteredSubItems) => (
                      <div className="overflow-y-auto max-h-[30vh] no-select cursor-pointer text-gray-700 font-semibold relative pl-6 ml-2 mt-2 before:absolute before:top-0 before:left-3 before:h-full before:w-0.5">
                        {filteredSubItems.map((subItem, subIndex) => (
                          <button
                            key={subIndex + "sub"}
                            className={`w-full sub-item-div py-1 h-9 border-l-4 pl-3 border-gray-300 rounded-r-lg cursor-pointer text-nowrap overflow-hidden text-ellipsis ${
                              subItem === id
                                ? "text-primary bg-[#eeeeee] border-l border-l-primary"
                                : ""
                            }`}
                            onClick={() =>
                              handleSubItemClick(subItem, subIndex)
                            }
                            onDoubleClick={() =>
                              handleSubItemDoubleClick(subItem)
                            }
                          >
                            <div className="flex">
                              <div>{subItem}</div>
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                  </DataFilter>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="absolute top-0 left-6 h-full w-0.5"></div>
    </div>
  );
};

export default Accordion;
