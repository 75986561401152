import React, { useEffect } from "react";
import { useFunctionTable } from "../contexts/FunctionContext";
import { useHaraTable } from "../contexts/HaraContext";
import { useSafety } from "../contexts/SafetyContext";
import useUrlParams from "../hooks/useUrlParams";
import AssociationHistory from "../pages/Function/AssociationHistory";
import Traceability from "../pages/Function/Traceability";
import { FUNC_CHILD, HARA_CHILDS, SAFETY_CHILDS } from "../constants/constants";
import FuncReqCard from "./Descriptive/FuncReqCard";
import Spinner from "./Spinner";

function Detailed() {
  const { child, id } = useUrlParams();
  const { functionalRequirement, loading, getFunctionalRequirement } =
    useFunctionTable();
  const { hara, getHaraData } = useHaraTable();
  const {
    safetyGoal,
    safetyRequirement,
    fetchSafetyGoal,
    fetchSafetyRequirement,
  } = useSafety();

  const dataMapping: Record<string, any[]> = {
    [FUNC_CHILD.functionalReq]: functionalRequirement || [],
    [FUNC_CHILD.triggeringEvt]: functionalRequirement || [],
    [FUNC_CHILD.hazards]: hara || [],
    [HARA_CHILDS.haraTables]: hara || [],
    [HARA_CHILDS.operConditions]: hara || [],
    [SAFETY_CHILDS.safetyGoals]: safetyGoal || [],
    [SAFETY_CHILDS.safetyRequirements]: safetyRequirement || [],
  };

  const dataForCurrentChild = dataMapping[child ?? ""] || [];

  useEffect(() => {
    if (!id) return;
    const fetchData = () => {
      switch (child) {
        case FUNC_CHILD.functionalReq:
        case FUNC_CHILD.triggeringEvt:
          getFunctionalRequirement(id);
          break;
        case HARA_CHILDS.haraTables:
        case HARA_CHILDS.operConditions:
          getHaraData(id);
          break;
        case SAFETY_CHILDS.safetyGoals:
          fetchSafetyGoal(id);
          break;
        case SAFETY_CHILDS.safetyRequirements:
          fetchSafetyRequirement(id);
          break;
        default:
          break;
      }
    };

    fetchData();
  }, []);

  return (
    <div className="profile-main w-full h-screen">
      {loading ? (
        <Spinner />
      ) : (
        <div className="func-req-main-div flex flex-col items-center justify-start w-full bg-transparent h-full pl-4 pt-7 pr-9">
          <FuncReqCard dataForCurrentChild={dataForCurrentChild} />
          <Traceability />
          <AssociationHistory />
        </div>
      )}
    </div>
  );
}

export default Detailed;
