import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import {
  MdOutlineAccountTree,
  MdOutlineAssignment,
  MdOutlineDone,
  MdOutlineEdit,
  MdOutlineViewList,
} from "react-icons/md";
import { CONST_URLS, PAGES, VIEWS } from "../constants/constants";
import { IoMdClose } from "react-icons/io";
import useUrlParams from "../hooks/useUrlParams";

const ViewList = () => {
  const { child, view, page, setParam } = useUrlParams()
  const handleEdit = () => {
    setParam(CONST_URLS.view, VIEWS.edit)
  };
  return (
    <div className="flex flex-row w-full">
      <div className="hara-body-wrapper w-full relative">
        <div className="h-[56px] flex text-2xl items-center gap-4 border-b border-black/opacity-20 justify-start pl-7 pe-4 text-zinc-500">
          {view === VIEWS.detailed || view === VIEWS.edit ? (
            <div className="w-full flex justify-between pr-6">
              <button
                onClick={() => {
                  setParam(CONST_URLS.view, VIEWS.descriptive)
                }}
                className="text-primary cursor-pointer flex justify-start items-center h-full text-base font-semibold font-['Inter']"
              >
                <FaAngleLeft />
                <span className="pl-1">{child}</span>
              </button>
              {view === VIEWS.edit ? (
                <div className="flex gap-2">
                  <IoMdClose className="text-danger cursor-pointer" />
                  <MdOutlineDone className="text-primary cursor-pointer" />
                </div>
              ) : (
                <MdOutlineEdit
                  onClick={handleEdit}
                  className="text-primary cursor-pointer"
                />
              )}
            </div>
          ) : (
            <>
              <button
                onClick={() => {
                  setParam(CONST_URLS.view, VIEWS.table)
                }}
              >
                <MdOutlineViewList
                  className={`${view === VIEWS.table ? "text-primary" : ""
                    } cursor-pointer`}
                />
              </button>
              <button
                onClick={() => {
                  setParam(CONST_URLS.view, VIEWS.descriptive)
                }}
              >
                <MdOutlineAssignment
                  className={`${view === VIEWS.descriptive ? "text-primary" : ""
                    } cursor-pointer`}
                />
              </button>
              <button
                onClick={() => {
                  setParam(CONST_URLS.view, VIEWS.graph)
                }}
              >
                <MdOutlineAccountTree
                  className={`${view === VIEWS.graph ? "text-primary" : ""
                    } cursor-pointer`}
                />
              </button>

              <div className="px-3 cursor-pointer absolute right-7 h-8 flex justify-center items-center rounded-3xl bg-cyan-600 text-white font-semibold text-sm">
                <p>
                  {page === PAGES.function
                    ? "Add Functional Requirement"
                    : "Add New"}
                </p>
                <span className="text-lg pl-2 pb-[3px]">+</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewList;
