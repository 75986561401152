import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import ResetPass from "./pages/ResetPassword/ResetPass";
import "./App.css";
import { ToastContainer } from "react-toastify";
import Account from "./pages/Account/Account";
import PagesWrapper from "./Components/PagesWrapper";
import ContactSupport from "./pages/Account/ContactSupport";
import Project from "./pages/Project/Project";
import { FunctionProvider } from "./contexts/FunctionContext";
import { SideBarProvider } from "./contexts/SideBarContext";
import { AuthProvider } from "./contexts/AuthContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import PrivateRoute from "./Components/PrivateRoute";
import { VersionsProvider } from "./contexts/VersionsContext";
import { HaraProvider } from "./contexts/HaraContext";
import { SafetyProvider } from "./contexts/SafetyContext";
import { EditorProvider } from "./contexts/EditorContext";
import { paths } from "./constants/paths";

const App: React.FC = () => {
  return (
    <>
      <Router>
        <EditorProvider>
          <VersionsProvider>
            <AuthProvider>
              <ProjectProvider>
                <HaraProvider>
                  <FunctionProvider>
                    <SafetyProvider>
                      <SideBarProvider>
                        <Routes>
                          <Route path={paths.resetPassword.resetPassword} element={<ResetPass />} />
                          <Route path={paths.login} element={<Login />} />
                          <Route path={paths.support} element={<ContactSupport />} />
                          <Route
                            path={paths.project.project}
                            element={
                              <PrivateRoute>
                                <Project />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={paths.home}
                            element={
                              <PrivateRoute>
                                <Project />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={paths.account}
                            element={
                              <PrivateRoute>
                                <Account />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={paths.project.projectWiki}
                            element={
                              <PrivateRoute>
                                <PagesWrapper />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path="*"
                            element={<Navigate to={paths.login} replace />}
                          />
                        </Routes>
                      </SideBarProvider>
                    </SafetyProvider>
                  </FunctionProvider>
                </HaraProvider>
              </ProjectProvider>
            </AuthProvider>
          </VersionsProvider>
        </EditorProvider>
      </Router>
      <ToastContainer className={" min-w-min"} />
    </>
  );
};

export default App;
