import React, { useEffect, useState } from "react";
import Button from "../Button";
import { AddProjectIcon } from "../../assets/Svg/Svg";
import ListViewComponent from "./ListView";
import CardViewComponent from "./CardView";
import AddNewProjectForm from "./AddNewProjectForm";
import { MdViewList } from "react-icons/md";
import { FaTableCells } from "react-icons/fa6";
import SearchInput from "../SearchInput";
import { useProjectContext } from "../../contexts/ProjectContext";
import { PROJECT_BODY_CONSTANTS } from "./constants";

export default function ProjectBody() {
  const [isListView, setIsListView] = useState(false);
  const [addProjectVisible, setAddProjectVisible] = useState(false);
  const { setSearchTerm } = useProjectContext();

  const toggleView = () => {
    setIsListView((prev) => !prev);
  };

  const showAddProject = () => {
    setAddProjectVisible(true);
  };

  const hideAddProject = () => {
    setAddProjectVisible(false);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [setSearchTerm]); // Ensure setSearchTerm is in the dependency array

  return (
    <div className="projects-wrapper">
      {!addProjectVisible ? (
        <div className="projects-main">
          <div className="projects-top-bar flex justify-between items-center w-full h-14 border-b border-black border-opacity-20 px-5">
            <SearchInput />
            <div className="add-menu flex items-center gap-5">
              <Button
                className="flex items-center gap-1 border border-primary rounded-2xl px-4 h-8"
                onClick={showAddProject}
              >
                <p className="text-xs text-primary font-semibold leading-5">
                  {PROJECT_BODY_CONSTANTS.ADD_PROJECT_TEXT} {/* Use the constant here */}
                </p>
                <AddProjectIcon />
              </Button>
              <Button className="bg-transparent mr-5 text-2xl text-primary" onClick={toggleView}>
                {isListView ? <MdViewList /> : <FaTableCells />}
              </Button>
            </div>
          </div>
          {isListView ? <ListViewComponent /> : <CardViewComponent />}
        </div>
      ) : (
        <AddNewProjectForm hideAddProject={hideAddProject} />
      )}
    </div>
  );
}
