export const ALL_VERSIONS_TEXT = {
  BACK_TO_ALL_VERSIONS: "Back to All Versions",
  BACK: "Back",
  COMPARE_VERSIONS_BUTTON: "Compare Versions",
  VERSIONS_TITLE: "Versions",
};

export const VERSION_TABLE_STRINGS = {
  displayVersion: "Display this version",
  restoreVersion: "Restore this version",
  compareToLatest: "Compare to latest version",
  changed: "Changed",
  added: "Added",
  deleted: "Deleted",
};
