import React from "react";
import { TopbarSearchIcon } from "../assets/Svg/Svg";
import { useProjectContext } from "../contexts/ProjectContext";

interface SearchInputProps {
  className?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ className }) => {
  const { setSearchTerm } = useProjectContext();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={`search-in flex items-center ${className}`}>
      <div className="se-icon h-fit relative left-8">
        <TopbarSearchIcon />
      </div>
      <input
        type="search"
        name="pro-search"
        id="pro-search-1"
        placeholder="Search"
        className="text-base text-mainBlack font-normal leading-6 border border-borderSecondaryGrey bg-searchBg rounded-lg w-[91%] h-8 pl-11"
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default SearchInput;
