import React, { forwardRef } from "react";
import { FaAngleDown } from "react-icons/fa";

interface TitleBarItemsProps {
  displayText: string;
  onItemClick: () => void;
  isSelected: boolean;
  isOpen: boolean;
  dropdownItems: string[];
  onChildClick: (e: string) => void;
  onArrowClick: () => void;
  onBlur?: () => void;
}

const TitleBarItems: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TitleBarItemsProps
> = (
  {
    displayText,
    onItemClick,
    isSelected,
    isOpen,
    dropdownItems,
    onChildClick,
    onArrowClick,
    onBlur,
  },
  ref
) => {
  return (
    <div className="relative" ref={ref} onBlur={onBlur} tabIndex={0}>
      <div
        className={`no-select cursor-pointer font-semibold font-internship flex h-10 px-4 py-1 justify-center items-center gap-1 rounded-t-2xl ${
          isSelected
            ? "border-b-4 border-primary text-primary"
            : "pb-2 bg-white text-[#616161]"
        }`}
      >
        <span onClick={onItemClick}>{displayText}</span>
        <FaAngleDown
          className={`transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          } cursor-pointer`}
          onClick={onArrowClick}
        />
      </div>
      {isOpen && dropdownItems.length > 0 && (
        <div className="absolute left-0 shadow-md bg-white border border-gray-200 py-2 mt-1 rounded-b-lg w-[230px] max-h-[250px] overflow-auto z-50">
          <div className="scrollbar scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {dropdownItems.map((item, index) => (
              <div
                key={index}
                className="dropdown-item px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  onChildClick(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(TitleBarItems);
