export const ADD_VERSION_STRINGS = {
  title: "Changes will be made:",
  redirectText: "Redirecting to Functional Requirements...",
  buttonLabels: {
    cancel: "Cancel",
    accept: "Accept",
  },
};

export const COMPARE_POPUP_STRINGS = {
  title: "Select to Compare",
  buttonLabels: {
    cancel: "Cancel",
    compare: "Compare",
  },
};
