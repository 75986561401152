import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Button";
import ChangePassword from "./ChangePassword";
import { SettingCard } from "./SettingsCard";
import { MdKeyboardArrowLeft } from "react-icons/md";
import HeaderLayout from "../../Components/HeaderLayout";
import { paths } from "../../constants/paths";
import { ACCOUNT_TEXT } from "./constants";

function Account() {
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleBackToSettingsClick = () => {
    setShowChangePassword(false);
  };

  const handleChangePasswordClick = () => {
    setShowChangePassword(true);
  };

  return (
    <HeaderLayout>
      <div className="account-main-wrapper flex flex-col h-screen">
        <div className="add-topbar w-full h-14 border-b-[1px] border-black border-opacity-20 px-10 py-4 flex justify-between items-center">
          <Link to={paths.profile}>
            <Button className="w-fit flex items-center text-teal">
              <MdKeyboardArrowLeft className="text-teal text-2xl" />
              <p className="text-teal text-base font-semibold leading-normal">
                {ACCOUNT_TEXT.BACK_BUTTON_TEXT}
              </p>
            </Button>
          </Link>
          <Link to={paths.home}>
            <p className="text-center text-cyan-600 text-base font-semibold leading-tight">
              {ACCOUNT_TEXT.EDIT_ACCOUNT_TEXT}
            </p>
          </Link>
        </div>
        <div className="account-setting-wrapper bg-login-bg bg-top bg-no-repeat bg-cover flex-grow flex justify-center items-center">
          {!showChangePassword ? (
            <SettingCard onChangePasswordClick={handleChangePasswordClick} />
          ) : (
            <ChangePassword
              handleBackToSettingsClick={handleBackToSettingsClick}
            />
          )}
        </div>
      </div>
    </HeaderLayout>
  );
}

export default Account;
