import React, { createContext, useContext, ReactNode, useMemo } from "react";
import {
  FUNC_CHILD,
  SAFETY_CHILDS,
  HARA_CHILDS,
  PAGES,
} from "../constants/constants";

interface PageItem {
  fileName: string;
  content: string;
}

interface SidebarItem {
  name: string;
  subItems?: string[];
  pages?: PageItem[];
}

interface TitleBarItems {
  name: string;
  subItems?: string[];
  key: string;
}

// Define the shape of the context value
interface SideBarContextType {
  sidebarWikiItems: SidebarItem[];
  sidebarHazardsItems: SidebarItem[];
  sidebarFunctionItems: SidebarItem[];
  sidebarSafetyItems: SidebarItem[];
  titleBarItems: TitleBarItems[];
  updateSidebarFunctionItems: (
    functionTableData: { id: string }[],
    triggeringTableData: { id: string }[],
    hazardTableData: { id: string }[],
    preliminaryArchitecture: { id: string }[]
  ) => void;
  updateSidebarHaraItems: (
    haraTableData: { id: string }[],
    operConditionData: { id: string }[]
  ) => void;
  updateSidebarSafetyItems: (
    goalsTableData: { id: string }[],
    requirementsTableData: { id: string }[]
  ) => void;
  addNewFolder: (view: string, folderName: string) => void;
  addNewPage: (selectedFolder: string, fileName: string, content: any) => void;
  getSpecificPageFolder: (page: string) => SidebarItem[];
}

// Initial state
const initialState: Omit<
  SideBarContextType,
  | "updateSidebarSafetyItems"
  | "updateSidebarHaraItems"
  | "updateSidebarFunctionItems"
  | "addNewFolder"
  | "addNewPage"
  | "getSpecificPageFolder"
  | "titleBarItems"
> = {
  sidebarWikiItems: [
    { name: "Task List" },
    {
      name: "System Log",
      subItems: ["Functional Requirements", "Safety Requirements"],
    },
    {
      name: "Requirements",
      subItems: ["Functional Requirements", "Safety Requirements"],
    },
  ],
  sidebarFunctionItems: [
    {
      name: FUNC_CHILD.functionalReq,
      subItems: [],
    },
    {
      name: FUNC_CHILD.triggeringEvt,
      subItems: [],
    },
    {
      name: FUNC_CHILD.hazards,
    },
    {
      name: FUNC_CHILD.preArch,
    },
  ],
  sidebarHazardsItems: [
    {
      name: HARA_CHILDS.haraTables,
      subItems: [],
    },
    {
      name: HARA_CHILDS.operConditions,
      subItems: [],
    },
  ],
  sidebarSafetyItems: [
    {
      name: SAFETY_CHILDS.safetyGoals,
      subItems: [],
    },
    {
      name: SAFETY_CHILDS.safetyRequirements,
      subItems: [],
    },
  ],
};

const SideBarContext = createContext<SideBarContextType | undefined>(undefined);

// Create a provider component
export const SideBarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [sidebarWikiItems, setSidebarWikiItems] = React.useState(
    initialState.sidebarWikiItems
  );
  const [sidebarFunctionItems, setSidebarFunctionItems] = React.useState(
    initialState.sidebarFunctionItems
  );
  const [sidebarHazardsItems, setSidebarHazardsItems] = React.useState(
    initialState.sidebarHazardsItems
  );
  const [sidebarSafetyItems, setSidebarSafetyItems] = React.useState(
    initialState.sidebarSafetyItems
  );

  const updateSidebarFunctionItems = (
    funcionTableData: { id: string }[],
    triggeringTableData: { id: string }[],
    hazardTableData: { id: string }[],
    preliminaryArchitecture: { id: string }[]
  ) => {
    const updatedItems = sidebarFunctionItems.map((item) => {
      if (item.name === FUNC_CHILD.functionalReq) {
        return {
          ...item,
          subItems: funcionTableData.map((ft) => ft.id),
        };
      } else if (item.name === FUNC_CHILD.triggeringEvt) {
        return {
          ...item,
          subItems: triggeringTableData.map((ft) => ft.id),
        };
      }
      if (item.name === FUNC_CHILD.hazards) {
        return {
          ...item,
          subItems: hazardTableData.map((ft) => ft.id),
        };
      }
      if (item.name === FUNC_CHILD.preArch) {
        return {
          ...item,
          subItems: preliminaryArchitecture.map((ft) => ft.id),
        };
      }
      return item;
    });

    setSidebarFunctionItems(updatedItems);
  };
  const updateSidebarHaraItems = (
    haraTableData: { id: string }[],
    operConditionData: { id: string }[]
  ) => {
    const updatedItems = sidebarHazardsItems.map((item) => {
      if (item.name === HARA_CHILDS.haraTables) {
        return {
          ...item,
          subItems: haraTableData.map((item) => item.id),
        };
      }
      if (item.name === HARA_CHILDS.operConditions) {
        return {
          ...item,
          subItems: operConditionData.map((item) => item.id),
        };
      }

      return item;
    });

    setSidebarHazardsItems(updatedItems);
  };
  const updateSidebarSafetyItems = (
    goalsTableData: { id: string }[],
    requirementsData: { id: string }[]
  ) => {
    const updatedItems = sidebarSafetyItems.map((item) => {
      if (item.name === SAFETY_CHILDS.safetyGoals) {
        return {
          ...item,
          subItems: goalsTableData.map((item) => item.id),
        };
      }
      if (item.name === SAFETY_CHILDS.safetyRequirements) {
        return {
          ...item,
          subItems: requirementsData.map((item) => item.id),
        };
      }

      return item;
    });

    setSidebarSafetyItems(updatedItems);
  };

  const addNewFolder = (page: string, folderName: string) => {
    if (page === PAGES.wiki) {
      setSidebarWikiItems((prevItems) => [
        ...prevItems,
        { name: folderName, subItems: [] },
      ]);
    }
    if (page === PAGES.function) {
      setSidebarFunctionItems((prevItems) => [
        ...prevItems,
        { name: folderName, subItems: [] },
      ]);
    }
    if (page === PAGES.hara) {
      setSidebarHazardsItems((prevItems) => [
        ...prevItems,
        { name: folderName, subItems: [] },
      ]);
    }
    if (page === PAGES.safety) {
      setSidebarSafetyItems((prevItems) => [
        ...prevItems,
        { name: folderName, subItems: [] },
      ]);
    }
  };

  // Add new page specific to folder
  const addNewPage = (
    selectedFolder: string,
    fileName: string,
    content: string
  ) => {
    const updateFolderItems = (items: SidebarItem[]) =>
      items.map((item) => {
        if (item.name === selectedFolder) {
          return {
            ...item,
            subItems: item.subItems ? [...item.subItems, fileName] : [fileName], // Keep subItems if needed
            pages: item.pages
              ? [...item.pages, { fileName, content }]
              : [{ fileName, content }],
          };
        }
        return item;
      });

    if (sidebarFunctionItems.some((item) => item.name === selectedFolder)) {
      setSidebarFunctionItems(updateFolderItems(sidebarFunctionItems));
    } else if (sidebarWikiItems.some((item) => item.name === selectedFolder)) {
      setSidebarWikiItems(updateFolderItems(sidebarWikiItems));
    } else if (
      sidebarHazardsItems.some((item) => item.name === selectedFolder)
    ) {
      setSidebarHazardsItems(updateFolderItems(sidebarHazardsItems));
    } else if (
      sidebarSafetyItems.some((item) => item.name === selectedFolder)
    ) {
      setSidebarSafetyItems(updateFolderItems(sidebarSafetyItems));
    }
  };

  const getSpecificPageFolder = (page: string): SidebarItem[] => {
    switch (page) {
      case PAGES.wiki:
        return sidebarWikiItems;
      case PAGES.function:
        return sidebarFunctionItems;
      case PAGES.hara:
        return sidebarHazardsItems;
      case PAGES.safety:
        return sidebarSafetyItems;
      default:
        return [];
    }
  };

  // Compute titleBarItems dynamically
  const titleBarItems: TitleBarItems[] = [
    {
      name: "Wiki",
      subItems: sidebarWikiItems.map((item) => item.name),
      key: "Wiki",
    },
    {
      name: "Function",
      subItems: sidebarFunctionItems.map((item) => item.name),
      key: "Function",
    },
    {
      name: "Hazards & Risk Assessment",
      subItems: sidebarHazardsItems.map((item) => item.name),
      key: "Hazard",
    },
    {
      name: "Safety Goals",
      subItems: sidebarSafetyItems.map((item) => item.name),
      key: "Safety",
    },
    {
      name: "SOTIF Analysis",
      subItems: sidebarWikiItems.map((item) => item.name),
      key: "Analysis",
    },
  ];

  const value = useMemo(
    () => ({
      sidebarWikiItems,
      sidebarHazardsItems,
      sidebarFunctionItems,
      sidebarSafetyItems,
      titleBarItems,
      updateSidebarFunctionItems,
      updateSidebarHaraItems,
      updateSidebarSafetyItems,
      addNewFolder,
      addNewPage,
      getSpecificPageFolder,
    }),
    [
      sidebarFunctionItems,
      sidebarWikiItems,
      sidebarHazardsItems,
      sidebarSafetyItems,
      titleBarItems,
    ]
  );

  return (
    <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
  );
};

// Custom hook to use the context
export const useSideBar = () => {
  const context = useContext(SideBarContext);
  if (!context) {
    throw new Error("useSideBar must be used within a SideBarProvider");
  }
  return context;
};
