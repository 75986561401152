import React from "react";
import { VIEWS } from "../../constants/constants";
import useUrlParams from "../../hooks/useUrlParams";

// Define the type for matchingChildData with flexible keys
interface MatchingChildData extends Record<string, any> { }

interface FuncReqCardProps {
  dataForCurrentChild: MatchingChildData[];
}

const FuncReqCard: React.FC<FuncReqCardProps> = ({ dataForCurrentChild }) => {
  const { id, view } = useUrlParams();

  const attributesAndValue = dataForCurrentChild[0]?.attributes_and_value ?? {};
  const entries = Object.entries(attributesAndValue);
  const columnSize = Math.ceil(entries.length / 3);

  const leftColumn = entries.slice(0, columnSize);
  const middleColumn = entries.slice(columnSize, columnSize * 2);
  const rightColumn = entries.slice(columnSize * 2);

  return (
    <div className="w-full h-auto relative mb-5">
      <div className="absolute -top-4 left-4 z-[1] text-zinc-800 text-2xl font-semibold font-['Inter']">
        {view !== VIEWS.edit ? id : null}
      </div>

      <div className="func-req-div w-full h-auto bg-cyan-600 bg-opacity-10 backdrop-blur-1 rounded-[10px] shadow-md px-4 py-4 pt-6 flex flex-row items-start justify-center">
        {/* Left Column */}
        <div className="w-[33%] h-full flex flex-col pl-2">
          {leftColumn.map(([key, value]) => (
            <div key={key} className="rows w-full flex flex-row my-1">
              <div className="basis-1/2 text-neutral-400 text-base font-normal font-['Inter']">
                {key}:
              </div>
              <div className="basis-1/2 text-mainBlack text-base font-semibold font-['Heebo']">
                {value !== undefined && value !== null && value !== ""
                  ? value.toString()
                  : "N/A"}
              </div>
            </div>
          ))}
        </div>

        {/* Middle Column */}
        <div className="w-[33%] h-full flex flex-col border-l-2 border-black/20 pl-5">
          {middleColumn.map(([key, value]) => (
            <div key={key} className="rows w-full flex flex-row my-1">
              <div className="basis-1/2 text-neutral-400 text-base font-normal font-['Inter']">
                {key}:
              </div>
              <div className="basis-1/2 text-mainBlack text-base font-semibold font-['Heebo']">
                {value !== undefined && value !== null && value !== ""
                  ? value.toString()
                  : "N/A"}
              </div>
            </div>
          ))}
        </div>

        {/* Right Column */}
        <div className="w-[33%] h-full flex flex-col border-l-2 border-black/20 pl-5">
          {rightColumn.map(([key, value]) => (
            <div key={key} className="rows w-full flex flex-row my-1">
              <div className="basis-1/2 text-neutral-400 text-base font-normal font-['Inter']">
                {key}:
              </div>
              <div className="basis-1/2 text-mainBlack text-base font-semibold font-['Heebo']">
                {value !== undefined && value !== null && value !== ""
                  ? value.toString()
                  : "N/A"}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FuncReqCard;
