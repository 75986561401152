export const LOGIN_LOGO_ALT = "main-logo";
export const LOGIN_LOGO_SRC = "../../assets/Images/login-logo.png";
export const SAFETY_ARG_DESCRIPTION = "Safety Argumentation & Analysis";
export const EMAIL_ERROR_EMPTY = "Please enter your email.";
export const EMAIL_ERROR_INVALID = "Please enter a valid email address.";
export const PASSWORD_ERROR_EMPTY = "Please enter and confirm your password.";
export const PASSWORD_ERROR_MISMATCH = "Passwords do not match.";
export const OTP_ERROR_INVALID = "Incorrect Code";
export const RESEND_OTP_ERROR = "Failed to generate again";
export const STEP_ONE_TITLE =
  "Enter your e-mail and we will send you a verification code to reset your password";
export const BUTTON_CONTINUE = "Continue";
export const NEW_USER_TEXT = "New User? ";
export const CONTACT_SUPPORT_TEXT = "Contact Support";
export const OTP_STEP_TITLE = "Check your e-mail for the 6 digit code";
export const CHANGE_EMAIL_TEXT = "Change";
export const DID_NOT_RECEIVE_CODE_TEXT = "Did not receive a code? ";
export const SEND_AGAIN_TEXT = "Send Again";
export const NEW_PASSWORD_TITLE = "Create a new password";
export const PASSWORD_LABEL = "Password";
export const REPEAT_PASSWORD_LABEL = "Repeat Password";
